/* 
* * * New Styles Aeroméxico Rewards Corporate *** 
* @ids
*/

@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700|Roboto+Mono:400,700);
@import url(https://pro.fontawesome.com/releases/v5.10.0/css/all.css);
@import url(https://corpstylo.clubpremier.info/ambusinessstyleguide/css/style-base.css);
@import url(https://cdn.datatables.net/1.10.21/css/jquery.dataTables.css);
/*!
@import url(https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css);
* Bootstrap v4.1.1 (https://getbootstrap.com/)
* Copyright 2011-2019 The Bootstrap Authors
*/
:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.custom-control-input:before,
.custom-control-input:after {
    border-radius: 0 !important;
}

html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav {
    display: block;
}

.header-basic {
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
    height: 100px;
    display: flex;
    align-items: center;
}

.header-basic .container-header {
    width: 100%;
}

.header-basic .btn-header {
    width: 100%;
}

.header-basic img {
    margin-left: 15px;
}

.header-basic .btn-secondary {
    min-width: -webkit-fill-available;
    margin-left: 5px;
    margin-right: 5px;
}

.header-basic .btn-primary {
    min-width: -webkit-fill-available;
    margin-left: 5px;
    margin-right: 5px;
}

.header-basic .btn-idioma {
    margin: auto;
    display: flex;
    align-items: center;
}

.container-headerDividers {
    margin: auto;
    margin-left: 10px;
    margin-right: 10px;
    max-height: 21px;
    display: flex;
    align-items: center;
}

.header-basic h6 {
    margin: 0;
}

.header-basic .es {
    color: #020C41;
}

.header-basic .en {
    color: #020C41;
}

section {
    display: block;
    margin-left: -15px;
    margin-right: -15px;
}

body {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #020C41;
    text-align: left;
    background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
}

@media (min-width: 992px) {
    p {
        font-weight: normal;
        font-size: 0.93vw;
        line-height: 1.30vw;
        letter-spacing: 0.4px;
    }
}

@media (max-width: 992px) {
    p {
        margin-top: 0;
        margin-bottom: 1rem;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
    .bullet-basic {
        font-size: 14px !important;
    }
}

.boldText {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 42px;
    color: #737373;
}

.mediumText {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 42px;
    color: #737373;
}

.regularText {
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 42px;
    color: #737373;
}

.lightText {
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 42px;
    color: #737373;
}

abbr[title],
abbr[data-original-title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
    padding-left: 18px;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: .5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -.25em;
}

sup {
    top: -.5em;
}

a {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
    /* identical to box height */
    color: #030D75;
  }
  
  a:hover {
    font-weight: 500;
    text-decoration: underline;
    color: #020C41;
}


/*Clase para deshabilitar link's */

.isDisabled {
    font-weight: normal !important;
    color: #AAAAAA;
    cursor: default !important;
    text-decoration: none !important;
}

pre,
code,
kbd,
samp {
    font-family: Consolas;
    font-style: normal;
    font-weight: normal;
    font-size: 0.93vw;
    line-height: 1.30vw;
    letter-spacing: 0.4px;
}

@media (max-width: 992px) {
    pre,
    code,
    kbd,
    samp {
        font-size: 1.5vw;
        line-height: 2vw;
        letter-spacing: 0.0001vw;
    }
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    display: block;
    font-size: 87.5%;
    color: #212529;
}

figure {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
}

.img-container {
    max-width: 600px;
    max-height: 600px;
    width: 100%;
    height: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    border: 1px solid #D0D0D0;
    box-shadow: 2px 0px 6px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.img-container .img-basic {
    width: 100%;
    height: 100%;
    max-width: 540px;
    max-height: 540px;
    padding: 30px;
    margin: auto;
}

.img-card-h {
    width: 100%;
    height: 100%;
    max-width: 200px;
    max-height: 170px;
    background: #FFFFFF;
}

.img-b {
    padding: 10px;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
}

th {
    text-align: inherit;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

select {
    word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
    cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    border: 2px solid #020C41;
    color: #020C41;
    display: none;
}



input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

@media (max-width: 992px) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        font-weight: 500;
        line-height: 1.2;
    }
    h1,
    .h1 {
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
    }
    h2,
    .h2,
    .title-bullet {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
    }
    h3,
    .h3 {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
    }
    h4,
    .h4 {
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
    }
    h5,
    .h5 {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
    }
    h6,
    .h6 {
        font-size: 12px!important;
        line-height: 15px!important;
        font-weight: normal!important;
    }
    
    .h7 {
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
    }
   
    .h8 {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
    }
    p .body-text {
        font-weight: normal;
        font-size: 14px !important;
        line-height: 16px !important;
    }
    p .Links-and-inputs {
        font-weight: 500;
        font-size: 16px !important;
        line-height: 23px !important;
    }
    p .support_text {
        font-weight: normal;
        font-style: normal;
        font-size: 14px !important;
        line-height: 16px !important;
    }
    p .label-text {
        font-weight: 500;
        font-style: normal;
        line-height: 14px !important;
        font-size:12px!important;
    }
}

.table-container {
    overflow-y: auto;
    padding: 0;
    margin: 0.75rem 1.25rem;
}

.table-container::-webkit-scrollbar {
    height: 0;
}

.table-striped .support_text {
    font-style: normal;
    font-weight: normal;
    font-size: 0.729166vw;
    line-height: 0.78125;
    letter-spacing: 0.4px;
}

.table-striped h6 {
    color: #fff;
}

@media (max-width: 992px){
    .table-striped .support_text {
        font-size: 12px!important;
        line-height: 15px!important;
    }
}

.vigencia_promo {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    color: #444444;
}

@media (min-width: 992px) {
    h1,
    .h1 {
        font-weight: bold;
        font-size: 48px;
        line-height: 65px;
    }
    h2,
    .h2,
    .title-bullet {
        font-weight: 600;
        font-size: 1.98vw;
        line-height: 2.5vw;
    }
    h3,
    .h3 {
        font-weight: 600;
        font-size: 1.56vw;
        line-height: 2.19vw;
    }
    h4,
    .h4 {
        font-weight: 600;
        font-size: 1.25vw;
        line-height: 1.82vw;
    }
    h5,
    .h5 {
        font-weight: 600;
        font-size: 1.10vw!important;
        line-height: 1.46vw!important;
    }
    h6,
    .h6 {
        font-size: 0.86vw!important;
        line-height: 1.31vw!important;
        font-weight: normal!important;
    }
    
    .h7 {
        font-weight: 500;
        font-size: 0.833vw!important;
        line-height: 1.04!important;
    }
    
    .h8 {
        font-weight: 500;
        font-size: 0.729vw!important;
        line-height: 0.833vw!important;
    }
    a {
        font-size: 0.83vw;
        line-height: 0.99vw;
    }
    .div-liks .fas {
        font-size: 0.83vw !important;
        line-height: 0.99vw !important;
        color: #020C41;
    }
    p .body-text {
        font-weight: normal;
        font-size: 0.86vw!important;
        line-height: 1.30vw!important;
    }
    p .Links-and-inputs {
        font-weight: 500;
        font-size: 0.833vw!important;
        line-height: 1.19vw!important;
    }
    p .support_text {
        font-weight: normal;
        font-style: normal;
        font-size: 0.729vw!important;
        line-height: 0.833vw!important;
    }
    p .label-text {
        font-weight: 500;
        font-style: normal;
        line-height: 0.729vw!important;
        font-size: 0.625vw!important
    }
}

.lead {
    font-size: 1.25rem;
    font-weight: 300;
}

.display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
}


small,
.small {
    font-size: 80%;
    font-weight: 400;
}

mark,
.mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;
}

.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}

.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d;
}

.blockquote-footer::before {
    content: "\2014\00A0";
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
}

.figure {
    display: inline-block;
}

.figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
}

.figure-caption {
    font-size: 90%;
    color: #6c757d;
}

code {
    color: #00D3A0;
    word-wrap: break-word;
}

a>code {
    color: inherit;
}

kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem;
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
}


pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}


@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
    .container-p {
        padding-right: 50px !important;
        padding-left: 50px !important;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
        margin: 0 6vw;
    }
    .container-p {
        padding-right: 100px !important;
        padding-left: 100px !important;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
        margin: 0 auto;
    }
    .container-p {
        padding-right: 150px !important;
        padding-left: 150px !important;
    }
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container,
    .container-sm {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container,
    .container-sm,
    .container-md {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 1140px;
    }
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.no-gutters {
    margin-right: 15px;
    margin-left: 15px;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}

.row-cols-1>* {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.row-cols-2>* {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.row-cols-3>* {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.row-cols-4>* {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.row-cols-5>* {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
}

.row-cols-6>* {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}

.col-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.col-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}

.col-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}

.col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.col-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}

.col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

.col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}

.col-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
}

.col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.order-first {
    -ms-flex-order: -1;
    order: -1;
}

.order-last {
    -ms-flex-order: 13;
    order: 13;
}

.order-0 {
    -ms-flex-order: 0;
    order: 0;
}

.order-1 {
    -ms-flex-order: 1;
    order: 1;
}

.order-2 {
    -ms-flex-order: 2;
    order: 2;
}

.order-3 {
    -ms-flex-order: 3;
    order: 3;
}

.order-4 {
    -ms-flex-order: 4;
    order: 4;
}

.order-5 {
    -ms-flex-order: 5;
    order: 5;
}

.order-6 {
    -ms-flex-order: 6;
    order: 6;
}

.order-7 {
    -ms-flex-order: 7;
    order: 7;
}

.order-8 {
    -ms-flex-order: 8;
    order: 8;
}

.order-9 {
    -ms-flex-order: 9;
    order: 9;
}

.order-10 {
    -ms-flex-order: 10;
    order: 10;
}

.order-11 {
    -ms-flex-order: 11;
    order: 11;
}

.order-12 {
    -ms-flex-order: 12;
    order: 12;
}

.offset-1 {
    margin-left: 8.333333%;
}

.offset-2 {
    margin-left: 16.666667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.333333%;
}

.offset-5 {
    margin-left: 41.666667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.333333%;
}

.offset-8 {
    margin-left: 66.666667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.333333%;
}

.offset-11 {
    margin-left: 91.666667%;
}

@media (min-width: 576px) {
    .col-sm {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .row-cols-sm-1>* {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .row-cols-sm-2>* {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .row-cols-sm-3>* {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .row-cols-sm-4>* {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .row-cols-sm-5>* {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
    .row-cols-sm-6>* {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-sm-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-sm-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-sm-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-sm-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-sm-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-sm-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-sm-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-sm-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-sm-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-sm-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-sm-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-sm-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .col-sm-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-sm-first {
        -ms-flex-order: -1;
        order: -1;
    }
    .order-sm-last {
        -ms-flex-order: 13;
        order: 13;
    }
    .order-sm-0 {
        -ms-flex-order: 0;
        order: 0;
    }
    .order-sm-1 {
        -ms-flex-order: 1;
        order: 1;
    }
    .order-sm-2 {
        -ms-flex-order: 2;
        order: 2;
    }
    .order-sm-3 {
        -ms-flex-order: 3;
        order: 3;
    }
    .order-sm-4 {
        -ms-flex-order: 4;
        order: 4;
    }
    .order-sm-5 {
        -ms-flex-order: 5;
        order: 5;
    }
    .order-sm-6 {
        -ms-flex-order: 6;
        order: 6;
    }
    .order-sm-7 {
        -ms-flex-order: 7;
        order: 7;
    }
    .order-sm-8 {
        -ms-flex-order: 8;
        order: 8;
    }
    .order-sm-9 {
        -ms-flex-order: 9;
        order: 9;
    }
    .order-sm-10 {
        -ms-flex-order: 10;
        order: 10;
    }
    .order-sm-11 {
        -ms-flex-order: 11;
        order: 11;
    }
    .order-sm-12 {
        -ms-flex-order: 12;
        order: 12;
    }
    .offset-sm-0 {
        margin-left: 0;
    }
    .offset-sm-1 {
        margin-left: 8.333333%;
    }
    .offset-sm-2 {
        margin-left: 16.666667%;
    }
    .offset-sm-3 {
        margin-left: 25%;
    }
    .offset-sm-4 {
        margin-left: 33.333333%;
    }
    .offset-sm-5 {
        margin-left: 41.666667%;
    }
    .offset-sm-6 {
        margin-left: 50%;
    }
    .offset-sm-7 {
        margin-left: 58.333333%;
    }
    .offset-sm-8 {
        margin-left: 66.666667%;
    }
    .offset-sm-9 {
        margin-left: 75%;
    }
    .offset-sm-10 {
        margin-left: 83.333333%;
    }
    .offset-sm-11 {
        margin-left: 91.666667%;
    }
}

@media (min-width: 768px) {
    .col-md {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .row-cols-md-1>* {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .row-cols-md-2>* {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .row-cols-md-3>* {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .row-cols-md-4>* {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .row-cols-md-5>* {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
    .row-cols-md-6>* {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-md-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-md-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-md-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-md-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-md-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-md-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-md-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-md-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-md-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-md-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-md-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-md-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .col-md-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-md-first {
        -ms-flex-order: -1;
        order: -1;
    }
    .order-md-last {
        -ms-flex-order: 13;
        order: 13;
    }
    .order-md-0 {
        -ms-flex-order: 0;
        order: 0;
    }
    .order-md-1 {
        -ms-flex-order: 1;
        order: 1;
    }
    .order-md-2 {
        -ms-flex-order: 2;
        order: 2;
    }
    .order-md-3 {
        -ms-flex-order: 3;
        order: 3;
    }
    .order-md-4 {
        -ms-flex-order: 4;
        order: 4;
    }
    .order-md-5 {
        -ms-flex-order: 5;
        order: 5;
    }
    .order-md-6 {
        -ms-flex-order: 6;
        order: 6;
    }
    .order-md-7 {
        -ms-flex-order: 7;
        order: 7;
    }
    .order-md-8 {
        -ms-flex-order: 8;
        order: 8;
    }
    .order-md-9 {
        -ms-flex-order: 9;
        order: 9;
    }
    .order-md-10 {
        -ms-flex-order: 10;
        order: 10;
    }
    .order-md-11 {
        -ms-flex-order: 11;
        order: 11;
    }
    .order-md-12 {
        -ms-flex-order: 12;
        order: 12;
    }
    .offset-md-0 {
        margin-left: 0;
    }
    .offset-md-1 {
        margin-left: 8.333333%;
    }
    .offset-md-2 {
        margin-left: 16.666667%;
    }
    .offset-md-3 {
        margin-left: 25%;
    }
    .offset-md-4 {
        margin-left: 33.333333%;
    }
    .offset-md-5 {
        margin-left: 41.666667%;
    }
    .offset-md-6 {
        margin-left: 50%;
    }
    .offset-md-7 {
        margin-left: 58.333333%;
    }
    .offset-md-8 {
        margin-left: 66.666667%;
    }
    .offset-md-9 {
        margin-left: 75%;
    }
    .offset-md-10 {
        margin-left: 83.333333%;
    }
    .offset-md-11 {
        margin-left: 91.666667%;
    }
}

@media (min-width: 992px) {
    .col-lg {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .row-cols-lg-1>* {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .row-cols-lg-2>* {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .row-cols-lg-3>* {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .row-cols-lg-4>* {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .row-cols-lg-5>* {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
    .row-cols-lg-6>* {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-lg-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-lg-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-lg-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-lg-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-lg-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-lg-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-lg-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-lg-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-lg-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-lg-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-lg-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-lg-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .col-lg-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-lg-first {
        -ms-flex-order: -1;
        order: -1;
    }
    .order-lg-last {
        -ms-flex-order: 13;
        order: 13;
    }
    .order-lg-0 {
        -ms-flex-order: 0;
        order: 0;
    }
    .order-lg-1 {
        -ms-flex-order: 1;
        order: 1;
    }
    .order-lg-2 {
        -ms-flex-order: 2;
        order: 2;
    }
    .order-lg-3 {
        -ms-flex-order: 3;
        order: 3;
    }
    .order-lg-4 {
        -ms-flex-order: 4;
        order: 4;
    }
    .order-lg-5 {
        -ms-flex-order: 5;
        order: 5;
    }
    .order-lg-6 {
        -ms-flex-order: 6;
        order: 6;
    }
    .order-lg-7 {
        -ms-flex-order: 7;
        order: 7;
    }
    .order-lg-8 {
        -ms-flex-order: 8;
        order: 8;
    }
    .order-lg-9 {
        -ms-flex-order: 9;
        order: 9;
    }
    .order-lg-10 {
        -ms-flex-order: 10;
        order: 10;
    }
    .order-lg-11 {
        -ms-flex-order: 11;
        order: 11;
    }
    .order-lg-12 {
        -ms-flex-order: 12;
        order: 12;
    }
    .offset-lg-0 {
        margin-left: 0;
    }
    .offset-lg-1 {
        margin-left: 8.333333%;
    }
    .offset-lg-2 {
        margin-left: 16.666667%;
    }
    .offset-lg-3 {
        margin-left: 25%;
    }
    .offset-lg-4 {
        margin-left: 33.333333%;
    }
    .offset-lg-5 {
        margin-left: 41.666667%;
    }
    .offset-lg-6 {
        margin-left: 50%;
    }
    .offset-lg-7 {
        margin-left: 58.333333%;
    }
    .offset-lg-8 {
        margin-left: 66.666667%;
    }
    .offset-lg-9 {
        margin-left: 75%;
    }
    .offset-lg-10 {
        margin-left: 83.333333%;
    }
    .offset-lg-11 {
        margin-left: 91.666667%;
    }
}

@media (min-width: 1200px) {
    .col-xl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .row-cols-xl-1>* {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .row-cols-xl-2>* {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .row-cols-xl-3>* {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .row-cols-xl-4>* {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .row-cols-xl-5>* {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
    .row-cols-xl-6>* {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-xl-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-xl-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-xl-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-xl-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-xl-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-xl-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-xl-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-xl-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-xl-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-xl-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-xl-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-xl-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .col-xl-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-xl-first {
        -ms-flex-order: -1;
        order: -1;
    }
    .order-xl-last {
        -ms-flex-order: 13;
        order: 13;
    }
    .order-xl-0 {
        -ms-flex-order: 0;
        order: 0;
    }
    .order-xl-1 {
        -ms-flex-order: 1;
        order: 1;
    }
    .order-xl-2 {
        -ms-flex-order: 2;
        order: 2;
    }
    .order-xl-3 {
        -ms-flex-order: 3;
        order: 3;
    }
    .order-xl-4 {
        -ms-flex-order: 4;
        order: 4;
    }
    .order-xl-5 {
        -ms-flex-order: 5;
        order: 5;
    }
    .order-xl-6 {
        -ms-flex-order: 6;
        order: 6;
    }
    .order-xl-7 {
        -ms-flex-order: 7;
        order: 7;
    }
    .order-xl-8 {
        -ms-flex-order: 8;
        order: 8;
    }
    .order-xl-9 {
        -ms-flex-order: 9;
        order: 9;
    }
    .order-xl-10 {
        -ms-flex-order: 10;
        order: 10;
    }
    .order-xl-11 {
        -ms-flex-order: 11;
        order: 11;
    }
    .order-xl-12 {
        -ms-flex-order: 12;
        order: 12;
    }
    .offset-xl-0 {
        margin-left: 0;
    }
    .offset-xl-1 {
        margin-left: 8.333333%;
    }
    .offset-xl-2 {
        margin-left: 16.666667%;
    }
    .offset-xl-3 {
        margin-left: 25%;
    }
    .offset-xl-4 {
        margin-left: 33.333333%;
    }
    .offset-xl-5 {
        margin-left: 41.666667%;
    }
    .offset-xl-6 {
        margin-left: 50%;
    }
    .offset-xl-7 {
        margin-left: 58.333333%;
    }
    .offset-xl-8 {
        margin-left: 66.666667%;
    }
    .offset-xl-9 {
        margin-left: 75%;
    }
    .offset-xl-10 {
        margin-left: 83.333333%;
    }
    .offset-xl-11 {
        margin-left: 91.666667%;
    }
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}

.table th,
.table td {
    vertical-align: center;
    border-top: 1px solid #dee2e6;
    padding-left: 20px;
    padding-right: 20px;
    height: 50px;
}

.table-striped thead {
    border-right: 1px solid;
    background: #00295E;
    color: #FFFFFF;
    border-radius: 5px 5px 0px 0px !important;
}

.table thead th {
    vertical-align: middle;
    border-bottom: 2px solid #dee2e6;
    box-sizing: border-box;
}

.table thead th:first-child {
    border-radius: 5px 0px 0px 0px;
}

.table thead th:last-child {
    border-radius: 0px 5px 0px 0px;
}

.table tbody+tbody {
    border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
    padding: 0.3rem;
}

.table-bordered {
    border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
    border: 0;
}

.table-striped tbody {
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 5px;
}

.table-striped tbody tr:nth-of-type(odd) {
    background: #F1F1F1;
}

.table-striped tbody tr:nth-of-type(even) {
    background: #FFFFFF;
}

.table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary>th,
.table-primary>td {
    background-color: #b8daff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody+tbody {
    border-color: #7abaff;
}

.table-hover .table-primary:hover {
    background-color: #9fcdff;
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #9fcdff;
}

.table-secondary,
.table-secondary>th,
.table-secondary>td {
    background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody+tbody {
    border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
    background-color: #c8cbcf;
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #c8cbcf;
}

.table-success,
.table-success>th,
.table-success>td {
    background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody+tbody {
    border-color: #8fd19e;
}

.table-hover .table-success:hover {
    background-color: #b1dfbb;
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #b1dfbb;
}

.table-info,
.table-info>th,
.table-info>td {
    background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody+tbody {
    border-color: #86cfda;
}

.table-hover .table-info:hover {
    background-color: #abdde5;
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #abdde5;
}

.table-warning,
.table-warning>th,
.table-warning>td {
    background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody+tbody {
    border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
    background-color: #ffe8a1;
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #ffe8a1;
}

.table-danger,
.table-danger>th,
.table-danger>td {
    background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody+tbody {
    border-color: #ed969e;
}

.table-hover .table-danger:hover {
    background-color: #f1b0b7;
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #f1b0b7;
}

.table-light,
.table-light>th,
.table-light>td {
    background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody+tbody {
    border-color: #fbfcfc;
}

.table-hover .table-light:hover {
    background-color: #ececf6;
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #ececf6;
}

.table-dark,
.table-dark>th,
.table-dark>td {
    background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody+tbody {
    border-color: #95999c;
}

.table-hover .table-dark:hover {
    background-color: #b9bbbe;
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #b9bbbe;
}

.table-active,
.table-active>th,
.table-active>td {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55;
}

.table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.table-dark {
    color: #fff;
    background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
    border-color: #454d55;
}

.table-dark.table-bordered {
    border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-sm>.table-bordered {
        border: 0;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-md>.table-bordered {
        border: 0;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-lg>.table-bordered {
        border: 0;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-xl>.table-bordered {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-responsive>.table-bordered {
    border: 0;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: justify;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-bottom: 1px solid #AAAAAA;
    margin-bottom: 0;
    border-radius: 0;
    flex: 1 1 0%;
    min-width: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-shadow: none;
}

@media (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none;
    }
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057;
}

.form-control:focus {
    z-index: 3;
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    border-bottom: 1px solid #020C41 !important;
}

.form-control::-webkit-input-placeholder {
    color: #D6D6D6;
    opacity: 1;
}

.form-control::-moz-placeholder {
    color: #D6D6D6;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #D6D6D6;
    opacity: 1;
}

.form-control::-ms-input-placeholder {
    color: #D6D6D6;
    opacity: 1;
}

.form-control::placeholder {
    opacity: 1;
    
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    text-align: justify;
    color: #D6D6D6;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff;
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%;
}

.col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}

.col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5;
}

.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5;
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.375rem 0;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0;
}

.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
    height: auto;
}

textarea.form-control {
    height: auto;
}

.form-group {
    margin-bottom: 1rem;
}

.form-text {
    display: block;
    margin-top: 0.25rem;
}

.form-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.form-row>.col,
.form-row>[class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.form-check-input {
    position: absolute;
    margin-top: 0.2rem;
    margin-left: -1.25rem;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    background: #020C41;
    border: 2px solid #020C41;
    box-sizing: border-box;
}

.form-check-input:hover {
    background: #FFFFFF;
    border: 2px solid #CCCCCC;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    color: #444444;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
    color: #6c757d;
}

.form-check-label {
    margin-bottom: 0;
    margin-left: 10px;
    
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #020C41;
}

.form-check-inline {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #28a745;
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(40, 167, 69, 0.9);
    border-radius: 0.25rem;
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
    border-color: #28a745;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
    border-color: #28a745;
    padding-right: calc(0.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
    color: #28a745;
}

.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip,
.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label {
    color: #28a745;
}

.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before {
    border-color: #28a745;
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before {
    border-color: #34ce57;
    background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.custom-control-input.is-valid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before {
    border-color: #28a745;
}

.was-validated .custom-file-input:valid~.custom-file-label,
.custom-file-input.is-valid~.custom-file-label {
    border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus~.custom-file-label,
.custom-file-input.is-valid:focus~.custom-file-label {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.9);
    border-radius: 0.25rem;
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
    border-color: #dc3545;
    padding-right: calc(0.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
    color: #dc3545;
}

.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip,
.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label {
    color: #dc3545;
}

.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before {
    border-color: #dc3545;
}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.custom-control-input.is-invalid:checked~.custom-control-label::before {
    border-color: #e4606d;
    background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
.custom-control-input.is-invalid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before {
    border-color: #dc3545;
}

.was-validated .custom-file-input:invalid~.custom-file-label,
.custom-file-input.is-invalid~.custom-file-label {
    border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus~.custom-file-label,
.custom-file-input.is-invalid:focus~.custom-file-label {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
}

.form-inline .form-check {
    width: 100%;
}

@media (min-width: 576px) {
    .form-inline label {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 0;
    }
    .form-inline .form-group {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 0;
    }
    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }
    .form-inline .form-control-plaintext {
        display: inline-block;
    }
    .form-inline .input-group,
    .form-inline .custom-select {
        width: auto;
    }
    .form-inline .form-check {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: auto;
        padding-left: 0;
    }
    .form-inline .form-check-input {
        position: relative;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: 0.25rem;
        margin-left: 0;
    }
    .form-inline .custom-control {
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .form-inline .custom-control-label {
        margin-bottom: 0;
    }
}

.btn {
    background: #030D75;
    border-radius: 1.875rem;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    vertical-align: middle;
    text-align: center;
    color: #FFFFFF;
}

@media (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}

.btn:hover {
    text-decoration: none;
    color: #FFFFFF;
    background: #030D75;
}

.btn:focus,
.btn.focus {
    outline: 0;
}

.btn.disabled,
.btn:disabled {
    background: #CCCCCC;
    color: #AAAAAA !important;
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

.btn-primary {
    color: #fff;
    background-color: #0000E3;
    border-color: #0000E3;
    border-radius: 1.875rem;
    font-weight: bold;
    line-height: auto;
    vertical-align: middle;
    text-align: center;
    border: none;
    font-size: 16px;
}

.btn-primary.hover{
    background-color: #030D75;
    border-color: #030D75;
    font-weight: bold;
}

.btn-primary-dark {
  color: #00295E;
  background-color: #07E7CC;
  border-color: #07E7CC;
  border-radius: 1.875rem;
  font-weight: bold;
  font-size: 12px;
  line-height: auto;
  vertical-align: middle;
  text-align: center;
  border: none;
  width: 100%;
  padding: 0.7rem 1.7rem;
}

.btn-primary-dark:hover {
  color: #00295E;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  border-radius: 1.875rem;
  font-weight: bold;
  font-size: 12px;
  line-height: auto;
  vertical-align: middle;
  text-align: center;
  border: none;
  width: 100%;
  padding: 0.7rem 1.7rem;
}

.btn-secondary-dark {
  border-radius: 30px;
  border: 1px solid #ffffff;
  color: #ffffff;
  padding: 10px 20px;
  font-weight: 600;
  background: transparent;
  font-size: 12px;
}

.btn-secondary-dark:hover {
  border-radius: 30px;
  border: 1px solid #ffffff;
  color: #00295E;
  padding: 10px 20px;
  font-weight: 600;
  background: #ffffff;
  font-size: 12px;
}

.btn-secondary.hover{
    background-color: #FFFFFF !important;
    border-radius: 1.875rem;
    color: #030D75;
    font-weight: 600;
    padding: 0.7rem 1.7rem;
}


@media (max-width: 767px) {
    .btn-primary {
      padding: 0.7rem 1.7rem;
    }
    .btn-primary {
        font-size: 12px;
        line-height: 19px;
        width: auto;
    }
    .btn-primary.disabled,
    .btn-primary:disabled {
        font-size: 16px;
        line-height: 19px;
    }
}

.btn-primary:hover {
    text-decoration: none;
    background: #030D75;
    border-color: #030D75;
}

.btn-primary:focus,
.btn-primary.focus {
    background: #030D75;
    border-color: #030D75;
}

.btn-primary.disabled,
.btn-primary:disabled,
.btn-outline-primary {
    background: #F1F1F1;
    border: 2px solid #CCCCCC;
    color: #AAAAAA;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
    background: #030D75;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    background: #030D75;
    border-color: #030D75;
}

.btn-secondary {
    border-radius: 1.875rem;
    background-color: transparent;
    border: 1px solid #030d75;
    color: #030d75 !important;
    font-weight: 600;
    font-size: 16px !important;
}

@media only screen and (min-width: 768px) {
    .btn-secondary {
        font-size: 12px;
        line-height: 19px;
        width: auto;
    }
}

.btn-secondary:hover {
  background-color: #030D75 !important;
  border-radius: 1.875rem;
  color: #FFFFFF !important;
  font-weight: 600;
  text-decoration: none;
}

.btn-secondary:focus,
.btn-secondary.focus {
    background-color: transparent;
    border-color: #030d75;
    color: #030d75;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    background-color: #CCCCCC;
    color: #AAAAAA;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
    background-color: transparent;
    border-color: #030d75;
    color: #030d75;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    background-color: transparent;
    border-color: #030d75;
    color: #030d75;
}

.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
}

.btn-success:focus,
.btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
}

.btn-info:focus,
.btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
}

.btn-warning:focus,
.btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}

.btn-danger:focus,
.btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
}

.btn-dark:focus,
.btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  border-radius: 1.875rem;
  background-color: transparent;
  border: 1px solid #030d75;
  color: #030d75;
  padding: 0.7rem 1.7rem;
  font-weight: 600;
}

@media (min-width: 767px) {
    .btn-outline-primary {
        font-size: 16px !important;
        line-height: 19px;
        width: max-content;
        height: 40px;
        border: 2px solid #030d75;
        padding: 0.7rem 1.7rem;
    }
}

@media (min-width: 768px) and  (max-width: 991px){
    a.btn-outline-primary {
      padding: 0.7rem 1.7rem;
    }
}
.btn-outline-primary:hover {
    background: #030d75;
    border: 2px solid #030d75;
    color: #FFFFFF;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    background: #030d75;
    border: 2px solid #030d75;
    color: #FFFFFF;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
    background: #030d75;
    border: 2px solid #030d75;
    color: #FFFFFF;
    padding: 0.7rem 1.7rem;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    background: #030d75;
    border: 2px solid #030d75;
    color: #FFFFFF;
    padding: 0.7rem 1.7rem;
}

.btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d;
    width: 100%;
}

@media (min-width: 768px) {
    .btn-outline-secondary {
        width: auto;
    }
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
    color: #28a745;
    border-color: #28a745;
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
    color: #17a2b8;
    border-color: #17a2b8;
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
    color: #ffc107;
    border-color: #ffc107;
}

.btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545;
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
    color: #343a40;
    border-color: #343a40;
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
    font-weight: 400;
    color: #020C41 !important;
    text-decoration: none;
    padding: 15px;
}

.btn-cancel-link {
    font-style: normal;
    font-weight: normal;
    text-align: right;
    color: #AAAAAA !important;
    margin: 0 !important;
    padding: 15px;
}

.fas {
    padding-left: 5px;
}

.fas-basic {
    font-size: 32px;
    color: #020C41;
}

.social-buttons .social-link {
	align-items: center;
	background-color: #020C41;
	color: #FFFFFF;
	display: inline-flex;
	font-size: 1rem;
	height: 2.5rem;
	justify-content: center;
	margin-right: 0.6rem;
	text-decoration: none;
	width: 2.5rem;
  border-radius: .3125rem;
}

.social-buttons .social-link a{
  text-decoration: none !important;
}

.social-buttons .social-link .fab::before{
    height: 16px;
    width: 16px;
    font-size: 16px;
}

.stretched-link.hover{
     
    font-style: normal; 
    font-weight: 500; 
    font-size: 16px; 
    line-height: 19px; 
    text-decoration-line: underline;  
    color: #020C41;
}

.stretched-link .fas {
    font-size: 16px !important;
    line-height: 19px !important;
    color: #020C41;
}

.stretched-link-disabled .fas {
    font-size: 16px !important;
    line-height: 19px !important;
    color: #020C41;
    cursor: default;
    opacity: 0.6;
}

.btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
    text-decoration: underline;
    box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
    color: #6c757d;
    pointer-events: none;
}

.btn-lg,
.btn-group-lg>.btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm>.btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-block+.btn-block {
    margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
    width: 100%;
}

.fade {
    transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
    .fade {
        transition: none;
    }
}

.fade:not(.show) {
    opacity: 0;
}

.collapse:not(.show) {
    display: none;
}

.card .card-body {
    padding: 30px 20px 20px 20px;
    /* border-top : 1px solid;
    margin : 0px 20px;*/
}

.card hr {
    margin-bottom: 0;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none;
    }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
    position: relative;
}

.dropdown-toggle {
    white-space: nowrap;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
    margin-left: 0;
}

.form-group-dropdown {
    max-width: 398px;
    width: -webkit-fill-available;
}

.form-group-dropdown .dropdown-menu {
    box-sizing: border-box;
    background: #FFFFFF; 
    border: 1px solid #CCCCCC; 
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    max-width: 398px;
    width: 100%;
    top: -5px !important;
    padding: 0;
    margin-right: 28px;
}

.form-group-dropdown .dropdown-item-basic {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    text-align: left; 
    color: #444444;
    padding-left: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 0;
    text-decoration: none!important;
}

.form-group-dropdown .dropdown-item-basic.active, .dropdown-item-basic:active {
    background: #D5DFE5; 
     
    font-weight: normal !important;
    font-size: 16px !important; 
    line-height: 19px; 
    background-color: #D5DFE5 !important;
    color: #020C41 !important;
}



.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}

.dropdown-menu-left {
    right: auto;
    left: 0;
}

.dropdown-menu-right {
    right: 0;
    left: auto;
}

@media (min-width: 576px) {
    .dropdown-menu-sm-left {
        right: auto;
        left: 0;
    }
    .dropdown-menu-sm-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 768px) {
    .dropdown-menu-md-left {
        right: auto;
        left: 0;
    }
    .dropdown-menu-md-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 992px) {
    .dropdown-menu-lg-left {
        right: auto;
        left: 0;
    }
    .dropdown-menu-lg-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 1200px) {
    .dropdown-menu-xl-left {
        right: auto;
        left: 0;
    }
    .dropdown-menu-xl-right {
        right: 0;
        left: auto;
    }
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
    vertical-align: 0;
}

.dropright .dropdown-toggle:empty::after {
    margin-left: 0;
}


.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    display: none;
}


.dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
    vertical-align: 0;
}

.dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
}



.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
    right: auto;
    bottom: auto;
}

.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
}

@media (max-width: 767px) {
    .btn-header {
        display: none;
    }
    .btn-idioma{
        margin: auto !important;
    }
}

@media (max-width: 425px) {
    .btn-idioma{
        margin-right: 15px !important;
    }
}

/*Estilo para hr de dividers en card*/

.headerDividers {
    border: 1px solid #DDDDDD;
    height: 21px;
}

.cardDividers {
    width: 33.333%;
    height: 5px;
    background: #020C41;
    margin-left: 0px;
}

.dividers-a {
    background: radial-gradient(92.12% 90.78% at 87.2% 90.78%, #1D3148 35.18%, #3D698A 100%);
    mix-blend-mode: normal;
    width: 100%;
    height: 100%;
} 

.dividers-b {
    background: #FFFFFF;
    mix-blend-mode: normal;
    width: 100%;
    height: 100%;
}

.dividers-c {
    background: #F8F8F8;
    mix-blend-mode: normal;
    width: 100%;
    height: 100%;
}

.dividers-d {
    background: #E5E5E5;
    mix-blend-mode: normal;
    width: 100%;
    height: 100%;
}

.dividers-e {
    background: #555555;
    mix-blend-mode: normal;
    width: 100%;
    height: 100%;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #020C41;
    text-decoration: none;
    background: #D5DFE5;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #6c757d;
    white-space: nowrap;
}

.dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #212529;
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.btn-group>.btn:hover,
.btn-group-vertical>.btn:hover {
    z-index: 1;
}

.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
    z-index: 1;
}

.btn-toolbar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.btn-toolbar .input-group {
    width: auto;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child) {
    margin-left: -1px;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
    margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
    margin-right: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

.btn-group-vertical {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: center;
    justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
    width: 100%;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
    margin-top: -1px;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
    margin-bottom: 0;
}

.btn-group-toggle>.btn input[type="radio"],
.btn-group-toggle>.btn input[type="checkbox"],
.btn-group-toggle>.btn-group>.btn input[type="radio"],
.btn-group-toggle>.btn-group>.btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.input-group-dropdown i{
    color: #020C41;
    font-size: 24px;
    margin-left: -16px;
}

.input-group-dropdown input{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.4px;
    border-bottom: 1px solid #AAAAAA !important;
    max-width: 398px;
    width: 398px;
    background-color: transparent;
}

.input-group-dropdown label{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: justify;
    width: 100%;
    margin-bottom: 0;
    color: #020C41;
}

.input-group-dropdown{
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    width: 100%;
    align-items: center;
}

.input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

.input-group>label {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-align: justify;
    color: #020C41;
    width: 100%;
    margin-bottom: 0;
}

.input-basic>label {
    color: #020C41;
}

.input-group>input {
    padding-left: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    text-align: justify;
    color: #020C41;
}

.input-group>.form-control,
.input-group>.form-control-plaintext,
.input-group>.custom-select,
.input-group>.custom-file {
    position: relative;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0;
    border: none;
    border-bottom: 1px solid #020C41;
    border-radius: 0;
}

.input-group>.form-control+.form-control,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.custom-file,
.input-group>.form-control-plaintext+.form-control,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.custom-select+.form-control,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.custom-file,
.input-group>.custom-file+.form-control,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.custom-file {
    margin-left: -1px;
}

.input-group>.form-control:focus,
.input-group>.custom-select:focus,
.input-group>.custom-file .custom-file-input:focus~.custom-file-label {
    z-index: 3;
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    border-bottom: 1px solid #020C41 !important;
}

.input-group>.custom-file .custom-file-input:focus {
    z-index: 4;
}

.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child) {
    border: none;
    border-bottom: 1px solid #020C41;
    border-radius: 0;
}

.inputTrue {
    border-bottom: 1px solid #42A94B !important;
    display: flex;
    align-items: center;
}

.inputFalse {
    border-bottom: 1px solid #CA0F0F !important;
    display: flex;
    align-items: center;
}

.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
    border-bottom: 1px solid #020C41;
    border-radius: 0;
}

.input-group>.custom-file {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #020C41;
    border-radius: 0;
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::after {
    border-bottom: 1px solid #AAAAAA;
    border-radius: 0;
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
    border-bottom: 1px solid #AAAAAA;
    border-radius: 0;
}

.input-group-prepend,
.input-group-append {
    display: -ms-flexbox;
    display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
    position: relative;
    z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
    z-index: 3;
}

.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.input-group-text,
.input-group-append .input-group-text+.btn {
    margin-left: -1px;
}

.input-group-prepend {
    margin-right: -1px;
}

.input-group-append {
    margin-left: -1px;
    top: 1.7rem;
}

.input-group .input-group-append {
    top: 1.25rem;
}

.input-group-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.375rem 0.75rem;
    padding-right: 0;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.inputTrue i {
    color: #42A94B;
    font-size: 14.5px;
}

.input-group-append span {
    border: 0 !important;
}

.inputFalse i {
    color: #CA0F0F;
    font-size: 14.5px;
}

.inputBasic {
    display: flex;
    align-items: center;
}

.inputBasic i {
    color: #AAAAAA;
    font-size: 14.5px;
}

.msnError {
    color: #CA0F0F !important;
    font-weight: 300 !important;
    font-size: 10px !important;
    line-height: 12px !important;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
    margin-top: 0;
}

.input-group-lg>.form-control:not(textarea),
.input-group-lg>.custom-select {
    height: calc(1.5em + 1rem + 2px);
}

.input-group-lg>.form-control,
.input-group-lg>.custom-select,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-append>.btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.input-group-sm>.form-control:not(textarea),
.input-group-sm>.custom-select {
    height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm>.form-control,
.input-group-sm>.custom-select,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-append>.btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
    padding-right: 1.75rem;
}

.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text,
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
    border: none;
    border-bottom: 1px solid #AAAAAA;
    border-radius: 0;
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}

.custom-control-inline {
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 1rem;
}

.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}

.custom-switch .custom-control-input:checked~.custom-control-label::before {
    background: #FF8200;
    border: 1.5px solid #FF8200;
    box-sizing: border-box;
    border-radius: 15.5px;
}

.custom-control-input:checked~.custom-control-label::before {
    background: #425462;
    border: 1px solid #425462;
    color: #425462;
}

.custom-radio-input:checked~.custom-radio-label::before {
    background: #425462;
    border: 1px solid #425462;
    color: #425462;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #80bdff;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff;
}

.custom-control-input[disabled]~.custom-control-label,
.custom-control-input:disabled~.custom-control-label {
    color: #6c757d;
}

.custom-control-input[disabled]~.custom-control-label::before,
.custom-control-input:disabled~.custom-control-label::before {
    background-color: #e9ecef;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    padding-left: 8px;
}

.custom-radio-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    padding-left: 8px;
}

.custom-control.hover .custom-control-label::before {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.custom-radio.hover .custom-radio-label::after{
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.custom-control-label:hover:before {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.custom-control-input:hover ~ .custom-control-label::before {
    background-color: #F1F1F1;
}

.custom-control-label::before {
    position: absolute;
    top: 0.1rem;
    left: -1.5rem;
    display: block;
    width: 20px !important;
    height: 20px !important;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: 1px solid #CCCCCC !important;
}

.custom-control-label::after {
    position: absolute;
    top: 0.1rem;
    left: -1.5rem;
    display: block;
    width: 20px;
    height: 20px;
    content: "";
    background: no-repeat 50% / 50% 50%;
    border: 1px solid #CCCCCC !important;
}

.custom-radio-label:hover:after {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.custom-radio-label::before {
    position: absolute;
    top: 0.45rem;
    left: -1.2rem;
    display: block;
    width: 10px;
    height: 10px;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border-radius: 1rem;
}

.custom-radio-label::after {
    position: absolute;
    top: 0.1rem;
    left: -1.5rem;
    display: block;
    width: 20px;
    height: 20px;
    content: "";
    background: no-repeat 50% / 50% 50%;
    border-radius: 1rem;
    border: 2px solid #CCCCCC;
    box-sizing: border-box;
}

.custom-checkbox.hover .custom-control-label::after {
    border: none;
    box-shadow: none;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e") !important;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    border-color: #007bff;
    background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
    border-radius: 50%;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    border: 2px solid #425462;
    box-sizing: border-box;
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: #020C41;
}

.custom-radio-input:checked~.custom-radio-label::after {
    background: transparent;
    border: 2px solid #425462;
    box-sizing: border-box;
}

.custom-switch {
    padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem !important;
    pointer-events: all;
    border-radius: 1rem;
    border-color: #F1F1F1 !important;
    background: #F1F1F1;
}

.custom-switch .custom-control-label::after {
    top: 0.2rem;
    left: calc(-2.6rem + 2px);
    width: 17px;
    height: 18px;
    background-color: #FF8200;
    border-radius: 0.5rem;
    border: 1px solid #FF8200 !important;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .custom-switch .custom-control-label::after {
        transition: none;
    }
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.86rem);
    transform: translateX(0.86rem);
    border: 1px solid #FF8200 !important;
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
    display: inline-block;
    width: 98.5%;
    height: 50px;
    line-height: 1.5;
    color: #0b2343;
    border-radius: 0;
    appearance: none;
    padding-left: 20px;
    vertical-align: middle;
    background: none;
}

.custom-select:focus::-ms-value {
    color: #495057;
    background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
}

.custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef;
}

.custom-select::-ms-expand {
    display: none;
}

.custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
}

.custom-select-lg {
    height: calc(1.5em + 1rem + 2px);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.25rem;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin-bottom: 0;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    opacity: 0;
}

.custom-file-input:focus~.custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input[disabled]~.custom-file-label,
.custom-file-input:disabled~.custom-file-label {
    background-color: #e9ecef;
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse";
}

.custom-file-input~.custom-file-label[data-browse]::after {
    content: attr(data-browse);
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
    width: 100%;
    height: 1.4rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.custom-range:focus {
    outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-focus-outer {
    border: 0;
}

.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none;
    }
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none;
    }
}

.custom-range::-moz-range-thumb:active {
    background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none;
    }
}

.custom-range::-ms-thumb:active {
    background-color: #b3d7ff;
}

.custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
    cursor: default;
}

.custom-range:disabled::-ms-thumb {
    background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
        transition: none;
    }
}

.nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
    text-decoration: none;
}

.nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default;
}

@media (min-width: 992px){
    .tab-footer a {
        font-size: 0.93vw;
        line-height: 1.30vw;
    }
}

@media (max-width: 992px){
    .tab-footer a {
        font-size: 14px;
        line-height: 16px;
    }
}

@media (max-width: 767px){
    .tab-footer .btn-link {
        text-align: right;
    }
    .nav-tabs hr {
        width: 0 !important;
        margin-left: -1px;
        margin-right: -1px;
    }
}

@media (max-width: 767px) {
    .nav-tabs .nav-item {
        padding: 10px 10px !important;
    }
    .nav-link.active h4 {
        color: #FFFFFF !important;
    }
}

.nav-tabs {
    border-bottom: 2px solid #81A0B2;
    flex-wrap: inherit;
}

.nav-tabs .nav-item {
    margin-bottom: -2px;
    padding-right: 10px;
    min-width: max-content;
    width: auto;
}

.nav-tabs hr {
    margin-left: 11px;
    border-top: 0;
}

/* .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    background: #FFFFFF;
    border-left: 2px solid #81A0B2;
    border-right: 2px solid #81A0B2;
    border-top: 2px solid #81A0B2;
    border-bottom: 2px solid #81A0B2;
    color: #020C41;
    margin-top: 8px;
    border-bottom-width: 0px;
    padding: 0;
    text-align: center;
} */

.nav-tabs .nav-link .h4-nav-link {
    margin: 0px;
    padding-top: 10px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 2px solid #81A0B2;
    color: #020C41;
    font-weight: 500 !important;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
    background: #CCCCCC;
    color: #AAAAAA;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 0px solid #FFFFFF;
    border-top: 2px solid #CCCCCC;
    margin-top: 8px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    /* background: #FFFFFF;
    border-left: 2px solid #81A0B2;
    border-right: 2px solid #81A0B2;
    border-top: 10px solid #81A0B2; */
    /* color: #020C41; */
    /* margin-top: 0px; */
}

.nav-tabs .nav-link.active .h4-nav-link {
    margin: 0px;
    padding-top: 10px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 2px solid #FFFFFF;
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-pills .nav-link {
    border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #007bff;
}

.nav-fill .nav-item {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center;
}

.nav-justified .nav-item {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
}

.tab-table {
    overflow-y: auto;
    margin: 30px;
    padding: 0 !important;
}

.tab-table::-webkit-scrollbar {
    height: 0;
}

.tab-content>.tab-pane {
    display: none;
    padding: 30px;
}

.tab-content>.active {
    display: block;
}

.nav-tabs-wrapper {
    max-width: 996px;
    overflow-y: hidden;
}

.nav-tabs-wrapper::-webkit-scrollbar {
    height: 0;
}

.nav-tabs-wrapper .nav {
    width: auto;
}

.border-tab-content {
    max-width: 996px;
    display: block;
    background: #FFFFFF;
    margin-top: -2px;
    border-top: 2px solid #81A0B2;
    border-bottom: 2px solid #81A0B2;
    border-left: 2px solid #81A0B2;
    border-right: 2px solid #81A0B2;
    box-shadow: 2px 0px 6px rgba(0, 0, 0, 0.2);
}

.navbar {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
    text-decoration: none;
}

.navbar-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none;
}

.navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
    list-style: none;
}

.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
    text-decoration: none;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (max-width: 575.98px) {
    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid,
    .navbar-expand-sm>.container-sm,
    .navbar-expand-sm>.container-md,
    .navbar-expand-sm>.container-lg,
    .navbar-expand-sm>.container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid,
    .navbar-expand-sm>.container-sm,
    .navbar-expand-sm>.container-md,
    .navbar-expand-sm>.container-lg,
    .navbar-expand-sm>.container-xl {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
    .navbar-expand-sm .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler {
        display: none;
    }
}

@media (max-width: 767.98px) {
    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid,
    .navbar-expand-md>.container-sm,
    .navbar-expand-md>.container-md,
    .navbar-expand-md>.container-lg,
    .navbar-expand-md>.container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid,
    .navbar-expand-md>.container-sm,
    .navbar-expand-md>.container-md,
    .navbar-expand-md>.container-lg,
    .navbar-expand-md>.container-xl {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
    .navbar-expand-md .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler {
        display: none;
    }
}

@media (max-width: 991.98px) {
    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid,
    .navbar-expand-lg>.container-sm,
    .navbar-expand-lg>.container-md,
    .navbar-expand-lg>.container-lg,
    .navbar-expand-lg>.container-xl {
        padding-right: 0;
        padding-left: 0;
    }
    .navbar-expand-lg {
        background: radial-gradient(92.12% 90.78% at 87.2% 90.78%, #1D3148 35.18%, #3D698A 100%);
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start;
        background: radial-gradient(92.12% 90.78% at 87.2% 90.78%, #1D3148 35.18%, #3D698A 100%);
        mix-blend-mode: normal;
        box-shadow: 2px 0px 6px rgba(0, 0, 0, 0.2);
        padding-left: 120px;
        padding-right: 120px;
        height: 50px !important;
    }
    .navbar-expand-lg .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-lg .navbar-nav .nav-item:not(:last-child) {
        margin-right: 15px;
    }
    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid,
    .navbar-expand-lg>.container-sm,
    .navbar-expand-lg>.container-md,
    .navbar-expand-lg>.container-lg,
    .navbar-expand-lg>.container-xl {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
    .navbar-expand-lg .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

@media (max-width: 1199.98px) {
    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid,
    .navbar-expand-xl>.container-sm,
    .navbar-expand-xl>.container-md,
    .navbar-expand-xl>.container-lg,
    .navbar-expand-xl>.container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid,
    .navbar-expand-xl>.container-sm,
    .navbar-expand-xl>.container-md,
    .navbar-expand-xl>.container-lg,
    .navbar-expand-xl>.container-xl {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
    .navbar-expand-xl .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler {
        display: none;
    }
}

.navbar-expand {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg,
.navbar-expand>.container-xl {
    padding-right: 0;
    padding-left: 0;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.navbar-expand .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}


.navbar-expand .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
}

.navbar-expand .navbar-toggler {
    display: none;
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
    
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    text-align: center;
    width: max-content;
    margin: 0 auto;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    border-bottom: 2px solid #FF8200;
    padding-bottom: 2px;
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
}

.navbar-nav a {
    
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
    color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
    color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
    color: #fff;
}

.card-basic,
.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    /* form_shadows */
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.card-basic .card-body,
.card-ins .card-body,
.card-with-img .card-body {
    padding-top: 0;
    padding-bottom: 30px;
    padding-right: 30px;
    padding-left: 30px;
}

.card-saber-mas .card-body {
    padding-top: 0;
    padding-bottom: 30px;
    padding-right: 30px;
    padding-left: 30px;
}

.card-ins {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background: #81A0B2;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    /* form_shadows */
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    max-width: 720px;
    max-height: 480px;
    min-height: 380px;
}

.card-h {
    margin: 0px;
}

.card-with-img {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    /* form_shadows */
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.card-saber-mas {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    /* form_shadows */
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.card-saber-mas .collapse p {
    margin: 0;
}

.card-with-img-h {
    min-width: 0;
    word-wrap: break-word;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.card-color-100 {
    height: 99px;
    margin-bottom: 5px;
}

@media (max-width: 606px) {
    .card-color-100-radial {
        height: 125px;
    }
    .card-color-100-radial code{
        font-size: 13px !important;
    }
}

.card-color-100-b {
    height: 198px;
    margin-bottom: 5px;
}

.card-color-75,
.card-color-50,
.card-color-25 {
    height: 28px;
    margin-bottom: 5px;
}

@media (max-width: 577px) {
    .steps-web {
        display: none !important;
    }
    .step-m {
        min-Height: 200px;
    }
    .step-icon-m {
        bottom: 4.5rem !important;
    }
    .step-label-m {
        bottom: 5rem !important;
    }
    .step-label-m2 {
        bottom: 0.125rem !important;
        max-Width: 280px !important;
    }
}

@media (min-width: 577px) and (max-width: 767px) {
    .step-m {
        min-Height: 200px;
    }
    .step-icon-m {
        bottom: 4.5rem !important;
    }
    .step-label-m {
        bottom: 5rem !important;
    }
    .step-label-m2 {
        bottom: 0.125rem !important;
        max-Width: 280px !important;
    }
    .card-body-h {
        padding-bottom: 30px !important;
    }
    .card-text-ins {
        font-size: 0.83vw;
    }
    .card-img-h {
        padding-bottom: 0 !important;
        padding-right: 30px !important;
    }
    .car-card-ins .card-item {
        padding-left: 0;
        padding-right: 0;
    }
    .car-card-ins .carousel-inner {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .car-card-ins .carousel-control-prev {
        margin-left: 10px !important
    }
    .car-card-ins .carousel-control-next {
        margin-right: 10px !important
    }
    .car-card-ins .carousel-inner .carousel-item>div {
        display: block;
        overflow: hidden;
    }
    .car-card-pro .card-item {
        padding-left: 0;
        padding-right: 0;
    }
    .car-card-pro .carousel-inner {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
    .car-card-pro .carousel-control-prev {
        margin-left: 10px !important
    }
    .car-card-pro .carousel-control-next {
        margin-right: 10px !important
    }
    .car-card-pro .carousel-inner .carousel-item>div {
        display: block;
        overflow: hidden;
    }
    .car-card-pro .steps {
        padding: 0 !important;
        margin-bottom: 50 !important;
    }
    .steps-web {
        display: none !important;
    }
    .steps-mobile {
        max-width: 400px;
        margin: 0 auto;
    }
    .card-saber-mas .collapse {
        display: flex;
    }
    .card-footer-saber {
        display: none;
    }
    .color-tipog .col-color-4 {
        flex: 0 0 37% !important;
        max-width: 37% !important;
    }
    .color-tipog .col-color-3 {
        flex: 0 0 26% !important;
        max-width: 26% !important;
    }
}

@media (min-width: 768px) {
    .steps-web {
        margin-top: 60px !important;
    }
    .car-card-ins .card-item {
        padding-left: 0;
        padding-right: 15px;
    }
    .car-card-ins .carousel-inner {
        padding-left: 110px !important;
        margin-right: 95px !important;
    }
    .car-card-ins .carousel-control-prev {
        margin-left: 90px !important;
    }
    .car-card-ins .carousel-control-next {
        margin-right: 90px !important;
    }
    .car-card-ins .carousel-inner .carousel-item>div {
        display: flex !important;
        overflow: hidden;
    }
    .car-card-pro .card-item {
        padding-left: 0;
        padding-right: 15px;
    }
    .car-card-pro .carousel-inner {
        padding-left: 110px !important;
        margin-right: 95px !important;
    }
    .car-card-pro .carousel-control-prev {
        margin-left: 90px !important;
    }
    .car-card-pro .carousel-control-next {
        margin-right: 90px !important;
    }
    .car-card-pro .carousel-inner .carousel-item>div {
        display: flex !important;
        overflow: hidden;
    }
    .car-card-pro .steps {
        padding: 0 !important;
        margin-bottom: 50 !important;
    }
    .steps-mobile {
        display: none;
    }
    .card-saber-mas .collapse {
        display: flex;
    }
    .card-footer-saber {
        display: none;
    }
    .color-tipog .col-color-4 {
        flex: 0 0 37% !important;
        max-width: 37% !important;
    }
    .color-tipog .col-color-3 {
        flex: 0 0 26% !important;
        max-width: 26% !important;
    }
}

.car-card-ins .card-with-img {
    max-width: none;
}

.car-card-ins .carousel-control-prev {
    top: 50% !important;
    background: #020C41;
    mix-blend-mode: normal;
    opacity: 0.7;
    border-radius: 5px;
}

.car-card-ins .carousel-control-next {
    top: 50% !important;
    background: #020C41;
    mix-blend-mode: normal;
    opacity: 0.7;
    border-radius: 5px;
}

.car-card-ins .carousel-inner {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
    width: auto !important;
}

.car-card-pro .card-with-img {
    max-width: none;
}

.car-card-pro .carousel-control-prev {
    top: 50% !important;
    background: #020C41;
    mix-blend-mode: normal;
    opacity: 0.7;
    border-radius: 5px;
}

.car-card-pro .carousel-control-next {
    top: 50% !important;
    background: #020C41;
    mix-blend-mode: normal;
    opacity: 0.7;
    border-radius: 5px;
}

.car-card-pro .carousel-inner {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
    width: auto !important;
}

.card-collapsePrimary {
    border-left: 10px solid #020C41;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #DDDDDD !important;
}

.card-collapseSecondary {
    border-radius: 0;
    box-shadow: none;
    border: none;
    border-top: 2px solid #DDDDDD;
    border-bottom: 2px solid #DDDDDD !important;
}

.cardHorizontal {
    padding-top: 30px;
}

.card>hr {
    margin-right: 0;
    margin-left: 0;
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.dividerCollapse {
    width: 97%;
    margin-top: 0 !important;
    margin-bottom: 30 !important;
    border-top: 1px solid #DDDDDD !important;
}

.card-title {
    padding-top: 30px;
    padding-bottom: 0;
    padding-right: 30px;
    padding-left: 30px;
    margin: 0;
}

.card-title-ins {
    margin: 0;
    color: #FFFFFF;
}

.card-title h4 {
    color: #020C41;
    margin: 0;
}

.card-divider {
    padding-bottom: 0;
    padding-left: 30px;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
}

.card-body-ins {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding-top: 0;
    padding-bottom: 30px;
    padding-right: 30px;
    padding-left: 30px;
    position: absolute;
    bottom: 0;
}

.card-body-ins-car {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding-top: 3.125vw;        
    padding-bottom: 1.5625vw;
    padding-right: 10.41666vw;     
    padding-left: 7.8125vw;   
    position: absolute;
    top: 0;
}


.card-body-ins-car p {
    padding-bottom: 30px;
}

.card-body-h {
    flex: 1 1 auto;
    min-height: 1px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 30px;
    padding-left: 30px;
}

.card-footer {
    height: 49px;
    right: 30px !important;
    padding-top: 0;
    padding-bottom: 30px !important;
    padding-right: 30px !important;
    padding-left: 30px !important;
    margin: 0;
    border: 0;
    background: #ffffff;
}

.card-footer-saber {
    height: 49px;
    right: 30px !important;
    padding-top: 0;
    padding-bottom: 30px !important;
    padding-right: 30px !important;
    padding-left: 30px !important;
    margin: 0;
    border: 0;
    background: #ffffff;
}

.card-footer a,
.card-footer-saber a {
    right: 30px !important;
    float: right !important;
}

.card-footer-venta {
    padding-top: 0;
    padding-bottom: 30px;
    padding-right: 30px;
    padding-left: 30px;
    background: #ffffff;
}

.card-footer-venta button {
    width: 100%;
    background: #ffffff;
}

.card-footer-venta i {
    float: right !important;
    color: #FF8200;
    padding: 0;
    line-height: initial;
    line-height: 125%;
}

.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
}

@media (min-width: 992px) {
    .card-text {
        margin-top: 30px;
        letter-spacing: 0.4px;
        
        font-style: normal;
        font-weight: normal;
        font-size: 0.93vw;
        line-height: 1.30vw;
        color: #444444;
    }
}

@media (max-width: 992px) {
    .card-text {
        margin-top: 30px;
        letter-spacing: 0.4px;
        
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #444444;
    }
}

.card-text-ins {
    margin: 0;
    letter-spacing: 0.4px;
    
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
}

.card-link:hover {
    text-decoration: none;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    color: #020C41;
    border-bottom: none !important;
}

.card-header .fa-angle-down {
    color: #020C41;
}

.input-group-dropdown[aria-expanded=true] .fa-angle-down {
    transition: .3s transform ease-in-out;
    transform: rotate(180deg);
}

.input-group-dropdown[aria-expanded=false] .fa-angle-down {
    transition: .3s transform ease-in-out;
}

.accordion .card .card-header[aria-expanded=true] .fa-angle-down {
    transition: .3s transform ease-in-out;
    transform: rotate(180deg);
}

.accordion .card .card-header[aria-expanded=false] .fa-angle-down {
    transition: .3s transform ease-in-out;
}

.accordion h4 {
    padding-left: 20px;
}

.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0;
}

.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}

.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}

.card-img-h {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding-top: 30px !important;
    padding-bottom: 30px;
    padding-right: 1px;
    padding-left: 30px !important;
    background: #FFFFFF;
}

.card-img-v {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding-top: 30px !important;
    padding-bottom: 0 !important;
    padding-right: 30px !important;
    padding-left: 30px !important;
    background: #FFFFFF;
    margin-bottom: -15px;
}

.card-img-ins {
    max-width: 720px;
    width: 100%;
    padding: 0;
    margin: 0;
}

.card-img-v img {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    width: 100%;
    padding: 0;
    background: #FFFFFF;
}

.card-img-b {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    background: #FFFFFF;
}

.card-img-b img {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    width: 100%;
    padding: 0;
    background: #FFFFFF;
}

.card-img,
.card-img-top {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-deck {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
    .card-deck .card {
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
    }
}

.card-group>.card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-group {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }
    .card-group>.card {
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-bottom: 0;
    }
    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0;
    }
    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .card-group>.card:not(:last-child) .card-img-top,
    .card-group>.card:not(:last-child) .card-header {
        border-top-right-radius: 0;
    }
    .card-group>.card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0;
    }
    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .card-group>.card:not(:first-child) .card-img-top,
    .card-group>.card:not(:first-child) .card-header {
        border-top-left-radius: 0;
    }
    .card-group>.card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0;
    }
}

.card-columns .card {
    margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
    .card-columns {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }
    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.accordion>.card {
    overflow: hidden;
}

.accordion>.card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion>.card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.accordion>.card>.card-header {
    border-radius: 0;
    margin-bottom: -1px;
}

.bullet-basic {
    color: #020C41;
    font-size: 1.041666vw;
    margin-bottom: 15px;
}

ol .bullet-basic {
    padding-left: 7px;
}

ul.b-upper-alpha .bullet-basic {
    padding-left: 7px;
}

ul.b-lower-alpha .bullet-basic {
    padding-left: 7px;
}

ul.b-upper-roman .bullet-basic {
    padding-left: 7px;
}

ul.b-lower-roman .bullet-basic {
    padding-left: 7px;
}


.bullet-basic-title {
    color: #81A0B2 !important;
}

.bullet-basic p {
    color: #444444;
    margin-bottom: 0;
}

ul.b-square {
    list-style-type: square;
}

ul.b-lower-alpha {
    list-style-type: lower-alpha;
}

ul.b-upper-alpha {
    list-style-type: upper-alpha;
}

ul.b-lower-roman {
    list-style-type: lower-roman;
}

ul.b-upper-roman {
    list-style-type: upper-roman;
}

.breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/";
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: none;
}

.breadcrumb-item.active {
    color: #6c757d;
}

.pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .badge {
        transition: none;
    }
}

a.badge:hover,
a.badge:focus {
    text-decoration: none;
}

.badge:empty {
    display: none;
}

.btn .badge {
    position: relative;
    top: -1px;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

.badge-primary {
    color: #fff;
    background-color: #007bff;
}

a.badge-primary:hover,
a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc;
}

a.badge-primary:focus,
a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
    color: #fff;
    background-color: #6c757d;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
    color: #fff;
    background-color: #28a745;
}

a.badge-success:hover,
a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34;
}

a.badge-success:focus,
a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
    color: #fff;
    background-color: #17a2b8;
}

a.badge-info:hover,
a.badge-info:focus {
    color: #fff;
    background-color: #117a8b;
}

a.badge-info:focus,
a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
    color: #212529;
    background-color: #ffc107;
}

a.badge-warning:hover,
a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00;
}

a.badge-warning:focus,
a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
    color: #fff;
    background-color: #dc3545;
}

a.badge-danger:hover,
a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130;
}

a.badge-danger:focus,
a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
    color: #212529;
    background-color: #f8f9fa;
}

a.badge-light:hover,
a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5;
}

a.badge-light:focus,
a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
    color: #fff;
    background-color: #343a40;
}

a.badge-dark:hover,
a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124;
}

a.badge-dark:focus,
a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: 0.3rem;
}

@media (min-width: 576px) {
    .jumbotron {
        padding: 4rem 2rem;
    }
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
}

@media (max-width: 530px) {
    .alert-grid {
        display: none !important;
    }
}

@media (max-width: 896px) {
    .content-grid {
        width: inherit;
    }
}


.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.alert-heading {
    color: inherit;
}

.alert-link {
    font-weight: 700;
}

.alert-dismissible {
    padding-right: 4rem;
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
}

.alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}

.alert-primary hr {
    border-top-color: #9fcdff;
}

.alert-primary .alert-link {
    color: #002752;
}

.alert-secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
}

.alert-secondary hr {
    border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
    color: #202326;
}

.alert-success {
    background: rgba(66, 169, 75, 0.15);
    border: 2px solid #42A94B;
    box-sizing: border-box;
    border-radius: 5px;
    padding-top: 17px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
}

.alert-success p{
    color: #444444;
    margin: 0px;
}

.alert-success h5 {
    color: #42A94B;
}

.alert-success .fa-check-circle {
    color: #42A94B;
    margin-right: 10px !important;
    padding: 0;
    font-size: 1.041666vw !important;
    line-height: 1.46vw!important;  
}

.alert-success .fa-times {
    color: #42A94B;
    right: 15px;
    position: absolute;
    font-size: 1.041666vw !important;
}

.alert-success .fa-check-circle:before {
    font-size: 1.041666vw;
}


.alert-danger p {
    margin: 0px;
    color: #444444;
}

.alert-info p {
    margin: 0px;
    color: #444444;
}

.alert-warning p {
    margin: 0px;
    color: #444444;
}
.alert-danger .alert-body a,
.alert-info .alert-body a,
.alert-warning .alert-body a {
    font-size: inherit;
}

@media (max-width: 992px) {
    .alert-success .fa-check-circle {
        font-size: 14px;
        line-height: 16px;
    }
    .alert-success .fa-times {
        font-size: 14px !important;
        line-height: 16px;
    }
    .alert-danger .fa-times {
        font-size: 14px !important;
        line-height: 16px;
    }
    .alert-warning .fa-times {
        font-size: 14px !important;
        line-height: 16px;
    }
    .alert-info .fa-times {
        font-size: 14px !important;
        line-height: 16px;
    }
    .alert-success .fa-check-circle:before {
        font-size: 14px !important;
        line-height: 16px;
    }
    .alert-danger .fa-times-circle:before  {
        font-size: 14px !important;
        line-height: 16px;
    }
    .alert-warning .fa-exclamation-triangle:before  {
        font-size: 14px !important;
        line-height: 16px;
    }
    .alert-info .fa-info-circle:before  {
        font-size: 14px !important;
        line-height: 16px;
    }
}

.alert-success hr {
    border-top-color: #b1dfbb;
}

.alert-success .alert-link {
    color: #0b2e13;
}

.alert-info {
    background: rgba(66, 109, 169, 0.15);
    border: 2px solid #426DA9;
    box-sizing: border-box;
    border-radius: 5px;
    padding-top: 17px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
}


.alert-info h5 {
    color: #020C41;
}

.alert-info .fa-info-circle {
    color: #020C41;
    margin-right: 10px !important;
    padding: 0;
    font-size: 1.041666vw !important;
    line-height: 1.46vw!important;
}

.alert-info .fa-times {
    color: #020C41;
    right: 15px;
    position: absolute;
    font-size: 1.041666vw;
    line-height: 1.46vw!important;
}

.alert-info .fa-info-circle:before {
    font-size: 1.041666vw;
}

.alert-info hr {
    border-top-color: #abdde5;
}

.alert-info .alert-link {
    color: #062c33;
}

.alert-warning {
    background: rgba(255, 200, 69, 0.15);
    border: 2px solid #FFC845;
    box-sizing: border-box;
    border-radius: 5px;
    padding-top: 17px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
}


.alert-warning h5 {
    color: #FFC845;
}

.alert-warning .fa-exclamation-triangle {
    color: #FFC845;
    margin-right: 10px !important;
    padding: 0;
    font-size: 1.041666vw !important;
    line-height: 1.46vw!important;
}

.alert-warning .fa-times {
    color: #FFC845;
    right: 15px;
    position: absolute;
    font-size: 1.041666vw;
}

.alert-warning .fa-exclamation-triangle:before {
    font-size: 1.041666vw;
}

.alert-warning hr {
    border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
    color: #533f03;
}

.alert-danger {
    background: rgba(202, 15, 15, 0.15);
    border: 2px solid #CA0F0F;
    box-sizing: border-box;
    border-radius: 5px;
    padding-top: 17px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
}


.alert-danger h5 {
    color: #CA0F0F;
}

.alert-danger .fa-times-circle {
    color: #CA0F0F;
    margin-right: 10px !important;
    padding: 0;
    font-size: 1.041666vw !important;
    line-height: 1.46vw!important;
}

.alert-danger .fa-times {
    color: #CA0F0F;
    right: 15px;
    position: absolute;
    font-size: 1.041666vw;
}

.alert-danger .fa-times-circle:before {
    font-size: 1.041666vw;
}

.alert-danger hr {
    border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
    color: #491217;
}

.alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe;
}

.alert-light hr {
    border-top-color: #ececf6;
}

.alert-light .alert-link {
    color: #686868;
}

.alert-dark {
    color: #1b1e21;
    background-color: #d6d8d9;
    border-color: #c6c8ca;
}

.alert-dark hr {
    border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
    color: #040505;
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}

.progress {
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}

.progress-bar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #007bff;
    transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none;
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
}

.progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
        -webkit-animation: none;
        animation: none;
    }
}

.media {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
}

.media-body {
    -ms-flex: 1;
    flex: 1;
}

.list-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}

.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
}

.list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.list-group-item+.list-group-item {
    border-top-width: 0;
}

.list-group-item+.list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px;
}

.list-group-horizontal {
    -ms-flex-direction: row;
    flex-direction: row;
}

.list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
}

.list-group-horizontal .list-group-item.active {
    margin-top: 0;
}

.list-group-horizontal .list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
}

.list-group-horizontal .list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
}

@media (min-width: 576px) {
    .list-group-horizontal-sm {
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .list-group-horizontal-sm .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-sm .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-sm .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-sm .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-sm .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 768px) {
    .list-group-horizontal-md {
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .list-group-horizontal-md .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-md .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-md .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-md .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-md .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 992px) {
    .list-group-horizontal-lg {
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .list-group-horizontal-lg .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-lg .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-lg .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-lg .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-lg .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 1200px) {
    .list-group-horizontal-xl {
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .list-group-horizontal-xl .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-xl .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-xl .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-xl .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-xl .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

.list-group-flush .list-group-item {
    border-right-width: 0;
    border-left-width: 0;
    border-radius: 0;
}

.list-group-flush .list-group-item:first-child {
    border-top-width: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
    border-bottom-width: 0;
}

.list-group-item-primary {
    color: #004085;
    background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085;
}

.list-group-item-secondary {
    color: #383d41;
    background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41;
}

.list-group-item-success {
    color: #155724;
    background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724;
}

.list-group-item-info {
    color: #0c5460;
    background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460;
}

.list-group-item-warning {
    color: #856404;
    background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404;
}

.list-group-item-danger {
    color: #721c24;
    background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24;
}

.list-group-item-light {
    color: #818182;
    background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182;
}

.list-group-item-dark {
    color: #1b1e21;
    background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.close:hover {
    color: #000;
    text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
    opacity: .75;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

a.close.disabled {
    pointer-events: none;
}

.toast {
    max-width: 350px;
    overflow: hidden;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: 0.25rem;
}

.toast:not(:last-child) {
    margin-bottom: 0.75rem;
}

.toast.showing {
    opacity: 1;
}

.toast.show {
    display: block;
    opacity: 1;
}

.toast.hide {
    display: none;
}

.toast-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: #6c757d;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
    padding: 0.75rem;
}

.modal-open {
    overflow: hidden;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.modal-dialog {
    position: relative;
    pointer-events: none;
    width: 600px;
}

@media (max-width: 499px) {
    .modal-dialog {
        max-width: 500px;
        width: 100%;
        margin: 0 !important;
    }
    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem);
    }
    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem);
    }
    .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem);
    }
    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 500px) and (max-width: 767px) {
    .modal-dialog {
        width: 500px;
        margin: auto;
    }
    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem);
    }
    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem);
    }
    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }
    .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem);
    }
    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 768px) {
    .modal-dialog {
        max-width: 500px;
    }
    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem);
    }
    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem);
    }
    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }
    .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem);
    }
    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}

.modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none;
    }
}

.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
}

.modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
}

.modal-dialog-scrollable {
    display: -ms-flexbox;
    display: flex;
    max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.modal-dialog-centered {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
    content: none;
}

.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
    bottom: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.5;
}

.modal-header {
    padding: 0;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 0px;
    border: none;
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-title button {
    opacity: 1;
}

.modal-body {
    padding: 0;
    margin-top: 10px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: #444444;
}

.modal-footer {
    padding: 0 !important;
    margin-top: 10px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
    border: none;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}

@media (min-width: 768px) {
    .modal-body {
        font-size: 18px;
        line-height: 25px;
    }
}


.modal-footer>* {
    margin: 0.25rem;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem);
    }
    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem);
    }
    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }
    .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem);
    }
    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}

@media (max-width: 576px) {
    .tooltip .bottom {
        transform: translate(-78%, 0) !important;
        min-width: 235px !important;
    }
    .tooltip .bottom i {
        left: 84% !important;
    }
}

.tooltip .fa-question-circle {
    color: #81A0B2;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .tooltip .bottom {
        min-width: 27vw !important;
        top: 63px;
        left: 6px;
    }
    .tooltip P {
        font-size: 1.3vw !important;
        line-height: 1.5vw !important;
        letter-spacing: 0.0001vw;
    }
}

@media (min-width: 1025px) and (max-width: 1386px) {
    .tooltip P {
        font-size: 0.9vw !important;
        line-height: 1vw !important;
        letter-spacing: 0.0001vw;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .tooltip P {
        font-size: 11px !important;
        line-height: 12px !important;
        margin-bottom: 15px !important;
        margin-top: 15px !important;
    }
}

.input-group .tooltip {
    opacity: 1;
    line-height: 3;
    display: flex;
    align-items: center;
}

.tooltip {
    display:inline-block;
    position:relative;
    border-bottom: 1px solid #AAAAAA;
    text-align:left;
}

.tooltip h3 {
    margin:12px 0;
}

.tooltip P {
    
    font-style: normal;
    font-weight: normal;
    font-size: 0.72916vw;
    line-height: 0.833333vw;
    color: #444444;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
    margin-top: 30px;
}

.tooltip .bottom {
    min-width:370px;
    top:63px;
    transform:translate(-88%, 0);
    border-radius:6px;
    position:absolute;
    box-sizing:border-box;
    display:none;
    background: #FFFFFF; 
    border: 1px solid #020C41; 
    border-bottom: 10px solid #020C41; 
     
    font-style: normal;
    font-weight: normal;
    font-size: 14px; 
    line-height: 16px; 
    color: #444444;
}



.tooltip:hover .bottom {
    display:block;
}

.tooltip .bottom img {
    width:400px;
}

.tooltip .bottom i {
    position: absolute;
    bottom: 100%;
    left: 92%;
    margin-left: -15px;
    width: 32px;
    height: 19px;
    overflow: hidden;
}

.tooltip .bottom i::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 24px;
    left: 50%;
    transform: translate(-50%,50%) rotate(45deg);
    background: #FFFFFF;
    border: 1px solid #020C41;
}

.tooltip.show {
    opacity: 0.9;
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
}

.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
    padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
    padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
    padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
    padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
}

.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem;
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
    margin-bottom: 0.5rem;
}

.bs-popover-top>.arrow,
.bs-popover-auto[x-placement^="top"]>.arrow {
    bottom: calc(-0.5rem - 1px);
}

.bs-popover-top>.arrow::before,
.bs-popover-auto[x-placement^="top"]>.arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top>.arrow::after,
.bs-popover-auto[x-placement^="top"]>.arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem;
}

.bs-popover-right>.arrow,
.bs-popover-auto[x-placement^="right"]>.arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-right>.arrow::before,
.bs-popover-auto[x-placement^="right"]>.arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right>.arrow::after,
.bs-popover-auto[x-placement^="right"]>.arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem;
}

.bs-popover-bottom>.arrow,
.bs-popover-auto[x-placement^="bottom"]>.arrow {
    top: calc(-0.5rem - 1px);
}

.bs-popover-bottom>.arrow::before,
.bs-popover-auto[x-placement^="bottom"]>.arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom>.arrow::after,
.bs-popover-auto[x-placement^="bottom"]>.arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
    margin-right: 0.5rem;
}

.bs-popover-left>.arrow,
.bs-popover-auto[x-placement^="left"]>.arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-left>.arrow::before,
.bs-popover-auto[x-placement^="left"]>.arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left>.arrow::after,
.bs-popover-auto[x-placement^="left"]>.arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff;
}

.popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
    display: none;
}

.popover-body {
    padding: 0.5rem 0.75rem;
    color: #212529;
}

.carousel {
    position: relative;
    max-width: 100%;
}

.carousel.pointer-event {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.img-carrusel {
    min-height: 400px;
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: "";
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-item {
        transition: none;
    }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
}

.carrusel-ins {
    min-height: 337px !important;
    background: #81A0B2;
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    -webkit-transform: none;
    transform: none;
    min-height: 400px !important;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
        transition: none;
    }
}

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
        transition: none;
    }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
}

.carousel-control-prev {
    left: 0;
}

.carousel-control-next {
    right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10%;
    left: 0;
    z-index: 15;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

@media (min-width: 768px) {
    .carousel-indicators {
        transform: rotate(90deg);
        margin-left: 80%;
        margin-bottom: 5%;
        margin-right: 0;
    }
}

.carousel-indicators li {
    box-sizing: content-box;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid #FFFFFF;
    transform: rotate(-90deg);
}

@media (prefers-reduced-motion: reduce) {
    .carousel-indicators li {
        transition: none;
    }
}

.carousel-indicators .active {
    opacity: 1;
    background: #FFFFFF;
}

.carousel-caption {
    position: absolute;
    bottom: 0px;
    z-index: 10;
    color: #fff;
    top: 10px;
    padding: 0px 30px 30px 30px;
}

@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        opacity: 1;
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        opacity: 1;
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow .75s linear infinite;
    animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

.align-baseline {
    vertical-align: baseline !important;
}

.align-top {
    vertical-align: top !important;
}

.align-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}

.align-text-bottom {
    vertical-align: text-bottom !important;
}

.align-text-top {
    vertical-align: text-top !important;
}

.bg-primary {
    background-color: #007bff !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: #0062cc !important;
}

.bg-secondary {
    background-color: #6c757d !important;
    padding: 0;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
    background-color: #545b62 !important;
}

.bg-success {
    background-color: #28a745 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
    background-color: #1e7e34 !important;
}

.bg-info {
    background-color: #17a2b8 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
    background-color: #117a8b !important;
}

.bg-warning {
    background-color: #ffc107 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
    background-color: #d39e00 !important;
}

.bg-danger {
    background-color: #dc3545 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
    background-color: #bd2130 !important;
}

.bg-light {
    background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
    background-color: #dae0e5 !important;
}

.bg-dark {
    background-color: #343a40 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
    background-color: #1d2124 !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.border {
    border: 1px solid #dee2e6 !important;
}

.border-top {
    border-top: 1px solid #dee2e6 !important;
}

.border-right {
    border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
    border-left: 1px solid #dee2e6 !important;
}

.border-0 {
    border: 0 !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-right-0 {
    border-right: 0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-left-0 {
    border-left: 0 !important;
}

.border-primary {
    border-color: #007bff !important;
}

.border-secondary {
    border-color: #6c757d !important;
}

.border-success {
    border-color: #28a745 !important;
}

.border-info {
    border-color: #17a2b8 !important;
}

.border-warning {
    border-color: #ffc107 !important;
}

.border-danger {
    border-color: #dc3545 !important;
}

.border-light {
    border-color: #f8f9fa !important;
}

.border-dark {
    border-color: #343a40 !important;
}

.border-white {
    border-color: #fff !important;
}

.rounded-sm {
    border-radius: 0.2rem !important;
}

.rounded {
    border-radius: 0.25rem !important;
}

.rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
}

.rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
    border-radius: 0.3rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}

.d-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
}

@media (min-width: 576px) {
    .d-sm-none {
        display: none !important;
    }
    .d-sm-inline {
        display: inline !important;
    }
    .d-sm-inline-block {
        display: inline-block !important;
    }
    .d-sm-block {
        display: block !important;
    }
    .d-sm-table {
        display: table !important;
    }
    .d-sm-table-row {
        display: table-row !important;
    }
    .d-sm-table-cell {
        display: table-cell !important;
    }
    .d-sm-flex {
        display: -ms-flexbox !important;
        display: flex !important;
    }
    .d-sm-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media (min-width: 768px) {
    .d-md-none {
        display: none !important;
    }
    .d-md-inline {
        display: inline !important;
    }
    .d-md-inline-block {
        display: inline-block !important;
    }
    .d-md-block {
        display: block !important;
    }
    .d-md-table {
        display: table !important;
    }
    .d-md-table-row {
        display: table-row !important;
    }
    .d-md-table-cell {
        display: table-cell !important;
    }
    .d-md-flex {
        display: -ms-flexbox !important;
        display: flex !important;
    }
    .d-md-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display: none !important;
    }
    .d-lg-inline {
        display: inline !important;
    }
    .d-lg-inline-block {
        display: inline-block !important;
    }
    .d-lg-block {
        display: block !important;
    }
    .d-lg-table {
        display: table !important;
    }
    .d-lg-table-row {
        display: table-row !important;
    }
    .d-lg-table-cell {
        display: table-cell !important;
    }
    .d-lg-flex {
        display: -ms-flexbox !important;
        display: flex !important;
    }
    .d-lg-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media (min-width: 1200px) {
    .d-xl-none {
        display: none !important;
    }
    .d-xl-inline {
        display: inline !important;
    }
    .d-xl-inline-block {
        display: inline-block !important;
    }
    .d-xl-block {
        display: block !important;
    }
    .d-xl-table {
        display: table !important;
    }
    .d-xl-table-row {
        display: table-row !important;
    }
    .d-xl-table-cell {
        display: table-cell !important;
    }
    .d-xl-flex {
        display: -ms-flexbox !important;
        display: flex !important;
    }
    .d-xl-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media print {
    .d-print-none {
        display: none !important;
    }
    .d-print-inline {
        display: inline !important;
    }
    .d-print-inline-block {
        display: inline-block !important;
    }
    .d-print-block {
        display: block !important;
    }
    .d-print-table {
        display: table !important;
    }
    .d-print-table-row {
        display: table-row !important;
    }
    .d-print-table-cell {
        display: table-cell !important;
    }
    .d-print-flex {
        display: -ms-flexbox !important;
        display: flex !important;
    }
    .d-print-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.embed-responsive::before {
    display: block;
    content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.embed-responsive-21by9::before {
    padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.embed-responsive-4by3::before {
    padding-top: 75%;
}

.embed-responsive-1by1::before {
    padding-top: 100%;
}

.flex-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
}

.flex-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
}

.flex-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
}

.flex-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
}

.flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
}

.flex-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
}

.flex-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
}

.flex-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
}

.flex-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
}

.flex-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
}

.flex-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
}

.flex-direction-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
}

.justify-content-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
}

.justify-content-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
}

.justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
}

.justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
}

.align-items-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
}

.align-items-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}

.align-items-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
}

.align-items-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
}

.align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
}

.align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
}

.align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
}

.align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
}

.align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
}

.align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
}

.align-self-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
}

.align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
}

.align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
}

.align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
}

.align-self-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
}

.align-self-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
}

@media (min-width: 576px) {
    .flex-sm-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }
    .flex-sm-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }
    .flex-sm-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }
    .flex-sm-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }
    .flex-sm-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }
    .flex-sm-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }
    .flex-sm-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }
    .flex-sm-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }
    .justify-content-sm-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }
    .justify-content-sm-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }
    .justify-content-sm-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }
    .justify-content-sm-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }
    .justify-content-sm-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }
    .align-items-sm-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }
    .align-items-sm-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }
    .align-items-sm-center {
        -ms-flex-align: center !important;
        align-items: center !important;
    }
    .align-items-sm-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }
    .align-items-sm-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }
    .align-content-sm-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }
    .align-content-sm-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }
    .align-content-sm-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }
    .align-content-sm-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }
    .align-content-sm-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }
    .align-content-sm-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }
    .align-self-sm-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }
    .align-self-sm-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }
    .align-self-sm-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }
    .align-self-sm-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }
    .align-self-sm-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }
    .align-self-sm-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }
}

@media (min-width: 768px) {
    .flex-md-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }
    .flex-md-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }
    .flex-md-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }
    .flex-md-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }
    .flex-md-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }
    .flex-md-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }
    .flex-md-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }
    .flex-md-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }
    .flex-md-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }
    .justify-content-md-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }
    .justify-content-md-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }
    .justify-content-md-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }
    .justify-content-md-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }
    .justify-content-md-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }
    .align-items-md-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }
    .align-items-md-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }
    .align-items-md-center {
        -ms-flex-align: center !important;
        align-items: center !important;
    }
    .align-items-md-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }
    .align-items-md-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }
    .align-content-md-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }
    .align-content-md-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }
    .align-content-md-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }
    .align-content-md-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }
    .align-content-md-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }
    .align-content-md-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }
    .align-self-md-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }
    .align-self-md-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }
    .align-self-md-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }
    .align-self-md-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }
    .align-self-md-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }
    .align-self-md-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }
}

@media (min-width: 992px) {
    .flex-lg-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }
    .flex-lg-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }
    .flex-lg-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }
    .flex-lg-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }
    .flex-lg-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }
    .flex-lg-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }
    .flex-lg-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }
    .flex-lg-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }
    .justify-content-lg-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }
    .justify-content-lg-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }
    .justify-content-lg-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }
    .justify-content-lg-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }
    .justify-content-lg-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }
    .align-items-lg-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }
    .align-items-lg-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }
    .align-items-lg-center {
        -ms-flex-align: center !important;
        align-items: center !important;
    }
    .align-items-lg-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }
    .align-items-lg-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }
    .align-content-lg-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }
    .align-content-lg-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }
    .align-content-lg-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }
    .align-content-lg-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }
    .align-content-lg-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }
    .align-content-lg-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }
    .align-self-lg-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }
    .align-self-lg-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }
    .align-self-lg-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }
    .align-self-lg-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }
    .align-self-lg-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }
    .align-self-lg-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }
}

@media (min-width: 1200px) {
    .flex-xl-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }
    .flex-xl-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }
    .flex-xl-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }
    .flex-xl-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }
    .flex-xl-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }
    .flex-xl-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }
    .flex-xl-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }
    .flex-xl-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }
    .justify-content-xl-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }
    .justify-content-xl-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }
    .justify-content-xl-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }
    .justify-content-xl-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }
    .justify-content-xl-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }
    .align-items-xl-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }
    .align-items-xl-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }
    .align-items-xl-center {
        -ms-flex-align: center !important;
        align-items: center !important;
    }
    .align-items-xl-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }
    .align-items-xl-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }
    .align-content-xl-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }
    .align-content-xl-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }
    .align-content-xl-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }
    .align-content-xl-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }
    .align-content-xl-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }
    .align-content-xl-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }
    .align-self-xl-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }
    .align-self-xl-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }
    .align-self-xl-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }
    .align-self-xl-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }
    .align-self-xl-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }
    .align-self-xl-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.float-none {
    float: none !important;
}

@media (min-width: 576px) {
    .float-sm-left {
        float: left !important;
    }
    .float-sm-right {
        float: right !important;
    }
    .float-sm-none {
        float: none !important;
    }
}

@media (min-width: 768px) {
    .float-md-left {
        float: left !important;
    }
    .float-md-right {
        float: right !important;
    }
    .float-md-none {
        float: none !important;
    }
}

@media (min-width: 992px) {
    .float-lg-left {
        float: left !important;
    }
    .float-lg-right {
        float: right !important;
    }
    .float-lg-none {
        float: none !important;
    }
}

@media (min-width: 1200px) {
    .float-xl-left {
        float: left !important;
    }
    .float-xl-right {
        float: right !important;
    }
    .float-xl-none {
        float: none !important;
    }
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.position-static {
    position: static !important;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
}

.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
    box-shadow: none !important;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.w-auto {
    width: auto !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.mw-100 {
    max-width: 100% !important;
}

.mh-100 {
    max-height: 100% !important;
}

.min-vw-100 {
    min-width: 100vw !important;
}

.min-vh-100 {
    min-height: 100vh !important;
}

.vw-100 {
    width: 100vw !important;
}

.vh-100 {
    height: 100vh !important;
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
}

.stretched-link-disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
}

.m-0 {
    margin: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2,
.my-2 {
    margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pr-0,
.px-0 {
    padding-right: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

.pr-3,
.px-3 {
    padding-right: 1rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem !important;
}

.pr-5,
.px-5 {
    padding-right: 3rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

.m-n1 {
    margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
    margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
    margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
    margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
    margin-left: -0.25rem !important;
}

.m-n2 {
    margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
    margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
    margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
    margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
    margin-left: -0.5rem !important;
}

.m-n3 {
    margin: -1rem !important;
}

.mt-n3,
.my-n3 {
    margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
    margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
    margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
    margin-left: -1rem !important;
}

.m-n4 {
    margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
    margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
    margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
    margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
    margin-left: -1.5rem !important;
}

.m-n5 {
    margin: -3rem !important;
}

.mt-n5,
.my-n5 {
    margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
    margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
    margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
    margin-left: -3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto,
.my-auto {
    margin-top: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}


/* padding, margin descubre */

.p-80 {
    padding: 4.7vw;
}

.p-60 {
    padding: 3.1vw;
}

.p-50 {
    padding: 2.6vw;
}

.p-30 {
    padding: 15px;
}

.mb-80 {
    margin-bottom: 80px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-60 {
    margin-top: 60px;
}

.mb-30 {
    margin-bottom: 30px!important;
}

.mb-50 {
    margin-bottom: 50px;
}

.m-center {
    margin: 0 auto;
}

.p-35 {
    padding: 35px;
}

@media (min-width: 576px) {
    .m-sm-0 {
        margin: 0 !important;
    }
    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important;
    }
    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important;
    }
    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important;
    }
    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important;
    }
    .m-sm-1 {
        margin: 0.25rem !important;
    }
    .mt-sm-1,
    .my-sm-1 {
        margin-top: 0.25rem !important;
    }
    .mr-sm-1,
    .mx-sm-1 {
        margin-right: 0.25rem !important;
    }
    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-sm-1,
    .mx-sm-1 {
        margin-left: 0.25rem !important;
    }
    .m-sm-2 {
        margin: 0.5rem !important;
    }
    .mt-sm-2,
    .my-sm-2 {
        margin-top: 0.5rem !important;
    }
    .mr-sm-2,
    .mx-sm-2 {
        margin-right: 0.5rem !important;
    }
    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-sm-2,
    .mx-sm-2 {
        margin-left: 0.5rem !important;
    }
    .m-sm-3 {
        margin: 1rem !important;
    }
    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem !important;
    }
    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important;
    }
    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem !important;
    }
    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important;
    }
    .m-sm-4 {
        margin: 1.5rem !important;
    }
    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem !important;
    }
    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important;
    }
    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important;
    }
    .m-sm-5 {
        margin: 3rem !important;
    }
    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem !important;
    }
    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem !important;
    }
    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem !important;
    }
    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem !important;
    }
    .p-sm-0 {
        padding: 0 !important;
    }
    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important;
    }
    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important;
    }
    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important;
    }
    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important;
    }
    .p-sm-1 {
        padding: 0.25rem !important;
    }
    .pt-sm-1,
    .py-sm-1 {
        padding-top: 0.25rem !important;
    }
    .pr-sm-1,
    .px-sm-1 {
        padding-right: 0.25rem !important;
    }
    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-sm-1,
    .px-sm-1 {
        padding-left: 0.25rem !important;
    }
    .p-sm-2 {
        padding: 0.5rem !important;
    }
    .pt-sm-2,
    .py-sm-2 {
        padding-top: 0.5rem !important;
    }
    .pr-sm-2,
    .px-sm-2 {
        padding-right: 0.5rem !important;
    }
    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-sm-2,
    .px-sm-2 {
        padding-left: 0.5rem !important;
    }
    .p-sm-3 {
        padding: 1rem !important;
    }
    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important;
    }
    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important;
    }
    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem !important;
    }
    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important;
    }
    .p-sm-4 {
        padding: 1.5rem !important;
    }
    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important;
    }
    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important;
    }
    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important;
    }
    .p-sm-5 {
        padding: 3rem !important;
    }
    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem !important;
    }
    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important;
    }
    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem !important;
    }
    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem !important;
    }
    .m-sm-n1 {
        margin: -0.25rem !important;
    }
    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -0.25rem !important;
    }
    .mr-sm-n1,
    .mx-sm-n1 {
        margin-right: -0.25rem !important;
    }
    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -0.25rem !important;
    }
    .ml-sm-n1,
    .mx-sm-n1 {
        margin-left: -0.25rem !important;
    }
    .m-sm-n2 {
        margin: -0.5rem !important;
    }
    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -0.5rem !important;
    }
    .mr-sm-n2,
    .mx-sm-n2 {
        margin-right: -0.5rem !important;
    }
    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -0.5rem !important;
    }
    .ml-sm-n2,
    .mx-sm-n2 {
        margin-left: -0.5rem !important;
    }
    .m-sm-n3 {
        margin: -1rem !important;
    }
    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -1rem !important;
    }
    .mr-sm-n3,
    .mx-sm-n3 {
        margin-right: -1rem !important;
    }
    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -1rem !important;
    }
    .ml-sm-n3,
    .mx-sm-n3 {
        margin-left: -1rem !important;
    }
    .m-sm-n4 {
        margin: -1.5rem !important;
    }
    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1.5rem !important;
    }
    .mr-sm-n4,
    .mx-sm-n4 {
        margin-right: -1.5rem !important;
    }
    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1.5rem !important;
    }
    .ml-sm-n4,
    .mx-sm-n4 {
        margin-left: -1.5rem !important;
    }
    .m-sm-n5 {
        margin: -3rem !important;
    }
    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -3rem !important;
    }
    .mr-sm-n5,
    .mx-sm-n5 {
        margin-right: -3rem !important;
    }
    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -3rem !important;
    }
    .ml-sm-n5,
    .mx-sm-n5 {
        margin-left: -3rem !important;
    }
    .m-sm-auto {
        margin: auto !important;
    }
    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important;
    }
    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important;
    }
    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important;
    }
    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    .m-md-0 {
        margin: 0 !important;
    }
    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important;
    }
    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important;
    }
    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important;
    }
    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important;
    }
    .m-md-1 {
        margin: 0.25rem !important;
    }
    .mt-md-1,
    .my-md-1 {
        margin-top: 0.25rem !important;
    }
    .mr-md-1,
    .mx-md-1 {
        margin-right: 0.25rem !important;
    }
    .mb-md-1,
    .my-md-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-md-1,
    .mx-md-1 {
        margin-left: 0.25rem !important;
    }
    .m-md-2 {
        margin: 0.5rem !important;
    }
    .mt-md-2,
    .my-md-2 {
        margin-top: 0.5rem !important;
    }
    .mr-md-2,
    .mx-md-2 {
        margin-right: 0.5rem !important;
    }
    .mb-md-2,
    .my-md-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-md-2,
    .mx-md-2 {
        margin-left: 0.5rem !important;
    }
    .m-md-3 {
        margin: 1rem !important;
    }
    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem !important;
    }
    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem !important;
    }
    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important;
    }
    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem !important;
    }
    .m-md-4 {
        margin: 1.5rem !important;
    }
    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem !important;
    }
    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important;
    }
    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important;
    }
    .m-md-5 {
        margin: 3rem !important;
    }
    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem !important;
    }
    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem !important;
    }
    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem !important;
    }
    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem !important;
    }
    .p-md-0 {
        padding: 0 !important;
    }
    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important;
    }
    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important;
    }
    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important;
    }
    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important;
    }
    .p-md-1 {
        padding: 0.25rem !important;
    }
    .pt-md-1,
    .py-md-1 {
        padding-top: 0.25rem !important;
    }
    .pr-md-1,
    .px-md-1 {
        padding-right: 0.25rem !important;
    }
    .pb-md-1,
    .py-md-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-md-1,
    .px-md-1 {
        padding-left: 0.25rem !important;
    }
    .p-md-2 {
        padding: 0.5rem !important;
    }
    .pt-md-2,
    .py-md-2 {
        padding-top: 0.5rem !important;
    }
    .pr-md-2,
    .px-md-2 {
        padding-right: 0.5rem !important;
    }
    .pb-md-2,
    .py-md-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-md-2,
    .px-md-2 {
        padding-left: 0.5rem !important;
    }
    .p-md-3 {
        padding: 1rem !important;
    }
    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important;
    }
    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem !important;
    }
    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem !important;
    }
    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem !important;
    }
    .p-md-4 {
        padding: 1.5rem !important;
    }
    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important;
    }
    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important;
    }
    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important;
    }
    .p-md-5 {
        padding: 3rem !important;
    }
    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem !important;
    }
    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem !important;
    }
    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem !important;
    }
    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem !important;
    }
    .m-md-n1 {
        margin: -0.25rem !important;
    }
    .mt-md-n1,
    .my-md-n1 {
        margin-top: -0.25rem !important;
    }
    .mr-md-n1,
    .mx-md-n1 {
        margin-right: -0.25rem !important;
    }
    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -0.25rem !important;
    }
    .ml-md-n1,
    .mx-md-n1 {
        margin-left: -0.25rem !important;
    }
    .m-md-n2 {
        margin: -0.5rem !important;
    }
    .mt-md-n2,
    .my-md-n2 {
        margin-top: -0.5rem !important;
    }
    .mr-md-n2,
    .mx-md-n2 {
        margin-right: -0.5rem !important;
    }
    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -0.5rem !important;
    }
    .ml-md-n2,
    .mx-md-n2 {
        margin-left: -0.5rem !important;
    }
    .m-md-n3 {
        margin: -1rem !important;
    }
    .mt-md-n3,
    .my-md-n3 {
        margin-top: -1rem !important;
    }
    .mr-md-n3,
    .mx-md-n3 {
        margin-right: -1rem !important;
    }
    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -1rem !important;
    }
    .ml-md-n3,
    .mx-md-n3 {
        margin-left: -1rem !important;
    }
    .m-md-n4 {
        margin: -1.5rem !important;
    }
    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1.5rem !important;
    }
    .mr-md-n4,
    .mx-md-n4 {
        margin-right: -1.5rem !important;
    }
    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1.5rem !important;
    }
    .ml-md-n4,
    .mx-md-n4 {
        margin-left: -1.5rem !important;
    }
    .m-md-n5 {
        margin: -3rem !important;
    }
    .mt-md-n5,
    .my-md-n5 {
        margin-top: -3rem !important;
    }
    .mr-md-n5,
    .mx-md-n5 {
        margin-right: -3rem !important;
    }
    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -3rem !important;
    }
    .ml-md-n5,
    .mx-md-n5 {
        margin-left: -3rem !important;
    }
    .m-md-auto {
        margin: auto !important;
    }
    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important;
    }
    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important;
    }
    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important;
    }
    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    .m-lg-0 {
        margin: 0 !important;
    }
    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important;
    }
    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important;
    }
    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important;
    }
    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important;
    }
    .m-lg-1 {
        margin: 0.25rem !important;
    }
    .mt-lg-1,
    .my-lg-1 {
        margin-top: 0.25rem !important;
    }
    .mr-lg-1,
    .mx-lg-1 {
        margin-right: 0.25rem !important;
    }
    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-lg-1,
    .mx-lg-1 {
        margin-left: 0.25rem !important;
    }
    .m-lg-2 {
        margin: 0.5rem !important;
    }
    .mt-lg-2,
    .my-lg-2 {
        margin-top: 0.5rem !important;
    }
    .mr-lg-2,
    .mx-lg-2 {
        margin-right: 0.5rem !important;
    }
    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-lg-2,
    .mx-lg-2 {
        margin-left: 0.5rem !important;
    }
    .m-lg-3 {
        margin: 1rem !important;
    }
    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem !important;
    }
    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important;
    }
    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem !important;
    }
    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important;
    }
    .m-lg-4 {
        margin: 1.5rem !important;
    }
    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem !important;
    }
    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important;
    }
    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important;
    }
    .m-lg-5 {
        margin: 3rem !important;
    }
    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem !important;
    }
    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem !important;
    }
    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem !important;
    }
    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important;
    }
    .p-lg-0 {
        padding: 0 !important;
    }
    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important;
    }
    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important;
    }
    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important;
    }
    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important;
    }
    .p-lg-1 {
        padding: 0.25rem !important;
    }
    .pt-lg-1,
    .py-lg-1 {
        padding-top: 0.25rem !important;
    }
    .pr-lg-1,
    .px-lg-1 {
        padding-right: 0.25rem !important;
    }
    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-lg-1,
    .px-lg-1 {
        padding-left: 0.25rem !important;
    }
    .p-lg-2 {
        padding: 0.5rem !important;
    }
    .pt-lg-2,
    .py-lg-2 {
        padding-top: 0.5rem !important;
    }
    .pr-lg-2,
    .px-lg-2 {
        padding-right: 0.5rem !important;
    }
    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-lg-2,
    .px-lg-2 {
        padding-left: 0.5rem !important;
    }
    .p-lg-3 {
        padding: 1rem !important;
    }
    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important;
    }
    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important;
    }
    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem !important;
    }
    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important;
    }
    .p-lg-4 {
        padding: 1.5rem !important;
    }
    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important;
    }
    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important;
    }
    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important;
    }
    .p-lg-5 {
        padding: 3rem !important;
    }
    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important;
    }
    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important;
    }
    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem !important;
    }
    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem !important;
    }
    .m-lg-n1 {
        margin: -0.25rem !important;
    }
    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -0.25rem !important;
    }
    .mr-lg-n1,
    .mx-lg-n1 {
        margin-right: -0.25rem !important;
    }
    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -0.25rem !important;
    }
    .ml-lg-n1,
    .mx-lg-n1 {
        margin-left: -0.25rem !important;
    }
    .m-lg-n2 {
        margin: -0.5rem !important;
    }
    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -0.5rem !important;
    }
    .mr-lg-n2,
    .mx-lg-n2 {
        margin-right: -0.5rem !important;
    }
    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -0.5rem !important;
    }
    .ml-lg-n2,
    .mx-lg-n2 {
        margin-left: -0.5rem !important;
    }
    .m-lg-n3 {
        margin: -1rem !important;
    }
    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -1rem !important;
    }
    .mr-lg-n3,
    .mx-lg-n3 {
        margin-right: -1rem !important;
    }
    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -1rem !important;
    }
    .ml-lg-n3,
    .mx-lg-n3 {
        margin-left: -1rem !important;
    }
    .m-lg-n4 {
        margin: -1.5rem !important;
    }
    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1.5rem !important;
    }
    .mr-lg-n4,
    .mx-lg-n4 {
        margin-right: -1.5rem !important;
    }
    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1.5rem !important;
    }
    .ml-lg-n4,
    .mx-lg-n4 {
        margin-left: -1.5rem !important;
    }
    .m-lg-n5 {
        margin: -3rem !important;
    }
    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -3rem !important;
    }
    .mr-lg-n5,
    .mx-lg-n5 {
        margin-right: -3rem !important;
    }
    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -3rem !important;
    }
    .ml-lg-n5,
    .mx-lg-n5 {
        margin-left: -3rem !important;
    }
    .m-lg-auto {
        margin: auto !important;
    }
    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important;
    }
    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important;
    }
    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important;
    }
    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    .m-xl-0 {
        margin: 0 !important;
    }
    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important;
    }
    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important;
    }
    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important;
    }
    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important;
    }
    .m-xl-1 {
        margin: 0.25rem !important;
    }
    .mt-xl-1,
    .my-xl-1 {
        margin-top: 0.25rem !important;
    }
    .mr-xl-1,
    .mx-xl-1 {
        margin-right: 0.25rem !important;
    }
    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-xl-1,
    .mx-xl-1 {
        margin-left: 0.25rem !important;
    }
    .m-xl-2 {
        margin: 0.5rem !important;
    }
    .mt-xl-2,
    .my-xl-2 {
        margin-top: 0.5rem !important;
    }
    .mr-xl-2,
    .mx-xl-2 {
        margin-right: 0.5rem !important;
    }
    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-xl-2,
    .mx-xl-2 {
        margin-left: 0.5rem !important;
    }
    .m-xl-3 {
        margin: 1rem !important;
    }
    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem !important;
    }
    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important;
    }
    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem !important;
    }
    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important;
    }
    .m-xl-4 {
        margin: 1.5rem !important;
    }
    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem !important;
    }
    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important;
    }
    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important;
    }
    .m-xl-5 {
        margin: 3rem !important;
    }
    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem !important;
    }
    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem !important;
    }
    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem !important;
    }
    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem !important;
    }
    .p-xl-0 {
        padding: 0 !important;
    }
    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important;
    }
    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important;
    }
    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important;
    }
    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important;
    }
    .p-xl-1 {
        padding: 0.25rem !important;
    }
    .pt-xl-1,
    .py-xl-1 {
        padding-top: 0.25rem !important;
    }
    .pr-xl-1,
    .px-xl-1 {
        padding-right: 0.25rem !important;
    }
    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-xl-1,
    .px-xl-1 {
        padding-left: 0.25rem !important;
    }
    .p-xl-2 {
        padding: 0.5rem !important;
    }
    .pt-xl-2,
    .py-xl-2 {
        padding-top: 0.5rem !important;
    }
    .pr-xl-2,
    .px-xl-2 {
        padding-right: 0.5rem !important;
    }
    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-xl-2,
    .px-xl-2 {
        padding-left: 0.5rem !important;
    }
    .p-xl-3 {
        padding: 1rem !important;
    }
    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem !important;
    }
    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important;
    }
    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem !important;
    }
    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important;
    }
    .p-xl-4 {
        padding: 1.5rem !important;
    }
    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem !important;
    }
    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important;
    }
    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important;
    }
    .p-xl-5 {
        padding: 3rem !important;
    }
    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem !important;
    }
    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem !important;
    }
    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem !important;
    }
    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem !important;
    }
    .m-xl-n1 {
        margin: -0.25rem !important;
    }
    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -0.25rem !important;
    }
    .mr-xl-n1,
    .mx-xl-n1 {
        margin-right: -0.25rem !important;
    }
    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -0.25rem !important;
    }
    .ml-xl-n1,
    .mx-xl-n1 {
        margin-left: -0.25rem !important;
    }
    .m-xl-n2 {
        margin: -0.5rem !important;
    }
    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -0.5rem !important;
    }
    .mr-xl-n2,
    .mx-xl-n2 {
        margin-right: -0.5rem !important;
    }
    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -0.5rem !important;
    }
    .ml-xl-n2,
    .mx-xl-n2 {
        margin-left: -0.5rem !important;
    }
    .m-xl-n3 {
        margin: -1rem !important;
    }
    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -1rem !important;
    }
    .mr-xl-n3,
    .mx-xl-n3 {
        margin-right: -1rem !important;
    }
    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -1rem !important;
    }
    .ml-xl-n3,
    .mx-xl-n3 {
        margin-left: -1rem !important;
    }
    .m-xl-n4 {
        margin: -1.5rem !important;
    }
    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1.5rem !important;
    }
    .mr-xl-n4,
    .mx-xl-n4 {
        margin-right: -1.5rem !important;
    }
    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1.5rem !important;
    }
    .ml-xl-n4,
    .mx-xl-n4 {
        margin-left: -1.5rem !important;
    }
    .m-xl-n5 {
        margin: -3rem !important;
    }
    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -3rem !important;
    }
    .mr-xl-n5,
    .mx-xl-n5 {
        margin-right: -3rem !important;
    }
    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -3rem !important;
    }
    .ml-xl-n5,
    .mx-xl-n5 {
        margin-left: -3rem !important;
    }
    .m-xl-auto {
        margin: auto !important;
    }
    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important;
    }
    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important;
    }
    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important;
    }
    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important;
    }
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
    text-align: justify !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

@media (min-width: 576px) {
    .text-sm-left {
        text-align: left !important;
    }
    .text-sm-right {
        text-align: right !important;
    }
    .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left !important;
    }
    .text-md-right {
        text-align: right !important;
    }
    .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align: left !important;
    }
    .text-lg-right {
        text-align: right !important;
    }
    .text-lg-center {
        text-align: center !important;
    }
}

@media (min-width: 1200px) {
    .text-xl-left {
        text-align: left !important;
    }
    .text-xl-right {
        text-align: right !important;
    }
    .text-xl-center {
        text-align: center !important;
    }
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

/* FONT (and other stuff) COLOR */
.color-gull-gray {
    color: #98abba !important;
}

.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-lighter {
    font-weight: lighter !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-bolder {
    font-weight: bolder !important;
}

.font-italic {
    font-style: italic !important;
}

.ft-12 {
    font-size: 12px !important;
}

.ft-14 {
    font-size: 14px !important;
}

.ft-16 {
    font-size: 16px !important;
}

.text-white {
    color: #fff !important;
}

.text-dark-blue {
    color: #0000E2 !important;
}

.text-primary {
    color: #007bff !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #0056b3 !important;
}

.text-secondary {
    color: #6c757d !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
    color: #494f54 !important;
}

.text-success {
    color: #28a745 !important;
}

a.text-success:hover,
a.text-success:focus {
    color: #19692c !important;
}

.text-info {
    color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus {
    color: #0f6674 !important;
}

.text-warning {
    color: #ffc107 !important;
}

a.text-warning:hover,
a.text-warning:focus {
    color: #ba8b00 !important;
}

.text-danger {
    color: #dc3545 !important;
}

a.text-danger:hover,
a.text-danger:focus {
    color: #a71d2a !important;
}

.text-light {
    color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
    color: #cbd3da !important;
}

.text-dark {
    color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus {
    color: #121416 !important;
}

.text-body {
    color: #212529 !important;
}

.text-muted {
    color: #6c757d !important;
}

.text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.text-decoration-none {
    text-decoration: none !important;
}

.text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important;
}

.text-reset {
    color: inherit !important;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden !important;
}

@media print {
    *,
    *::before,
    *::after {
        text-shadow: none !important;
        box-shadow: none !important;
    }
    a:not(.btn) {
        text-decoration: underline;
    }
    abbr[title]::after {
        content: " (" attr(title) ")";
    }
    pre {
        white-space: pre-wrap !important;
    }
    pre,
    blockquote {
        border: 1px solid #adb5bd;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    tr,
    img {
        page-break-inside: avoid;
    }
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3 {
        page-break-after: avoid;
    }
    @page {
        size: a3;
    }
    body {
        min-width: 992px !important;
    }
    .container {
        min-width: 992px !important;
    }
    .navbar {
        display: none;
    }
    .badge {
        border: 1px solid #000;
    }
    .table {
        border-collapse: collapse !important;
    }
    .table td,
    .table th {
        background-color: #fff !important;
    }
    .table-bordered th,
    .table-bordered td {
        border: 1px solid #dee2e6 !important;
    }
    .table-dark {
        color: inherit;
    }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody+tbody {
        border-color: #dee2e6;
    }
    .table .thead-dark th {
        color: inherit;
        border-color: #dee2e6;
    }
}

.applications {
    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    box-sizing: border-box;
    /* menu_shadows */
    box-shadow: 2px 0px 6px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    height: 112px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.applications.app-cob .img-left {
    right: 0;
    margin-right: 10px;
}

.applications.app-cob .img-right {
    left: 0;
    margin-left: 10px;
}

.applications.app-cob hr {
    border: 1px solid #394E59;
    height: 50%;
    margin-left: 10px;
    margin-right: 10px;
}

@media (max-width: 767px){
    .applications.app-cob img {
        width: 100px;
        margin-left: 5px !important;
        margin-right: 5px !important;
    }
    .applications.app-cob hr {
        margin-left: 5px !important;
        margin-right: 5px !important;
    }
}

.applications img {
    height: auto;
    max-width: -webkit-fill-available;
    margin: auto;
    max-height: -webkit-fill-available;
    width: 200px;
}

.bg-divCP {
    background: #020C41;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 394px;
}

.bg-divCPBrown {
    background: #DCCCC9;
}

.bg-divCPBrown article ul h2{
    color: #020C41;
}

.bg-divCPBrown article p {
    color: #020C41;
}

.bg-divCP h1 {
    color: #FFFFFF;
}

.imgCarrousel {
    display: none;
}

@media (min-width: 768px) {
    .imgCarrousel {
        display: block;
        height: 100%;
        max-height: 508px;
    }
    .textCarrousel {
        font-size: 48px;
        line-height: 65px;
        letter-spacing: 0.475px;
    }
    .bg-divCP {
        height: 500px;
    }
}

.textCarrousel {
    
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
}

.events-none {
    pointer-events: none;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-auto {
    cursor: auto;
}


/* Component Styles - Steps */

.steps {
    display: flex;
    width: 100%;
    margin: 0;
    list-style: none;
    padding-left: 0;
}

.step {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    position: relative;
    pointer-events: none;
}

.step-active,
.step-complete {
    cursor: pointer;
    pointer-events: all;
}

.step:not(:last-child)::before,
.step:not(:last-child)::after {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 0.1875rem;
    content: '';
    transform: translateY(-50%);
    will-change: width;
}

@media (max-width: 767.98px) {
    .step:not(:last-child)::before,
    .step:not(:last-child)::after {
        height: 0;
    }
}

.step::before {
    width: 100%;
    height: 0.1875rem;
    background-color: #7d7d7d;
}

.step::after {
    width: 0;
    background-color: #020C41;
}

.step-complete::after {
    width: 100% !important;
    opacity: 1;
    transition: width 0.6s ease-in-out, opacity 0.6s ease-in-out;
}

.step-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 3.125rem;
    height: 3.125rem;
    background-color: #fff;
    border: 0.1875rem solid;
    border-radius: 50%;
    color: #7d7d7d;
    font-size: 1.25rem;
}

.step-icon::before {
    display: block;
    color: #000;
}

.step-complete.step-active .step-icon {
    color: #fff;
    z-index: 1;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.step-incomplete.step-active .step-icon {
    border-color: #020C41;
    background-color: #020C41;
    color: #fff;
    transition-delay: 0.5s;
    z-index: 1;
}

.step-complete .step-icon {
    animation: bounce 0.5s ease-in-out;
    background-color: #020C41;
    border-color: #020C41;
    color: #fff;
    z-index: 1;
}

.step-label {
    position: absolute;
    bottom: -7.875rem;
    margin-top: 0.9375rem;
    font-size: 0.8rem;
    text-align: center;
    max-width: 365px;
    margin-left: 5px;
    margin-right: 5px;
}

.step-incomplete.step-inactive .step-label {
    color: #000;
}

.step-incomplete.step-active .step-label {
    color: #000;
}

.step-active .step-label {
    transition: color 0.3s ease-in-out;
    transition-delay: 0.5s;
}

.paragraph {
    
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #444444;
}

ul li {
    color: #020C41;
    font-size: 20px;
}

.bg-CP {
    background: #394E59;
}

.bg-AM {
    background: #182743;
}

.bg-DL {
    background-color: #184076;
}

.bg-AF {
    background: #CB2229;
}

.bg-KM {
    background: #10A4E2;
}

.labelRecommended {
    background: #FF8200;
    border-radius: 100px 0px 0px 100px;
    height: 30px;
    margin-bottom: 100px;
    position: absolute;
    right: 0%;
    top: 15px;
    text-align: right;
    vertical-align: center;
    padding-right: 15px;
    padding-left: 24px;
}

.recommended {
    
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    letter-spacing: 0.408333px;
    color: #FFFFFF!important;
    margin: 0;
}

.color-icon {
    width: 100px;
    height: 100px;
}

/* 
* * * Styles for Template Corporate  *** 
*
* Inclued styles for the pages created to corporate template
* @ids
*/

.container-corporativo .card-img-h img,
.container-corporativo .card-img-h svg{
 margin: 0 auto;
 display: flex;
}
.table thead th {
 border: none;
 padding: 15px;
 padding-right: 10px!important;
 padding-left: 10px!important;
}
.table th, .table td {
 padding-left: 20px!important;
 padding-right: 20px!important;
}
.container-corporativo .custom-select{
 background: transparent;
}
.container-corporativo .carousel{
 margin-bottom: 0px;
}
.bg-divCP .h1, 
.bg-divCP .h2,
.bg-divCP .h3,
.bg-divCP .h4,
.bg-divCP .h5,
.bg-divCP .h6,
.bg-divCP h1,
.bg-divCP h2,
.bg-divCP h3,
.bg-divCP h4,
.bg-divCP h5,
.bg-divCP h6
 {
  color: #fff; 
}

.bg-main .h1, 
.bg-main .h2,
.bg-main .h3,
.bg-main .h4,
.bg-main .h5,
.bg-main .h6,
.bg-main h1,
.bg-main h2,
.bg-main h3,
.bg-main h4,
.bg-main h5,
.bg-main h6
 {
  color: #fff; 
}
.bg-grey .h1, 
.bg-grey h1,
.bg-grey h1 a,
.bg-grey .h1 a{
  color: #496C82; 
}
.container-corporativo .h6,
.container-corporativo h6{
 font-weight: 500!important;
}
.section-corporativo{
 padding: 60px 0px;
}

@media (min-width: 768px) {
 .section-corp {
  padding:0px;
  margin: 0px 6vw;
 }
 .h-md-50 {
  max-height: 50%;
 }
 .position-md-absolute {
  position: absolute;
 }
}

@media (max-width: 768px) {
 .section-corp {
  margin: 0px 15px;
  padding: 0px;
 }
}


/*Container link*/


.container-corporativo a.btn-primary{
 display: inline-flex;
 align-items: center;
 justify-content: center;
 color: #fff!important;
 font-weight: normal;
}
.container-corporativo a{
 font-weight:inherit;
 color: inherit;
 font-size: inherit;
 line-height: inherit;
}
.container-corporativo a:hover {
    font-weight:inherit;
    text-decoration: underline;
}
.container-corporativo a.btn,
.container-corporativo a.nav-link{
 text-decoration: none;
}
.container-corporativo a.btn:hover,
.container-corporativo a.btn-primary:hover {
    text-decoration: none!important;
    font-weight: normal;
}
.container-corporativo a.btn-primary i{
    color: #fff;
}

@media (min-width: 768px){
    .container-corporativo a.btn,
    .container-corporativo .btn-primary.disabled, 
    .container-corporativo .btn-primary:disabled{
        width:auto;
        font-size: inherit!important;
        font-weight: normal!important;
    }
}

.container-corporativo a.btn-outline-primary{
    width: 100%;
    justify-content:center;
    align-items: center;
    display: flex;
    padding: 15px;
    font-weight:500!important;
    color: #FF8200!important;
}
.container-corporativo a.btn-outline-primary:hover{
    color: #fff!important;
    text-decoration:none!important;
}
@media (min-width: 992px){
    .container-corporativo a.btn-outline-primary{
        font-size :.95vw!important;
    }
}
.container-corporativo a.btn-outline-primary i{
    margin-left: auto;
    margin-right: 0px;
}
@media (max-width: 767px){
    .container-corporativo a.btn-size-override{
        width:auto!important;
    }
    .container-corporativo a.btn-primary{
        width:100%;
        max-width: 100%;
        min-height: 30px;
        height: auto;
        white-space: normal;
    }
}
.container-corporativo a i,
.container-corporativo a svg{
    margin-left: 15px;
    padding: 0px;
}

.container-corporativo .card-body .bg-blue strong,
.container-corporativo .card-body .bg-blue b
 {
    color: #fff;
}

 .card-body strong,
 .card-body b
 {
    color: #020C41;
    font-weight: bold;
}
.card-body strong,
 .card-body .table b
 {
    color: #020C41;
    font-weight: bold;
 }

.container-corporativo .card-body a {
    color: #020C41;
    text-decoration: none;
    font-weight: 500
}
@media (min-width: 992px){
    .container-corporativo .card-body a {
    font-size: 0.95vw;
    }
}

.container-corporativo .card-body a:hover{
 text-decoration: underline;
}

/* @media (min-width: 992px) { */
    .container-corporativo .card-body strong,
    .container-corporativo .card-body b {
        font-size: inherit;
        line-height: 1.30vw;
    }
/* } */


/*Container CARDS*/
.container-corporativo .cardDividers {
    border: none;
}

.container-corporativo .card-body {
    color:#444444!important;
}

@media (min-width: 992px) {
    .container-corporativo .card-body-h {
        padding-right: 1.9vw;
        padding-left: 1.9vw;
        color:#444444!important;
    }
}

/*Container listas*/

.container-corporativo ul {
    list-style: none;
    padding-left: 0px !important;
}
.container-corporativo li{
   color: #444444 ;
   display: table-row;
}
.container-corporativo .bg-blue li{
   color: #fff;
}
.container-corporativo ul li::before {
    border-radius: 15px;
    padding-right: 10px;
    color: #07E7CC;
    display: table-cell;
    content: ' \25CF';
    font-size: 14px;
}

@media (min-width: 992px) {
    .container-corporativo li {
        font-size: 0.93vw !important;
    }
}
@media (max-width: 992px) {
    .container-corporativo li {
        font-size: 14px !important;
    }
}

.container-corporativo ul li.nav-item::before,
.container-corporativo ul li.step-li::before{
    content: none;
}

/*NAV */
.container-corporativo .nav-tabs .nav-link:hover{
    text-decoration: none;
}


/**TABLAS*/
.container-corporativo table thead,
.container-corporativo table thead p{
    color: white!important;
    margin: 0px;
    font-weight: 500;
}

@media (min-width: 768px) {
    .table-fixed{
        table-layout: fixed;
    }
}

.container-corporativo table i{
    font-size: 16px; 
}

/*MARGIN-PADDING*/
.mt-15{
    margin-top: 15px;
}
.mb-15 {
    margin-bottom: 15px;
}

.mb-60{
    margin-bottom: 60px!important;
}
.top-50{
    top:50%!important;
}

/*BACKGROUND*/

.bg-main {
    background: radial-gradient(92.12% 90.78% at 87.2% 90.78%, #1D3148 35.18%, #3D698A 100%);
}

.bg-grey {
    background-color: #f8f8f8;
}

.bg-blue{
   background-color: #425462;
} 

.bg-westar{
   background-color: #E1DFD7;
}
.bg-white h5,
.bg-white .h5{
    color: #020C41;
}

/* .bg-white h4,
.bg-white .h4{
    color: #98ABBA;
} */

h4.h4-nav-link{
    color:#020C41;
}

.bg-main,
.bg-main a,
.bg-divCP,
.bg-blue {
   color: #fff ;
}

.bg-main .title-bullet{
    color: #fff;
}

.bg-grey .title-bullet,
.bg-white .title-bullet {
    color: #00295E;
}

.bg-grey h5,
.bg-grey .h5 {
    color: #020C41;
}
.bg-grey h3,
.bg-grey .h3,
.bg-white h3,
.bg-white .h3
{
 letter-spacing: 0.45px;
 color: #98ABBA;
 font-weight: 500;
}
.bg-grey h4,
.bg-grey .h4,
.bg-white h4,
.bg-white .h4{
 letter-spacing: 0.45px;
 color: #020C41;
 font-weight: 500;

}

.bg-white p a,
.bg-grey p a{
    color: #0000E2;
    font-weight: 600;
}

.bg-white,
.bg-white p,
.bg-grey p {
  color: #00295e;
}

.bg-grey svg {
    fill: #81A0B2;
}

.svg-blue{
   fill: #020C41; 
}

.container-corporativo img{
background: transparent;
}


/*Texto columnas*/
 .col-text:not(:last-child){
     margin-bottom: 30px;
 }
 @media (min-width:768px) {
 .section-corp:not(:last-child){
     margin-bottom: 60px;
 }
 
 }
 @media (max-width:768px) {
    .col-text{
       padding: 0px; 
    }
     .section-corp:not(:last-child){
     margin-bottom:30px;
    }
 }

/*Collapse*/
.card-collapsePrimary .card-header {
    color: #020C41;
}

/*----------------------------------------------------
Titulo con bullet
----------------------------------------------------*/
.title-bullet-cont ul {
    margin-bottom: 0px;
}
@media (max-width: 768px) {
    .title-bullet::before {
        display: none;
    }
    .title-bullet-cont ul li::before,
    .title-bullet-cont-fr ul li::before {
        color: #020C41;
        font-size: 14px!important;
    }
}

.title-bullet-cont-fr ul li::before {
    color: #020C41;
    font-size: 30px;
}

.title-bullet-cont-fr .title-bullet::before {
    display: none;
}

.title-bullet-cont-fr .title-bullet {
    text-align:left;
}

@media (min-width: 768px) {
    .title-bullet {
        text-align: center;
    }
    .title-bullet-cont {
        display: flex;
        justify-content:center;
    }
    .title-bullet::before {
      content: '';
      display: inline-block;
      width: 3vh;
      height: 3vh;
      -moz-border-radius: 15px;
      -webkit-border-radius: 15px;
      border-radius: 15px;
      margin-right: 15px;
      background-color: #0000E2;
    }
    .title-bullet::marker {
        content: '';
        display: none;
    }
    .title-bullet-cont ul li::before {
        display: none;
    }
}

@media (max-width: 992px) {
    .title-bullet {
        font-size: 20px;
        line-height: 28px;
    }
    .title-bullet::before {
        width: 10px;
        height: 10px;
    }
}
/*----------------------------------------------------
carouselDescubre
----------------------------------------------------*/
.carousel-descubre .carousel .carousel-indicators{
    bottom: 60px;
}

.carousel-descubre .carousel-item .row[class*="col-"] {
    min-height: 60vh;
}

@media (min-width: 768px) {
    .container-corporativo h1,
    .container-corporativo .h1 {
        font-size: 2.5vw!important;
        line-height: 3.39vw!important;
        letter-spacing: 0.45px;
    }
}

.container-corporativo .h1 {
    font-weight: 300;
    letter-spacing: 0.45px;
    margin-bottom: 2.125vw;
    margin-top: 15px;
}

@media (max-width: 768px) {
    .container-corporativo .h1 {
        margin-bottom: 50px;
    }
}


@media (min-width: 768px) {
    .carousel-descubre .carousel-item .bg-divCP {
        min-height: 26.82vw;
        height: auto;
        align-items: flex-start;
        justify-content: flex-start;
        display: flex;
    }
    .carousel-descubre .carousel-item article {
        margin: 60px 20px 60px 6vw;
    }
}

@media (max-width: 767px) {
    .carousel-descubre .carousel-item article {
        margin: 0px 15px;
    }
}

.carousel-descubre .carousel-item .col-img {
    background-repeat: no-repeat;
    background-size: cover;
}

/*----------------------------------------------------
            Aerolineas
----------------------------------------------------*/

.grid-descubre .bg-divCP {
    display: block;
    height: auto;
    min-height: 24.8vw;
    padding: 3.1vw;
}

@media (max-width: 767px) {
    .grid-descubre .bg-divCP {
        padding: 30px;
    }
}
@media (min-width: 768px) {
    .grid-descubre .bg-divCP {
        padding: 60px;
    }
}

.grid-descubre .title-bullet {
    margin-bottom: 15px;
    margin-top: 0px;
}

.grid-descubre .grid-images {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 13.23vw;
    height: 100%;
    padding: 3.1vw;
}

@media (max-width: 768px) {
    .grid-descubre .grid-images {
        min-height: 121px;
        height: 121px;
    }
}

.grid-descubre article img {
    width: 13.23vw;
    height: auto;
    margin-top: 30px;
    top: 100%;
    position: sticky;
}

@media (max-width: 992px) {
    .grid-descubre article img {
        width: 152px;
        height: auto;
    }
}

.grid-images .imagen-tumb {
    width: 32vw;
}


.grid-images .img-fluid {
    max-width: 215px;
    max-height: 40px;
    height: auto;
    padding: 0px 15px;
}

.grid-images .image_land {
    opacity: 0.50 !important;
    max-width: 50%;
    max-height: 50px;
    width: auto;
}


/*HOVER DE AEROLINEAS*/

.img__description {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url("https://corpstylo.aeromexicorewards.com/logos/AeroMexico.png");
    background-repeat: no-repeat;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    height: 100%;
    transition: opacity .2s, visibility .2s;
}

.imagen-tumb {
    width: 35vw;
    height: 16vw;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
}

@media only screen and (min-width: 992px) {
    .grid-images:hover .imagen-tumb {
        visibility: visible;
        z-index: 4;
    }
}

.image_land {
    width: 50%;
    opacity: 0.85 !important;
}

.textLand {
    /* position: absolute; */
    width: 100%;
    height: 100%;
    margin-top: 0px !important;
    padding: 3.12vw!important;
    color: #FFFFFF;
}

.textLand p {
    margin-top: 0px !important;
    margin-bottom: 1.56vw !important;
}

.textLand a {
    color: #FFFFFF;
    position: sticky;
    top: 100%;
    display:flex;
    justify-content:flex-end;
}

@media (min-width: 992px) {
   .textLand a {
    font-size: 0.95vw;
    }
}


/*----------------------------------------------------
FORM REGISTRATION
----------------------------------------------------*/

.section-form.bg-divCP {
    height: auto!important;
}

.form-registration i.fas {
    font-size: 16px;
}

.form-registration.bg-divCP {
    height: auto!important;
}

.form-registration .title-bullet {
    margin-bottom: 30px;
    margin-top: 0;
}

.form-registration .card {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    height: calc(100% + 17px);
}

.form-registration .card .card-title {
    color: #020C41;
    font-style: normal;
    font-weight: bold;
    padding-top: 0;
}

.form-registration .card .card-body {
    display: grid;
    padding: 30px 40px;
}

.form-registration .card .card-text {
    color: #000000;
    margin-top: 15px;
    margin-right: 0px;
}

.form-registration form label {
    color: #020C41;
}


@media (max-width: 768px) {
    .form-registration form .btn {
        width: 100%;
    }
}

input.form-control {
    padding-left: 0;
    
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: justify;
    color: #020C41;
}


/*----------------------------------------------------
PARSLEY FORMS VALIDATION
----------------------------------------------------*/

input.parsley-success,
select.parsley-success,
textarea.parsley-success,
.input-group>.parsley-success:focus {
    border-bottom: 1px solid #42A94B !important;
}

.valid {
    display: none;
}

.valid i.fas {
    color: #42A94B;
}

.parsley-success~.input-group-append .valid {
    display: block;
}

input.parsley-error,
select.parsley-error,
textarea.parsley-error,
.input-group>.parsley-error:focus {
    border-bottom: 1px solid #CA0F0F !important;
}

.invalid {
    display: none;
}

.invalid i.fas {
    color: #CA0F0F;
}

.parsley-error~.input-group-append .invalid {
    display: block;
}

.parsley-errors-list {
    padding: 0;
}
.parsley-errors-list li:before{
    content: none!important;
}

.parsley-errors-list li {
    margin: 6px 0 3px;
    padding: 0;
    list-style-type: none;
    
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    text-align: justify;
    color: #CA0F0F;
    transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    -moz-transition: all .3s ease-in;
    -webkit-transition: all .3s ease-in;
}

.parsley-errors-list.filled {
    margin-top: 5px;
    opacity: 1;
}

form:valid {
    color: green;
}


/*----------------------------------------------------
columnas descubre
----------------------------------------------------*/
.columns-descubre .mt-60:first-child{
    margin-top: 0px;
}

.svg-auto{
 height: auto;
 width: auto;
}

.svg-big{
 height: 100px;
 width: 100px;
}

.svg-small{
 height: 60px;
 width: 60px;
}

.columns-descubre h3{
    color: #444;
}

.columns-descubre .col-md-12 {
    padding: 0px;
}

.card-row{
    padding: 0px;
    margin: 0px 0 0 0;
}

.card-row:not(:first-child){
   margin-top: 60px;
}

@media (min-width: 768px) and (max-width: 991px){
    .card-column{
        margin-top:60px;
    }
    .card-row:not(:first-child){
     margin-top: 0px;
    }
}
@media (max-width: 767px) {
    .card-column:not(:first-child){
        margin-top:60px;
    }
}

@media (min-width: 992px) {
    .card-column{
        margin-top:0px;
    }
    .card-column:not(:first-child){
        padding-left: .78vw;
    }
    .card-column:not(:last-child){
        padding-right: .78vw;
    }
}

.card-column img {
    border: none;
    display: flex;
    margin: 0 auto;
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
}

@media (min-width: 768px) {
    .img-end {
        display: flex;
        align-items: flex-end
    }
}

.card-column svg{
    width: 100%;
    margin:0 auto;
    display: flex;  
}

.card-column .card-img-v{
    height: 240px;
    padding-top: 0px!important;
    background-color: transparent;
    display: grid;
    align-items: flex-end;
}

.card-column .card-img-v,
.card-column .card-title,
.card-column .card-body {
    padding-left: 0px;
    padding-right: 0px;
}

/*----------------------------------------------------
seccion video
----------------------------------------------------*/

.section-video .title-bullet {
    margin-bottom: 30px;
}

.section-video iframe {
    height: 26.615vw;
    width: 44.38vw;
}

@media (max-width: 768px) {
    .section-video iframe {
        height: 51.615vw;
        width: 100%;
    }
}


/*----------------------------------------------------
CAROUSEL MULTI-ITEMS
----------------------------------------------------*/
.carousel-multiItems .carousel-inner {
    display:flex;
}
.carousel-multiItems .carousel-inner .carousel-item{
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out!important;
    /* background-color: transparent; */
}
 .carousel-multiItems .carousel-inner .carousel-item .card-item{
  height: 100%;
}
/* medium - display 2  */

@media (min-width: 768px) {
    .carousel-multiItems .carousel-inner .carousel-item-right.active,
    .carousel-multiItems .carousel-inner .carousel-item-next {
        transform: translateX(50%);
    }
    .carousel-multiItems .carousel-inner .carousel-item-left.active,
    .carousel-multiItems .carousel-inner .carousel-item-prev {
        transform: translateX(-50%);
    }
    .carousel-multiItems .carousel-inner .carousel-item-right,
    .carousel-multiItems .carousel-inner .carousel-item-left {
        transform: translateX(0);
    }
}


/* large - display 4 */

@media (min-width: 992px) {
    .carousel-multiItems .carousel-inner .carousel-item-right.active,
    .carousel-multiItems .carousel-inner .carousel-item-next {
        transform: translateX(25%);
    }
    .carousel-multiItems .carousel-inner .carousel-item-left.active,
    .carousel-multiItems .carousel-inner .carousel-item-prev {
        transform: translateX(-25%);
    }
    .carousel-multiItems .carousel-inner .carousel-item-right,
    .carousel-multiItems .carousel-inner .carousel-item-left {
        transform: translateX(0);
    }
    .container-corporativo .carousel-multiItems .card-item {
        padding: 0 7.5px;
    }
    .carousel-multiItems .carousel-control-prev{
     left: -7.5px;
    }
    .carousel-multiItems .carousel-control-next{
        right: -7.5px;
    }
}


/*display 1*/
@media (max-width: 767px) {
    .carousel-multiItems .carousel-inner .carousel-item>div {
        display: none;
    }
    .carousel-multiItems .carousel-inner .carousel-item>div:first-child {
        display: block;
    }
}
.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
    transform: unset!important; } 

.carousel-multiItems .carousel-inner .carousel-item.active,
.carousel-multiItems .carousel-inner .carousel-item-next,
.carousel-multiItems .carousel-inner .carousel-item-prev {
    display: flex;
    align-items: flex-start;
}

.carousel-multiItems .carousel-control-prev,
.carousel-multiItems .carousel-control-next {
    height: 40px;
    padding: 10px 7px;
    border-radius: 5px;
    background: #020C41;
    mix-blend-mode: normal;
    opacity: 0.7;
    top: 35%;
    width: auto;
}

.columns-descubre .carousel-multiItems .carousel-inner {
    margin: 0 auto;
    width: 80%;
}

.container-corporativo .carousel-multiItems .card-img-b .image-complete {
    height: 100%;
}


/*----------------------------------------------------
CARDS HORIZONTAL
----------------------------------------------------*/


@media (min-width: 768px) {
    .cards-horizontal .card-body-h {
        margin-bottom: 15px;
    }
}

@media (max-width: 767px) {
    .cards-horizontal .card-body-h {
        margin-bottom: 30px;
    }
    .container-corporativo .cardDividers {
        margin-bottom: 30px;
    }
    .cards-horizontal .card-body-h p {
        margin-bottom: 0px;
    }
     .columns-cards,
    .columns-cards-h {
         padding-top: 15px!important;
    }

}
.columns-card:not(:first-child){
    margin-top:30px;
}
@media (min-width: 992px) {
    .columns-cards,
    .columns-cards-h {
        padding-right: .39vw;
        padding-left: .39vw;
    }

    .columns-cards,
    .columns-cards-h{
        margin-bottom: .78vw;
    }
    .cards-horizontal .img-card-h {
        height: auto;
        align-self: center;
    }
}

@media (max-width: 992px) {
    .columns-cards,
    .columns-cards-h {
        padding-right: .39vw;
        padding-left: .39vw;
        padding-top: .78vw;
    }

}
 
@media (max-width: 768px) {
    .p-col-0 {
        padding: 0px;
    }
}

.cards-horizontal .card-footer {
    display: flex;
    position: sticky;
    top: 100%;
    justify-content: flex-end;
    height: auto;

}

.cards-horizontal .title-bullet {
    margin-bottom: 30px;
}

@media (min-width: 768px){
    .card-img-h{
        padding-left: 30px !important;
        display: flex;
        padding-top: 6% !important;
    }
  
}
@media (max-width: 767px) {
    .card-img-h{
        justify-content: center;
        padding-bottom:0px;
        display:flex;
        padding-left: 0px!important;
    }
    
    .card-saber-mas .card-img-v{
        padding-left : 18px !important;
        padding-right : 18px !important;
        max-width: none;
}
}

/*----------------------------------------------------
SECTION IMAGE CARDS
----------------------------------------------------*/
.sectionw-image{
    height: calc(100% + 15px);
    display: grid;
    padding:0px!important;
}


.row.sectionw-image-tarjetas{
    height: calc(100% + 15px);
}

.sectionw-image-tarjetas .col-img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}



@media (min-width: 768px) {
    .sectionw-image-tarjetas .bg-divCP {
        min-height: 26.82vw;
        height: auto;
        align-items: flex-start;
        justify-content: flex-end;
        display: flex;
    }
    .carousel-descubre .carousel-item article,
    .sectionw-image-tarjetas article {
        margin: 60px 20px 60px 6.0vw;
    }
}

@media (max-width: 767px) {
    .carousel-descubre .carousel-item article,
    .sectionw-image-tarjetas article {
        margin: 0px 15px;
    }
}


/*----------------------------------------------------
CARD WITH TABLE
----------------------------------------------------*/

.container-corporativo .card-title h4 {
    color: #020C41;
    margin: 0;
}

.cardw-image .h3,
.cardw-image h3 {
    letter-spacing: 0.45px;
    color: #98ABBA;
}


.container-corporativo .support-text{
     color:#A2A2A2!important;
     margin: 0px!important;
     font-weight: normal!important;
}
@media (min-width: 992px) {
    .container-corporativo .support-text  {
        font-size: 0.93vw;
        line-height: 1.30vw;
    }
}

@media (max-width: 992px) {
    .container-corporativo .support-text {
        font-size: 14px;
        line-height: 16px;
    }
}

.container-corporativo table td{
  vertical-align: middle;  
}
.container-corporativo table td i{
    color: #020C41;
}

 .container-corporativo table td p,
 .container-corporativo table td h6{
    margin: 0px;
}

 .container-corporativo table tbody {
    border: none;
}

.container-corporativo .card-footer{
    height: auto;
    display: flex; 
}

.container-corporativo .card-footer a,
.container-corporativo .card-footer-saber a{ 
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  color: #020C41;
}
.container-corporativo .card-footer a:hover,
.container-corporativo .card-footer-saber a:hover{
   text-decoration: underline;
}
@media (min-width: 992px) {
    .container-corporativo .card-footer a{ 
     font-size: 0.95vw;
     line-height: 0.99vw;
    }
}
/* .container-corporativo .card-saber-mas table{
    width: 100%;
} */

@media (max-width: 768px) {
    .container-corporativo .card-saber-mas .card-body,
    .container-corporativo .card-saber-mas .card-footer-saber,
    .container-corporativo .card-saber-mas .card-title {
        padding-right: 15px!important;
        padding-left: 15px!important;
    }
     .container-corporativo table td {
        /* padding: 10px!important; */
        border: none;
        height: auto;
    }
}

@media (min-width: 768px) {
    .container-corporativo table td {
        padding: 15px;
        padding-right: 10px!important;
        padding-left: 10px!important;
        border: none;
        height: auto;
    }
}


.cardw-image .card-img-v .card-title {
    color: #444444;
}

@media (max-width: 768px) {
    .cardw-image .columns-cards {
        padding: 0px;
    }
}
.columns-cards table tbody{
    border:none!important;
}

.columns-cards table{
width: 100%;
}
/*----------------------------------------------------
PROGRESS
----------------------------------------------------*/

.container-loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('../images/loader.gif') 50% 50% no-repeat rgb(255, 255, 255 );
    visibility: hidden;
}

ul.steps.steps-web.d-lg-flex.d-none.mt-0::before {
    display: block;
    position: absolute;
    top: 25px;
    left: calc((100% / var(--data-x)) / 2);
    height: 0.1875rem;
    content: '';
    transform: translateY(-50%);
    will-change: width;
    width: calc(100% - (100% / var(--data-x)));
    background-color:#fff;
}
.bg-white ul.steps.steps-web.d-lg-flex.d-none.mt-0::before {
    background-color: #6C93AC;
}
.bg-grey ul.steps.steps-web.d-lg-flex.d-none.mt-0::before {
    background-color: #6C93AC;
}

.progress_card .card-basic .card-title h4,
.progress_card .card-basic {
    color: #444444;
}

.bg-main h4 {
    font-weight: normal;
}

.bg-main .progress_card .step-label {
    color: #fff;
}

.bg-main .progress_card .step-icon {
    border-color: #fff;
    background-color: #fff;
    color: #020C41;
}
/*Para estilos de step cuando es svg ##DGS##*/
.bg-main .progress_card .step-icon-svg{
    background : none;
    border : none;
}
.bg-white .progress_card .step-icon,
.bg-grey .progress_card .step-icon {
    background-color: #6C93AC;
    border-color : #6C93AC;
    color: #FFFFFF ;
}
/*Para estilos de step cuando es svg ##DGS##*/
.bg-white .progress_card .step-icon-svg,
.bg-grey .progress_card .step-icon-svg {
    background : none;
    border : none;
}
.bg-white .progress_card .step-icon i.fas,
.bg-grey .progress_card .step-icon i.fas{
    color: #FFFFFF ;
    padding-left : 0px;
}

.progress_card .step::before,
.progress_card .step::after {
 content: none;
}

/* .bg-main h4 a {
    text-decoration: underline;
    color: #fff;
} */

/* @media (max-width: 992px) {
    .bg-main h4 a {
        font-size: 14px;
        line-height: 16px;
    }
}

@media (min-width: 992px) {
    .bg-main h4 a {
        font-size: 1.25vw;
        line-height: 1.82vw;
    }
} */

.progress_card .step-label {
    position: initial;
    margin-right: 0px;
}

@media (max-width: 992px) {
    .progress_card li.step {
    justify-content: center;
    display: flex;
    }
}

.progress_card .step-li {
    padding: 0px 7.5px;
}

/*----------------------------------------------------
SECTION FILTER
----------------------------------------------------*/
.section-filter .btn{
    min-width: unset!important;
}

@media (max-width: 768px) {
    .section-filter .nav {
        flex-wrap: nowrap;
        padding: 10px 0px;
        justify-content: flex-start;
    }
}
@media (min-width: 769px) {
    .section-filter .nav {
        padding: 0px;
        justify-content: center;
    }
    .section-filter .nav .nav-item {
        padding: 10px 0px;
    }
}
.section-filter .nav-link {
    display: block;
    padding: 0px 1rem;

}

@media (min-width: 768px) {
.container-corporativo .card-with-img{
    height: 100%;
}
}


.container-corporativo .card-img-b img {
    width: 100%;
    max-height: 100%;
}

.section-filter .btn-outline-primary i,
.section-filter .btn-outline-primary svg {
    margin-left: .78vw;
    fill: #030D75;
}

.section-filter .btn-outline-primary:not(:disabled):not(.disabled).active {
    border: 1px solid #030D75;
}

.section-filter .btn-outline-primary:hover svg,
.section-filter .btn-outline-primary.active svg {
    fill: white;
}

.section-filter .card-with-img{
    min-height: 31.4vw;
}
@media (min-width: 992px) {
    .container-corporativo .card-saber-mas .card-img-b {
        max-height: none;
        min-height: auto;
    }
    .container-corporativo .card-img-b {
        max-height: 10.928vw;
        min-height: 10.928vw;
        display: inline-grid;
    }
    .container-corporativo .card-with-img .card-body {
        padding-bottom: 1.56vw;
        padding-right: 1.56vw;
        padding-left: 1.56vw;
    }
    .container-corporativo .card-with-img .card-footer {
        padding-bottom: 2vw!important;
        padding-right: 1.56vw!important;
        padding-left: 1.56vw!important;
    }
    .container-corporativo .card-with-img .card-title {
        padding-top: 1.56vw;
        padding-right: 1.56vw;
        padding-left: 1.56vw;
    }

    .section-filter .nav .nav-item:not(:last-child){
        margin-right: .78vw;
    }

}

@media (max-width: 992px) {
    .section-filter .nav .nav-item {
        margin-right: 15px;
    }
    .section-filter .nav .nav-item:first-child {
        margin-left: 15px;
    }
    .section-filter .btn-outline-primary {
        padding: 0.7rem 1.7rem;
    }
    .container-corporativo .card-saber-mas .card-img-b {
        max-height: none;
        min-height: auto;
    }
    .container-corporativo .card-img-b {
        max-height: 176px;
        min-height: 176px;
        display: inline-grid;
    }
}

@media (max-width: 768px) {
    .section-filter .tab-pane .row .col-lg-3 {
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 15px;
    }

}

@media (min-width: 768px) {
    .section-filter .tab-pane .row .col-lg-3:not(:nth-child(4n)) {
        padding-right: .39vw;
    }
    .section-filter .tab-pane .row .col-lg-3:not(:nth-child(4n+1)) {
        padding-left: .39vw;
    }
    .section-filter .tab-pane .row .col-lg-3 {
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: .78vw;
    }
}

.section-filter .nav-tabs-wrapper {
    max-width: none;
}

.section-filter .nav-tabs-wrapper::-webkit-scrollbar {
    display: none;
}

.container-corporativo .card-img-b[data-type='svg'] {
    padding: 15px 0 0 0;
    height: 100%;
    display: flex;
    justify-content: center;
}

.container-corporativo .card-img-b[data-type='svg'] svg{
    width: auto;
    height:auto;
    max-width:100%;
}

@media (max-width: 992px) {
    .section-filter .nav-tabs-wrapper {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media (min-width: 992px) {
    .section-filter .nav-tabs-wrapper {
        display: flex;
        justify-content: center;
    }

}

.section-filter .nav .btn-outline-primary svg{
    height: 100%;
    width:18px;
}

.container-corporativo .nav .nav-link svg{
    height: 20px;
    width: 17px;
    margin:0 15px;
}

.section-filter .nav .btn-outline-primary svg.vuelos {
    padding-top: 5%;
}

.container-corporativo .card-img-b .image-center {
    width: auto;
    padding: 30px 30px 0px 30px;
    max-width: 100%;
    margin: 0 auto;
    align-self: center;
    align-content: center;
    display: block;
    max-height: inherit;
   
}
@media (max-width: 992px) {
    .container-corporativo .card-img-b .image-center {
    height: auto;
    }
}
/*----------------------------------------------------
PANTALLA COMPRA PUNTOS
----------------------------------------------------*/

.tabs-table .border-tab-content {
    max-width: 100% !important;
}

@media (min-width: 992px) {
    .tabs-table .tab-content table{
        width: 100%;
    }
}

.tabs-table .nav-item:first-child{
    padding-left : 0px !important;    
}
.tabs-table .nav-item:last-child{
    padding-right : 0px !important;
}
.tabs-table .nav-item
{
    padding-right : 7.5px ;
    padding-left: 7.5px;
}

@media (min-width: 767px) {
.tabs-table .nav-tabs-wrapper{
    max-width: none;
}
.tabs-table .nav-tabs-wrapper .nav {
    width: 100%;

}
}
@media (max-width: 767px) {
.tabs-table .col-md{
    position: inherit;
    width: auto;
    padding:0px;
}
.tabs-table .nav-tabs .nav-link.active{
    border:none;
}

}

.tabs-table .nav-tabs .nav-link .h4-nav-link{
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 55px;
}
@media (min-width: 992px) {
    .tabs-table .nav-tabs .nav-link .h4-nav-link{
        min-height: 4vw;
    }
}


/*----------------------------------------------------
PANTALLA PARA CARROUSEL EN MOBILE
----------------------------------------------------*/
@media (max-width: 767px) {
.buttonsGroup{
    flex-wrap: nowrap;
    overflow-y: auto;
}
}
.buttonsGroup{
    margin : 0 auto 40px auto;
}
.buttonsGroupCardVertical{
    margin : 0 auto 40px 15px !important;
}
.top-arrowCarrousel{
    top : 17.625rem !important;
}
.buttonsGroup::-webkit-scrollbar{
    display :none;
}
.buttonsGroup .nav{
    padding: 10px 0;
}
.buttonsGroup .nav:not(:last-child){
    margin-right: 15px;
}


/*----------------------------------------------------
PANTALLA PARA CARROUSEL CARDS HORIZONTALES
----------------------------------------------------*/

@media (max-width: 767px) {
.top-arrowCarrouselHorizontal{
    top : 14.625rem !important;
}
}
@media (min-width: 768px) {
.top-arrowCarrouselHorizontal{
    top : 40% !important;
}
.carousel-horizontal .card-img-h{
    min-height: 18.9vw;
    padding: 15px!important;
}
}
.carousel-horizontal .carousel-control-prev,
.carousel-horizontal .carousel-control-next {
    height: max-content;
    padding: 10px 7px;
    border-radius: 5px;
    background: #020C41;
    mix-blend-mode: normal;
    opacity: 0.7;
    top: 35%;
    width: auto;
}
/* .carousel-horizontal .carousel-control-prev {
    left: 10px;
} */

/* .carousel-horizontal .carousel-control-next {
    right: 10px;
} */

/*----------------------------------------------------
COLLAPSE
----------------------------------------------------*/
.content_collapse .card-collapsePrimary .card-body{
    padding: 30px;
}

.content_collapse .card-header p {
    margin: 0;
    color: #020C41;
    font-weight: 500;
}

.content_collapse .card-header i {
    color: #030D75;
}

.content_collapse h4 {
    padding-left: 30px;
}

.content_collapse .card-body {
    padding: 0 30px 30px 30px;
}

.content_collapse .card-body p {
    margin: 0;
    color: #000000;
}
/*----------------------------------------------------
IMAGENES COLUMNAS
----------------------------------------------------*/

.columns-image .row .col-images{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0px;
}
/*----------------------------------------------------
ERROR PAGE
----------------------------------------------------*/
/* .error-page{
 padding: 60px 0;
} */
@media (min-width: 768px) {
.error-page svg{
    margin-left: auto;
    display: flex;
    width : 32vw;
    height : 23vw;
}
}
@media (max-width: 767px) {
    .error-page svg{
        display: flex;
        margin : auto;
    }
}
/*----------------------------------------------------
RELEASES
----------------------------------------------------*/
/* .releases{
    padding: 60px 0;
} */
.releases .card-collapsePrimary p{
    margin: 0px;
}
.releases .card-basic h1,
.releases .card-basic h2,
.releases .card-basic h3,
.releases .card-basic h4,
.releases .card-basic h5,
.releases .card-basic h6{
    color: #496C82;
}
/*----------------------------------------------------
ANIMACIONES
----------------------------------------------------*/

.animated {
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    animation-duration: 2s; 
    animation-delay: 0s;
}

@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

@-webkit-keyframes fadeInDownBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInDownBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInDownBig {
    -webkit-animation-name: fadeInDownBig;
    animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUpBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInUpBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInUpBig {
    -webkit-animation-name: fadeInUpBig;
    animation-name: fadeInUpBig;
}

@-webkit-keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slideInLeft {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
    
}

/* 
* * * Styles for Aeroméxico Rewards Corporate *** 
*
* This file inclued styles for: 
* - Header
* - Nav
* - Footer
*
* @vincoorbis.com
*/

.header-wrapper {
  background-color: #fff;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.25);
}
.header-corporate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.9375rem;
}
@media (min-width: 768px) {
  .header-corporate {
    padding-left: 0;
    padding-right: 0;
 }
}
@media (min-width: 576px) and (max-width: 768px - 0.02px) {
  .header-corporate {
    max-width: unset;
 }
}
.header-corporate .header-group {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  height: 3.125rem;
}
.header-corporate .logo {
  width: calc(100% - 5.25rem - 1.875rem);
  flex-wrap: wrap;
}
.header-corporate .logo img{
  width: 100%;
}
.header-corporate .logo .cp-corporate-logo {
  width: 100%;
  justify-content: center;
  display: flex;
  margin-bottom: 4px;
}
.header-corporate .logo .header-partners {
  max-width: 100%;
  flex-wrap: nowrap;
  display: flex;
  width: 100%;
}
.header-corporate .logo .header-partners a {
  vertical-align: bottom;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.header-corporate .logo .header-partners a img {
  padding-bottom: 0.3125rem;
  border-bottom: 0.1875rem solid transparent;
	vertical-align: bottom;
	width: 100%;
}
.header-corporate .logo .header-partners a img:hover {
  border-bottom-color: #ff8200;
}
.header-corporate .logo .header-partners a:last-child img {
  padding-bottom: 0.125rem;
}
@media (min-width: 768px) {
  .header-corporate .logo {
    width: 16.9375rem;
 }
}
@media (min-width: 576px) and (max-width: 768px - 0.02px) {
  .header-corporate .logo {
    width: 21.5625rem;
 }
}
.header-corporate .logo.logo-am {
    margin-right: 13px;
}
.header-corporate .logo.logo-delta {
    margin-right: 10px;
}
.header-corporate .logo.logo-af {
    margin-right: 10px;
}
.header-corporate .logo.logo-klm {
    margin-right: 5px;
}
.header-corporate .logo.logo-line {
    margin-right: 5px;
    pointer-events: none;
}
.header-corporate .login {
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-end;
  max-width: 65%;
  width: 100%;
  margin-top: 6px;
}
@media (max-width: 768px) {
  .header-corporate .login {
    width: auto;
 }
}
.header-corporate .login > div {
  flex: 0 0 auto;
  height: 2.5rem;
  margin: 0 0.1875rem;
}
.header-corporate .login > div:first-child {
  margin-left: 0;
}
.header-corporate .login > div:last-child {
  margin-right: 0;
}
.header-corporate .login .login-link {
  display: none;
}
@media (min-width: 992px) {
  .header-corporate .login .login-link {
    display: inline;
 }
}
.header-corporate .language {
  /* width: 4.25rem; */
  padding-top: 1.2rem;
}
.header-corporate .language a {
  color: #00295E;
  text-decoration: none;
}
.header-corporate .language a:hover, .header-corporate .language a:focus {
  color: #020C41;
}
.header-corporate .language span {
  border-left: 0.125rem solid #00295E;
  margin: 0 0.8125rem;
}
.header-corporate .logged-in {
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  max-width: 50%;
  width: 100%;
}
.header-corporate .logged-in .userinfo p, .header-corporate .logged-in .userinfo h5 {
  color: #00295E;
}
@media (max-width: 576px - 0.02px) {
    .header-corporate .login {
        width: auto;
    }
    .header-corporate .logged-in {
        display: none;
    }
}
@media (max-width: 768px - 0.02px ) {
    .header-corporate .login {
        width: 0;
    }
}
@media (max-width: 1140px ) {
    .header-corporate .login {
        width: 0;
    }
}
.header-corporate.-logged .login {
  width: auto;
}
@media (min-width: 1200px) {
	.header-wrapper .header-corporate .btn {
		height: 40px;
	}
}
@media (max-width: 768px) {
	.header-corporate .logged-in {
		display: none;
	}
}
.navbar-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 150px;
}
.navbar-menu.bg-corporate {
  color: #3d698a;
  background: #00295E;
  mix-blend-mode: normal;
  box-shadow: 2px 0 6px rgba(0, 0, 0, 0.2);
	position: relative;
}

.navbar-menu.bg-corporate.tiel::after {
	content: '';
	box-shadow: 2px 0 6px rgba(0, 0, 0, 0.2);
	background-color: #F0EFEB;
	height: 60px;
	position: absolute;
  top: 60px;
	left: 0;
	bottom: -30px;
	width: 100%;
    z-index:1;
}
.navbar-menu .navbar {
  list-style: none;
  margin: 0;
  padding: 0;
}
.navbar-menu .item {
  color: #fff;
  display: inline-block;
  padding: 15px 15px;
}
.navbar-menu .item:hover {
  background-color: #F0EFEB;
  color: #00295E;  
}

.navbar-menu .item:hover a{
  background-color: #F0EFEB;
  color: #00295E;  
}
.navbar-menu a {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px !important;
  font-weight: bold !important;
}
.navbar-menu a:hover {
  color: #00295E;
}
.navbar-menu a:hover, .navbar-menu a.selected {
  background: #E1DFD780;
}
.navbar-menu .submenu {
  background-color: #F0EFEB;
  height: 60px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0;
  padding: 0 10px;
  top: 70px;
  transform: translateY(-10px);
  min-width: 100%;
  z-index: 3;
}

.navbar-menu .submenu .level-two:hover {
  background: #00295E;
  color: #FFFFFF;
}

.navbar-menu .submenu .level-two:hover a{
  background: #00295E;
  color: #FFFFFF;
}

.navbar-menu .submenu a {
  color: #00295E;
  font-weight: bold;
  text-decoration: none;
  text-transform: none;
  white-space: nowrap;
  font-size: 15px;
}
.navbar-menu .submenu span {
  padding-bottom: 3px;
}

.navbar-menu .submenu svg {
  display: inline;
  fill: #020C41;
  max-height: 14px;
  max-width: 14px;
  margin-right: 6px;
  vertical-align: middle;
  line-height: 16px;
}
.navbar-menu .subnivel {
  background-color: #FFFFFF;
  position: absolute;
  top: 60px;
  padding: 0;
}
.navbar-menu .subnivel .item {
  display: block;
  text-align: center;
  background-color: #FFFFFF;
}
.navbar-menu .subnivel .item a {
  display: inline-block;
  color: #00295E;
  width: 100%;
  padding: 0 15px;
  background-color: #FFFFFF;
}
.navbar-menu .submenu .level-two .subnivel .item a {
  background: white;
  color: #00295E;
}
.navbar-menu .submenu .level-two .subnivel .item a:hover {
  background: white;
  color: #0000E2;
}

@media (max-width: 960px ) {
  .navbar-menu {
    display: none;
 }
}
.navbar-menu-responsive {
  margin: 0 auto;
  width: 100%;
}
@media (min-width: 960px) {
  .navbar-menu-responsive {
    display: none;
 }
}
.navbar-menu-responsive a {
  text-decoration: none;
}
.navbar-menu-responsive .main-bar {
  align-items: center;
  color: #3d698a;
  background: #00295E;
  mix-blend-mode: normal;
  box-shadow: 2px 0 6px rgba(0, 0, 0, 0.2);
  display: flex;
  height: 3.125rem;
  justify-content: space-between;
  padding: 2.2rem 0.9375rem;
 /* Login/signup/logout buttons */
}
.navbar-menu-responsive .main-bar > div {
  flex: 0 0 auto;
}
.navbar-menu-responsive .main-bar .btn {
  padding-left: 0;
  padding-right: 0;
  width: 100px;
}
.navbar-menu-responsive .menu-toggle {
  color: white;
}
.navbar-menu-responsive a.menu-item {
  display: block;
  height: 3.125rem;
  line-height: 3.125rem;
  padding: 0 0.9375rem;
  width: 100%;
}
.navbar-menu-responsive a.menu-item svg {
  display: inline;
  height: 1.875rem;
  margin-right: 0.625rem;
  vertical-align: middle;
  width: 1.875rem;
}
.navbar-menu-responsive .menu-item-label {
  align-items: center;
  display: flex;
  height: 3.125rem;
  position: relative;
  width: 100%;
}
.navbar-menu-responsive .menu-item-label > * {
  height: 100%;
  line-height: 3.125rem;
  margin-bottom: 0;
  padding: 0 0.9375rem;
  width: 100%;
}
.navbar-menu-responsive .menu-item-label svg {
  display: inline;
  height: 1.875rem;
  margin-right: 0.625rem;
  vertical-align: middle;
  width: 1.875rem;
}
.navbar-menu-responsive .menu-item-label a[aria-expanded=true] {
  border-bottom: 3px solid #ff8200;
  margin: 0 0.9375rem;
}
.navbar-menu-responsive .menu-item {
  min-height: 3.125rem;
}
.navbar-menu-responsive .menu-item .icon-times {
  text-align: right;
}
.navbar-menu-responsive .menu-item .icon-times a {
  border: none;
}
.navbar-menu-responsive .menu-container {
  background: #00295E;
  mix-blend-mode: normal;
  box-shadow: 2px 0 6px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 9000px;
  z-index: 20;
}
.navbar-menu-responsive .level-1 > .menu-item {
  color: #fff;
  font-weight: 500;
}
.navbar-menu-responsive .level-1 > .menu-item > .menu-item-label > a {
  color: #fff;
	font-weight: 500;
	text-transform: uppercase;
}
.navbar-menu-responsive .level-2 > .menu-item {
  background-color: #fff;
  color: #020C41;
  font-size: 0.875rem;
  border-bottom: 0.5px solid #020C41;
}
.navbar-menu-responsive .level-2 > .menu-item > .menu-item-label > a {
  color: #020C41;
}
.navbar-menu-responsive .level-2 > .menu-item > .menu-item-label > a svg {
  fill: #020C41;
}
.navbar-menu-responsive .level-2 > .menu-item > svg {
  fill: #020C41;
}
.navbar-menu-responsive .level-3 > .menu-item {
  background-color: #020C41;
  border-bottom: 0.0625rem solid #ccc;
  color: #fff;
  font-weight: 400;
}
.navbar-menu-responsive .level-3 > .menu-item > .menu-item-label > a {
  color: #fff;
  font-weight: 400;
}
.footer-wrapper {
  background-color: #00295E;
  padding-top: 1.875rem;
  padding-bottom: 0;
  color: #FFFFFF;
  position: relative;
}
.footer-wrapper .footer-lines{
  position: absolute;
  right: 0;
  top: -1px;
  height: 100%;
  display: none;
  width: 36px;
} 
@media (min-width: 768px) {
  .footer-wrapper .footer-lines{
    display: block;
 }
 .footer-lines {
  display: block;
 }
}
.footer-wrapper .site-footer .logo {
  height: 1.25rem;
  width: auto;
}
.footer-wrapper .site-footer > .row > [class*="col"] {
  border-top: 0.0625rem solid #ccc;
  padding-bottom: 1.875rem;
  padding-top: 1.875rem;
}
.footer-wrapper .site-footer > .row > [class*="col"]:first-child {
  border-top: 0;
  padding-top: 0;
}
@media (min-width: 769px) {
  .footer-wrapper .site-footer > .row > [class*="col"] {
    border-top: none;
    border-left: 0.0625rem solid #ccc;
    padding: 0 0.9375rem;
 }
  .footer-wrapper .site-footer > .row > [class*="col"]:first-child {
    border-left: 0;
    padding-left: 0;
 }
  .footer-wrapper .site-footer > .row > [class*="col"]:last-child {
    padding-right: 0;
 }
}
.footer-wrapper .site-footer .contact-info h6{
  color: #FFFFFF;
  font-weight: 600 !important;
}
.footer-wrapper .site-footer .contact-info a{
  color: #FFFFFF;
  text-decoration: underline;
  font-size: 12px;
}
.footer-wrapper .site-footer .contact-info > .row {
  margin-bottom: 0.625rem;
}
.footer-wrapper .site-footer .contact-info > .row:last-child {
  margin-bottom: 0;
}
.footer-wrapper .site-footer .contact-info > .row > [class*="col-"]:last-child {
  color: #7d7d7d;
}
.footer-wrapper .site-footer .other-info p {
  margin-bottom: 0.625rem;
}
.footer-wrapper .site-footer .other-info p a {
  color: #FFFFFF;
  text-decoration: underline;
  font-size: 12px;
}
.footer-wrapper .site-footer .other-info h6 {
  color: #FFFFFF;
  font-weight: 600 !important;
  font-size: 12px;
}

.footer-wrapper .site-footer .other-links a {
  color: #FFFFFF;
  text-decoration: underline;
  font-size: 12px;
}
.footer-wrapper .site-footer h5 {
  margin-bottom: 1.25rem;
}
.footer-wrapper .site-footer p {
	line-height: 2;
	font-size: 12px;
}
@media (min-width: 1200px) {
	.footer-wrapper .site-footer p,
	.legal-wrapper .site-legal p  {
		font-size: 12px;
	}
	.footer-wrapper .site-footer a,
	.legal-wrapper .site-legal a {
		font-size: 12px;
	}
}
.btn-sm {
  min-width: 100px;
  font-weight: 300;
  padding: 0.375rem 1.5rem;
  font-size: 0.75rem;
  line-height: 0.625rem;
  border-radius: 1.875rem;
}
.btn-sm:hover {
  font-weight: 300;
}
.site-footer, .site-legal {
  font-weight: 400;
}
.site-footer p:last-child, .site-legal p:last-child, .site-footer div:last-child, .site-legal div:last-child {
  margin-bottom: 0;
}
.site-footer a, .site-legal a, .site-legal p {
  text-decoration: none;
  font-size: 12px;
}
.legal-wrapper {
  background-color: #E1DFD7;
}
.legal-wrapper .site-legal {
  color: #00295E;
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
}
@media (min-width: 768px) {
  .legal-wrapper .site-legal > .row > [class*="col"]:first-child {
    padding-left: 0;
 }
  .legal-wrapper .site-legal > .row > [class*="col"]:last-child {
    padding-right: 0;
 }
}
.legal-wrapper .site-legal a {
  color: #00295E;
}
.legal-wrapper .site-legal a:hover {
  color: #020C41;
}

/* 
* * * New Styles Aeroméxico Rewards Corporate *** 
* @vincoorbis.com
*/

/*----------------------------------------------------
Global Styles
----------------------------------------------------*/
.bg-big-stone {
    background-color: #182743;
}
.bg-chathams-blue {
    background-color: #184076;
}
.bg-cardinal {
    background-color: #cb2229;
}
.bg-cerulean {
    background-color: #00a1de;
}
.bg-gradient {
    background-color: #020C41;
}
.bg-flush-orange {
    background-color: #FF8200;
}
.bg-bismark {
    background-color: #496C82;
}
.bg-horizon {
    background-color: #020C41;
}
.bg-big-stone {
    background-color: #182743;
}
.bg-alabaster {
    background-color: #F8F8F8;
}
/* BG AM */
.bg-navy-blue {
  background-color: #030D75;
}
.bg-midnight-blue {
  background-color: #00295E;
}
.bg-tangaroa {
    background-color: #020C41;
}
.redirect-icon {
    color: white;
    font-size: 17px;
    line-height: 16px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
    position: relative;
    bottom: 40px;
}
.container-form {
    text-align: center
}
.container-form .card {
    margin: 0px auto;
}

/* Floting Label */
  
.floating-label { 
    position:relative; 
} 
/* .floating-label input:focus{
    outline:none;
    border-bottom:2px solid #5264AE; 
} */
.floating-label label {
    color: #a0aab1; 
    cursor: text;
    font-weight: 500;
    position: absolute;
    pointer-events:none;
    left: 0;
    top: 1.75rem;
    transform-origin: top left;
    transition: all 0.3s ease; 
    -moz-transition: all 0.3s ease; 
    -webkit-transition: all 0.3s ease;
    font-size: 16px;
    opacity: 0;
}
.floating-label input:focus ~ label,
.floating-label input:not(:placeholder-shown) ~ label{
    transform: scale(.77) translateY(-1.75rem);
    color: #020C41;
    opacity: 1;
}
/* Carousel Promotions */ 
.carousel-promotions .carousel-indicators > li {
    border-radius: 50%;
    height: 10px;
    width: 10px;
    margin-top: 4px;
    background-color: white;   
}
@media (max-width: 414px) {
    .carousel-promotions .carousel-indicators > li {
        width: 8px;
        height: 8px;
        margin-top: 3px;
   }
}
.carousel-promotions .carousel-indicators {  
    display: inline-block;
    list-style: none;
    position: absolute;
    width: 30px;
    bottom: -15%;
    left: 13%;
    margin: 15px;
}
@media (max-width: 1024px) {
    .carousel-promotions .carousel-indicators {  
        position: absolute;
        bottom: -2%;
        left: 2%;
    }
}
@media (max-width: 414px) {
    .carousel-promotions .carousel-indicators {  
        position: absolute;
        width: 20px;
        bottom: 0;
        left: -1%;
    }
}
@media (max-width: 375px) {
    .carousel-promotions .carousel-indicators {  
        position: absolute;
        width: 20px;
        bottom: 1%;
    }
}
#carousel .carousel-promotions {
    margin-bottom: 0;
    height: 200vh;
    max-height: 200px;
    border-radius: 5px;
}
.carousel-promotions img {
    border-radius: 5px; 
    height: 255px;
    min-height: 162px;
    width: 394px;
}
@media (max-width: 1823px) {
    .carousel-promotions img {
        width: 478px;
        height: 200px;
   }
}
@media (max-width: 1634px) {
    .carousel-promotions img {
        width: 403px;
   }
}
@media (max-width: 1543px) {
    .carousel-promotions img {
        width: 344px;
   }
}
@media (max-width: 1024px) {
    .carousel-promotions img {
        height: 160px;
   }
}
@media (max-width: 414px) {
    .carousel-promotions img {
        width: 352px;
        height: 187px;
   }
}
@media (max-width: 375px) {
    .carousel-promotions img {
        width: 318px;
   }
}
@media (max-width: 360px) {
    .carousel-promotions img {
        width: 306px;
   }
}
@media (max-width: 320px) {
    .carousel-promotions img {
        width: 271px;
   }
}
.carousel-promotions .carousel-item{
    transition: none;
}
.container-home-member .carousel-promotions .carousel-inner {        
    width: 394px;
    height: 255px;
}
@media (max-width: 1823px) {
    .container-home-member .carousel-promotions .carousel-inner {
        width: 478px;
        height: 200px;
   }
}
@media (max-width: 1634px) {
    .container-home-member .carousel-promotions .carousel-inner {
        width: 403px;
   }
}
@media (max-width: 1543px) {
    .container-home-member .carousel-promotions .carousel-inner {
        width: 344px;
   }
}
@media (max-width: 1024px) {
    .container-home-member .carousel-promotions .carousel-inner {
        height: 160px;
   }
}
@media (max-width: 414px) {
    .container-home-member .carousel-promotions .carousel-inner {
        width: 352px;
        height: 187px;
   }
}
@media (max-width: 375px) {
    .container-home-member .carousel-promotions .carousel-inner {
        width: 318px;
        height: 187px;
   }
}
@media (max-width: 360px) {
    .container-home-member .carousel-promotions .carousel-inner {
        width: 306px;
   }
}
@media (max-width: 320px) {
    .container-home-member .carousel-promotions .carousel-inner {
        width: 271px;
   }
}
.container-home-member .carousel-promotions .carousel-caption {
    padding: 0;
    margin: 15px;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
}
.carousel-promotions .carousel-item {
    max-height: 162px;
    min-height: 162px;
    max-width: 475px;
    position: absolute;
}
.carousel-promotions .carousel-item .carousel-caption div a.btn-primary {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 22%;
    background: #07E7CC;
    color: #00295E;
}
@media (max-width: 1823px) {
    .carousel-promotions .carousel-item .carousel-caption div a.btn-primary {
        margin-top: 1%;
   }
}
@media (max-width: 414px) {
    .carousel-promotions .carousel-item .carousel-caption div a.btn-primary {
        margin-top: 16%;
   }
}
/* Sidebar Menú */ 
.sidebar {  
    min-height: 846px;
    height: 100%;
}
.sidebar header {  
  padding: 41px 0px;
  position: relative;
  overflow: hidden;
}

.sidebar .footer-lines{  
  position: absolute;
    right: 0;
    top: -1px;
    
}
  
.sidebar .nav a > .image {
    order: 0;
    width: 30px;
    margin-right:15px;
}
.sidebar a .content {
    order: 1;
    vertical-align: middle;
}
.sidebar .nav {
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
}
@media (max-width: 1024px) {
    .container-sidebar-homem .nav {
        margin-top: 86px;
    }
}
@media (max-width: 768px) {
    .container-sidebar-homem .nav {
        margin-top: 0;
    }
}

.sidebar .nav li {
    width: 100%;
}
.sidebar .nav a {
    background: none;
    border-bottom: 1px solid #ABBFCC;
    color: #FFFFFF;
    font-size: 14px;
    padding: 15px 3px;
    display: flex;
    vertical-align: middle;
}
.sidebar .nav a p {
    font-size: 16px;
}
@media (max-width: 1776px) {
    .sidebar .nav a p {
        font-size: 14px;
    }
}
@media (max-width: 1643px) {
    .sidebar .nav a p {
        font-size: 13px;
    }
}
@media (max-width: 768px) {
    .sidebar .nav a p {
        font-size: 14px;
    }
}
.sidebar .nav li a:active,
.sidebar .nav li a:hover{
    background-color: #020C41;
    color: #FFFFFF;
    text-decoration: none;
}
.sidebar .nav li a:hover .image .path{
    fill: #FFFFFF;
}
.sidebar .nav li:first-of-type a {
    border-top: 1px solid #ABBFCC;
}
.sidebar li{
    width: 100%;
}
/* SideBar Mobile */
@media (max-width: 414px) {
    .container-sidebar-homem .nav {
        padding: 0;
    }
}
@media (max-width: 1390px) {
    .sidebar .container-data {
        padding-right: 15px !important;
    }
    .container-sidebar-homem .nav {
       margin-top: 70px;
    }
}
@media (max-width: 414px) {
    .sidebar .container-data {
        padding: 0;
    }
}
@media (max-width: 1024px) {
    .container-sidebar-homem {
        padding: 0;
    }
}
@media (max-width: 768px) {
    .container-sidebar-homem {
        padding-left: 15px;
        padding-right: 15px;
    }
}

/*----------------------------------------------------
Login Styles
----------------------------------------------------*/
.container-login h2 {
    color: #020C41;
}
.container-login a.btn-primary {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff!important;
}

/*----------------------------------------------------
Enrollment Styles
----------------------------------------------------*/
.container-enrollment h2 {
    color: #020C41;
}
.container-enrollment .box-inscription p {
    color: #020C41;
}
.container-enrollment .box-inscription a {
    color: #030D75;
}
.container-enrollment .box-inscription a:hover {
    color: #020C41;      
}
.container-enrollment .link-inscription {
    color: #020C41; 
}
.container-enrollment .box-recover a {
    color: #020C41;      
}
.container-enrollment a.btn-primary { 
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff!important;
}
.container-enrollment a.btn-secondary { 
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.container-enrollment p, label {
    color: #020C41;
}
.container-enrollment a {
    color: white;
}
.container-enrollment .box-btn > a:hover {
  border-radius: 1.875rem;
  background-color: #030D75;
  border: 1px solid #030d75;
  color: #ffffff;
  font-weight: 600;
}
.container-enrollment .box-btn > a {
    border-radius: 1.875rem;
    background-color: transparent;
    border: 1px solid #030d75;
    color: #030d75;
    font-weight: 600;
    
}
.container-enrollment .custom-select {       
    display: inline-block;
    width: 100%;
    padding: 0.6875rem 0 0.6875rem 0;    
    color: #020C41;   
    background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23a0aab1' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'/%3E%3C/svg%3E") no-repeat right 0 center;
    background-size: 16px 16px;    
}
.container-enrollment .select-label {       
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: justify;
    width: 100%;
    color: #020C41;
    margin-bottom: 0;
}
.container-enrollment .input-group-text {
    padding: 0;
    width: 40px;
    border: 0;
  }
.container-enrollment ::placeholder, select{       
    font-size: 15px;
}
.container-enrollment .icon-add-new-contact {
    font-size: 14px;
    color: #020C41;
}
.container-enrollment .icon-delete-new-contact {
    color: #CA0F0F;
    font-size: 14px;
}
/*----------------------------------------------------
Home-Member Styles
----------------------------------------------------*/
.container-home-member {
    width: 90%;
    margin: 0 auto;
    padding-left: 15px;
}
@media (max-width: 414px) {
    .container-home-member {
        width: 100%;
        margin: 0;
        padding: 15px;
        padding: 0;
    }
}
.container-home-member .row {
    margin: 0;
}
.promo-block {
    width: 100vh;
    height: 100vh;
    max-width: 255px;
    max-height: 255px;
    padding: 10px;
    text-decoration: none;
    border-radius: 5px;  
    margin: 10px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}
@media (max-width: 1823px) {
    .promo-block {
        max-width: 200px;
        max-height: 200px;
   }
}
@media (max-width: 1024px) {
    .promo-block {
        max-width: 160px;
        max-height: 160px;
   }
}
@media (max-width: 414px) {
    .promo-block {
        max-width: 170px;
        max-height: 170px;
   }
}
@media (max-width: 375px) {
    .promo-block {
        max-width: 152px;
        max-height: 152px;
   }
}
@media (max-width: 360px) {
    .promo-block {
        max-width: 145px;
        max-height: 145px;
   }
}
@media (max-width: 320px) {
    .promo-block {
        max-width: 125px;
        max-height: 125px;
   }
}
.promo-block > .content > .title {
    color: white;
}
.promo-block  > .image {
    position: absolute;
    bottom: 2%;
}  
.avatar-circle {
    position: relative;
    left: 22%;
    top: 10%;
    height: 65px;
    width: 65px;
    border-radius: 50%;
    background-color: transparent;
}
@media (max-width: 1480px) {
    .avatar-circle {
        left: 7%;
   }
}
@media (max-width: 1390px) {
    .avatar-circle {
        left: -15%;
   }
}
@media (max-width: 1024px) {
    .avatar-circle {
        left: -42%;
   }
}
@media (max-width: 768px) {
    .avatar-circle {
        left: 22%;
   }
}
@media (max-width: 414px) {
    .avatar-circle {
        left: -22%;
   }
}
@media (max-width: 375px) {
    .avatar-circle {
        left: -30%;
   }
}
@media (max-width: 320px) {
    .avatar-circle {
        left: -35%;
   }
}
.avatar-circle-2 {
    position: absolute;
    left: 8%;
    top: 8%;
    height: 55px;
    width: 55px;
    border-radius: 50%;
    background-color: #E0DED6;
}
.notification {
    position: absolute;
    right: 25%;
    top: 15%;
}
.notification-text {
    width: 5%;
    height: 5%;
    text-align: center;
}
.notification-text p {
    font-weight: 500;
    font-size: 20px;
    line-height: 42px;
    margin: 0;
    color: #00295E;
}
.circle-2 {
    position: relative;
    left: 82%;
    top: 8%;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: #496C82;
}
.circle-3 {
    position: relative;
    left: 38%;
    top: 82%;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: #496C82;
}
.circle-4 {
    position: relative;
    left: -1%;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background-color: #496C82;
}
.company-data > p,
.name-data > p,
.account-data > p,
.title > p {
    display: inline;
    color: #FFFFFF;
    font-family: Roboto;
}
.company-data > p,
.account-data > p  {
    font-weight: 500;  
    line-height: 15px;
    font-size: 14px;
}
.name-data > p {
    font-weight: 500;  
    line-height: 23px;
    font-size: 16px;
}
.title > p {
    font-weight: 300; 
    line-height: 14px;
    font-size: 12px;
}
.title > p {
    font-size: 12px;
}

/* Table pagination  */

.container-home-member .container-body .account{
    flex-direction: row;
}
.account {
    flex-direction: row;
}
.account .title {
    display: flex;
    align-items: center;
}
.table-statement,
.table-home {
    width: 130%!important;
}
.table-statement, tbody td,
.table-home tbody td {
    border: none;
}
.table-statement.dataTable.no-footer,
.table-home.dataTable.no-footer {
    border: 1px solid #ccc;
    border-radius: 5px;
}
.table-statement_paginate
#table-home_paginate {
    padding-top: 20px;
}
#table-statement_wrapper #table-statement_paginate .paginate_button.disabled,
#table-home_wrapper #table-home_paginate .paginate_button.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}
#table-statement_wrapper #table-statement_paginate .paginate_button.current, #table-statement_wrapper #table-statement_paginate .paginate_button:hover,
#table-home_wrapper #table-home_paginate .paginate_button.current, #table-home_wrapper #table-home_paginate .paginate_button:hover {
    z-index: 1;
    color: #fff!important;
    background-color: #020C41!important;
    border-color: #020C41;
    background: none;
}
#table-statement_wrapper #table-statement_paginate .paginate_button
#table-home_wrapper #table-home_paginate .paginate_button {
    position: relative;
    line-height: 2rem;
    color: #020C41!important;
    font-size: 1rem;
    background-color: #fff;
    border: 1px solid #ccc;
    height: 2.125rem;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    padding: 0;
    text-align: center;
    width: 2.125rem;
}

/* Cards Partners*/
.container-home-member .card-carousel-cp {
    box-shadow: 1px 1px 3px #ddd;
    border-radius: 5px;
    height: 471px;
    width: 330px;
}
.container-home-member .divider {
    background-color: #030D75;   
    height: 4px; 
    width: 65px;
    margin-left: 20px;
    margin-top: 5px;  
}
@media (max-width: 414px) {
    .container-home-member .carousel-partners {
        padding-right: 10px;
        margin-left: 5px;
   }
}
.container-home-member .carousel-partners.slick-initialized.slick-slider > button.carousel-next.carousel-cp.carousel-cp-next.slick-arrow { 
    box-sizing: border-box;
    margin: 0;
    background-color: rgba(66, 85, 99, 0.7);
    border: 0;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 1.5rem;
    width: 24px;
    height: 30px;
    opacity: 0.7;
    z-index: 5;
    position: absolute;
    right: 12px;
    top: 50%;    
}
.container-home-member.card-carousel-cp > img {
    width: auto;
    height: auto;
}
.container-home-member .carousel-partners.slick-initialized.slick-slider > button.carousel-prev.carousel-cp.carousel-cp-prev.slick-arrow {
    box-sizing: border-box;
    margin: 0;
    background-color: rgba(66, 85, 99, 0.7);
    border: 0;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 1.5rem;
    width: 24px;
    height: 30px;
    opacity: 0.7;
    z-index: 5;
    position: absolute;
    left: 1px;
    top: 50%;
}
.container-home-member .card-basic,
.container-home-member .card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    max-width: 1220px;
    word-wrap: break-word;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    margin: 10px;
    /* form_shadows */
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}
.container-home-member .carousel-partners > button.carousel-next.carousel-cp.carousel-cp-next.slick-arrow > span,
.container-home-member .carousel-partners > button.carousel-prev.carousel-cp.carousel-cp-prev.slick-arrow > span{
    padding-left: 0;
}
.container-home-member h2, 
.container-home-member h3 {
    color: #020C41;
}
.container-home-member h4 {
    color: #FFFFFF;
}
.carousel-promotions .overlay{
    background-color:#444444;
    width: 394px;
    height: 255px;
    position: absolute;
    border-radius: 5px; 
    opacity: 0.65;
}
@media (max-width: 1823px) {
    .carousel-promotions .overlay {
        width: 478px;
        height: 200px;
   }
}
@media (max-width: 1634px) {
    .carousel-promotions .overlay {
        width: 403px;
   }
}
@media (max-width: 1543px) {
    .carousel-promotions .overlay {
        width: 344px;
   }
}
@media (max-width: 1024px) {
    .carousel-promotions .overlay {
        height: 160px;
   }
}
@media (max-width: 414px) {
    .carousel-promotions .overlay {
        width: 352px;
        height: 187px;
   }
}
@media (max-width: 375px) {
    .carousel-promotions .overlay {
        width: 318px;
   }
}
@media (max-width: 360px) {
    .carousel-promotions .overlay {
        width: 306px;
   }
}
@media (max-width: 320px) {
    .carousel-promotions .overlay {
        width: 271px;
   }
}
.icon-dashboard {
    margin-right: 6px;
    padding: 0;
}


/*----------------------------------------------------
Corporate Profile View
----------------------------------------------------*/
.container-corporate-profile {
    width: 90%;
    margin: 0 auto;
    padding-left: 15px;
}
@media (max-width: 414px) {
    .container-corporate-profile {
        width: 100%;
        margin: 0;
        padding: 15px;
        padding: 0;
    }
}
.container-corporate-profile .row {
    margin: 0;
}
.container-corporate-profile .custom-select {       
    display: inline-block;
    width: 100%;
    padding: 0;  
    color: #020C41;   
    background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23a0aab1' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'/%3E%3C/svg%3E") no-repeat right 0 center;
    background-size: 16px 16px;    
}
.container-corporate-profile .select-label {       
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: justify;
    width: 100%;
    color: #AAAAAA;
    margin-bottom: 0;
}
.container-corporate-profile .input-group-text {
    padding: 0;
    width: 40px;
    border: 0;
  }
  .container-corporate-profile input {
    padding-top: 23px;
    padding-bottom: 20px;
  }
.container-corporate-profile ::placeholder, select{       
    font-size: 15px;
}
.container-corporate-profile .subtitle{       
    padding-left: 5px;
}
.container-corporate-profile input:read-only {
    background: none;
}
.container-corporate-profile .upload-dropzone {
    background-color: #F0F0F0;
    border: 1px dashed rgba(68, 68, 68, 0.25);
    margin: 10px;
    padding: 10px 30px;
}
.container-corporate-profile .upload-dropzone p {
    color: rgba(68, 68, 68, 0.25);
    font-weight: 500;
}
.container-corporate-profile .upload-dropzone .file-requirements {
    margin-top: 20px;
}
.container-corporate-profile .upload-dropzone .file-requirements > p {
    font-size: 12px;
    margin: 0;
    padding: 0;
    line-height: initial;
}
.container-corporate-profile .uploader-files-container .files-list {
    margin: 10px;
}
.container-corporate-profile .uploader-files-container .files-list ul li p label {
    color: #020C41;
    cursor: pointer;
}
.container-corporate-profile .update-controls {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 20px 10px;
    flex: 1 auto;
}
.container-corporate-profile .update-controls button {
    min-width: auto;
    margin: 0px 5px;
}
.container-corporate-profile .card-general,
.container-corporate-profile .card-contact {
    max-width: 580px;
}
@media (max-width: 1808px) {
    .container-corporate-profile .card-general,
    .container-corporate-profile .card-contact {
        max-width: 560px;
   }
}
@media (max-width: 1747px) {
    .container-corporate-profile .card-general,
    .container-corporate-profile .card-contact {
        max-width: 535px;
   }
}
@media (max-width: 1670px) {
    .container-corporate-profile .card-general,
    .container-corporate-profile .card-contact {
        max-width: 510px;
   }
}
@media (max-width: 1600px) {
    .container-corporate-profile .card-general,
    .container-corporate-profile .card-contact {
        max-width: 470px;
   }
}
@media (max-width: 1468px) {
    .container-corporate-profile .card-general,
    .container-corporate-profile .card-contact {
        max-width: 442px;
   }
}
@media (max-width: 1390px) {
    .container-corporate-profile .card-general,
    .container-corporate-profile .card-contact {
        max-width: 410px;
   }
}
@media (max-width: 1289px) {
    .container-corporate-profile .card-general,
    .container-corporate-profile .card-contact {
        max-width: 366px;
   }
}
@media (max-width: 1024px) {
    .container-corporate-profile .card-general,
    .container-corporate-profile .card-contact {
        max-width: 365px;
   }
}
@media (max-width: 414px) {
    .container-corporate-profile .card-general,
    .container-corporate-profile .card-contact {
        max-width: 350px;
   }
}
@media (max-width: 375px) {
    .container-corporate-profile .card-general,
    .container-corporate-profile .card-contact {
        max-width: 350px;
   }
}
.container-corporate-profile .telephone-code-profile {
    margin-top: 14px;
}
.container-corporate-profile .box-btn-after > a{
    color: #00295E;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.container-corporate-profile .box-btn-after > a:hover {
    color: white;
}
.container-corporate-profile .container-message-ue p{
    color: #444444;
}
.container-corporate-profile .fa-lock{
    font-size: 14px;
    color: #00295E;
}
.container-corporate-profile .uploader-files-container div.upload-dropzone.max-files {
    cursor: not-allowed;
}
.container-corporate-profile .telephone-contact > div > a,
.container-corporate-profile .remove-contact > div > a {
    color: #00295E;
    cursor: pointer;
}
.container-corporate-profile .telephone-contact > div > a:hover,
.container-corporate-profile .remove-contact > div > a:hover {
    color: #00295E;
    cursor: pointer;
}
.container-corporate-profile h2 {
    padding-left: 22px;
}
@media (max-width: 414px) {
    .container-corporate-profile h2 {
        padding-left: 0;
   }
}
.container-corporate-profile button.btn-secondary {   
    display: inline-flex;
    align-items: center;
    justify-content: center;   
}
.container-corporate-profile .opacity {   
    opacity: 0.35;
}
.container-corporate-profile .disabled-all {   
    cursor: not-allowed;
    pointer-events: none;
}
.container-corporate-profile .btn-outline-primary {
    padding: 0px 0px !important;
}
div.container-fluid.bg-alabaster > div > div.col-12.col-md-9.container-body.mt-15.p-0 > div.box-btn-responsive.row.w-100.mb-15.mt-15.d-md-none.d-sm-block > div > button.col-5.btn.btn-primary.btn-sm {
    font-size: .70rem;
    padding: 0;
}

/*----------------------------------------------------
Account Statement View
----------------------------------------------------*/
.container-account-statement {
    width: 90%;
    margin: 0 auto;
    padding-left: 15px;
}
@media (max-width: 414px) {
    .container-account-statement {
        width: 100%;
        margin: 0;
        padding: 15px;
        padding: 0;
    }
}
.container-account-statement .row {
    margin: 0;
}
.container-account-statement .custom-select {       
    display: inline-block;
    width: 100%;
    padding: 0;  
    color: #D6D6D6;   
    background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23a0aab1' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'/%3E%3C/svg%3E") no-repeat right 0 center;
    background-size: 16px 16px;    
}
.container-account-statement .select-label {       
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: justify;
    width: 100%;
    color: #020C41;
    margin-bottom: 0;
}
.container-account-statement .input-group-text {
    padding: 0;
    width: 40px;
    border: 0;
  }
.container-account-statement ::placeholder, select{       
    font-size: 15px;
}
.container-account-statement .subtitle{       
    padding-left: 5px;
}
.container-account-statement button.btn-secondary {   
    display: inline-flex;
    align-items: center;
    justify-content: center;   
}
.container-account-statement .arrows {
    transform: rotate(90deg);
    display: inline-block;
}
.container-account-statement table.dataTable thead .sorting_asc, 
.container-account-statement table.dataTable thead .sorting_desc, 
.container-account-statement table.dataTable thead .sorting {
    background-image: none;
}
.container-account-statement .table tbody td:last-child {
    border: none;
}
.container-account-statement #table-statement.dataTable.no-footer, #table-home.dataTable.no-footer {
    border: 0;
    border-radius: 0;
}
.container-account-statement .dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: 1px solid #ccc;
}
.container-account-statement .table tbody td {
    border-left: 0;
}
.container-account-statement .table tbody td:first-child {
    border-left: 1px solid #ccc;
}
.container-account-statement .table tbody td:last-child {
    border-right: 1px solid #ccc;
}

/* Modal Export */
.modal-export .modal-title {
    color: #020C41;
}

.modal-export .modal-buttons button {
    min-width: 100px;
}

.modal-export .custom-control-label {
    font-size: 16px;
}

.modal-export .custom-radio .custom-control-input:checked~.custom-control-label::after,
.modal-export .custom-control-label::after  {
    border: none !important;
}

/*----------------------------------------------------
*        Represent and Contacts view
* color-horizon: #6C93AC
* color-fiord: #020C41
----------------------------------------------------*/
.represent-contacts {
    width: 90%;
    margin: 0 auto;
    padding-left: 15px;
}
@media (max-width: 414px) {
    .represent-contacts {
        width: 100%;
        margin: 0;
        padding: 15px;
        padding: 0;
    }
}
.represent-contacts .row {
    margin: 0;
}
.represent-contacts .row-exception {
    margin-left: -15px;
    margin-right: -15px;
}
.represent-contacts .icon-dashboard {
    display: none;
}
@media (max-width: 768px) {
    .represent-contacts .icon-dashboard {
        display: inline-block;
    }
    .represent-contacts .btn {
        padding: 0.5rem;
    }
}
/* .represent-contacts p {
    color: #00295E;
} */
.represent-contacts .card-basic {
    flex-direction: initial;
}
.represent-contacts .card-white {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.represent-contacts .info-card {
    border: 2px solid #030D75;
}
.represent-contacts .info-card .avatar-circles {
    top: 5px;
}
@media (max-width: 768px ) {
    .represent-contacts .info-card .avatar-circle {
        left: -50%;
    }
}
@media (max-width: 767px ) {
    .represent-contacts .info-card .avatar-circle {
        left: 0;
        top: 0;
    }
}
.represent-contacts .info-card .avatar-circle .avatar-circle-2.variant{
    background-color: #DCCCC9;
}
.represent-contacts .info-card .bd-left {
    border-left: 2px solid #00295E;
}
@media (max-width: 767px ) {
    .represent-contacts .info-card .bd-left {
        border: 0;
        border-top: 2px solid #00295E;
    }
}
.represent-contacts .info-card .bd-btom {
    border-bottom: 2px solid #00295E;
}
@media (max-width: 767px ) {
    .represent-contacts .info-card .bd-btom {
        border: 0;
        border-right: 2px solid #00295E;
    }
}
.represent-contacts .info-card .icon-action {
    height: 47px;
}
@media (max-width: 768px ) {
    .represent-contacts .info-card .icon-action {
        height: 45px;
    }
}
@media (min-width: 1500px ) {
    .represent-contacts .info-card .icon-action {
        height: 59.5px;
    }
}
.represent-contacts .list-represents .avatar-circle-2 {
    height: 50px;
    width: 50px;
}
.represent-contacts .list-represents .avatar-circle-2 .notification-text p {
    line-height: 35px;
}
.represent-contacts .info-card .icon-action .fas {
    font-size: 22px;
    padding-top: 8px;
    color: #030D75;
}
.represent-contacts .input-group .form-control {
    color: #020C41;
    border-color: #aaaaaa;
}
.represent-contacts .input-group .form-control::placeholder {
    color: #D6D6D6;
}
.represent-contacts .input-group-append .fa-search {
    margin-top: -15px;
    color: #020C41;
}
.represent-contacts .modal form .input-group {
    display: block;
}
.represent-contacts .modal form .custom-select {
    height: 38px;
    margin-bottom: 0 !important;
    padding-bottom: 0;
    padding-left: 2px;
}
.represent-contacts .form-update-contact .input-group .input-group-append,
.represent-contacts .form-add-contact .input-group .input-group-append {
    top: 2rem;
    color: #020C41;
}
.represent-contacts .input-group .input-group-append .fa-angle-down {
    font-size: 20px;
    color: #020C41;
}
/*----------------------------------------------------
*               Cards and memberships
* color-horizon: #6C93AC
* color-fiord: #020C41
----------------------------------------------------*/
.cards-memberships {
    width: 90%;
    margin: 0 auto;
    padding-left: 15px;
}
@media (max-width: 414px) {
    .cards-memberships {
        width: 100%;
        margin: 0;
        padding: 15px;
        padding: 0;
    }
}
.cards-memberships .row {
    margin: 0;
}
.cards-memberships .row-exception {
    margin-left: -15px;
    margin-right: -15px;
}
.cards-memberships .icon-dashboard {
    display: none;
}
@media (max-width: 768px) {
    .cards-memberships .icon-dashboard {
        display: inline-block;
    }
    .cards-memberships .btn {
        padding: 0.5rem;
    }
}
.cards-memberships .btn-outline-primary {
    border-width: 1px;
}
.cards-memberships  h4 {
    font-weight: 400;
}
.cards-memberships .card-basic {
    flex-direction: initial;
}
.cards-memberships .card-white {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.cards-memberships .cards-type-member .card-white {
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
}
.cards-memberships .cards-type-member .count-cards {
    background: #07E7CC;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-block;
    color: #020C41;
    line-height: 1.8;
    position: absolute;
    top: -5%;
    right: 0;
}
.cards-memberships .arrows {
    transform: rotate(90deg);
    display: inline-block;
}
.cards-memberships .fas.fa-trash {
    font-size: 17px;
    color: #D0D0D0;
}
.cards-memberships .input-group .form-control {
    color: #020C41;
    border-color: #aaaaaa;
}
.cards-memberships .input-group .form-control::placeholder {
    color: #D6D6D6;
}
.cards-memberships .modal form .input-group {
    display: block;
}
.cards-memberships .modal form .custom-select {
    height: 38px;
    margin-bottom: 0 !important;
    padding-bottom: 0;
    padding-left: 2px;
}
.cards-memberships .form-add-card .input-group .input-group-append {
    top: 2rem;
    color: #020C41;
}
.cards-memberships .input-group .input-group-append .fa-angle-down {
    font-size: 20px;
    color: #020C41;
}
.cards-memberships .dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: 0;
}
.cards-memberships .table.dataTable thead .sorting,
.cards-memberships .table.dataTable thead .sorting_asc,
.cards-memberships .table.dataTable thead .sorting_desc {
    background: none !important;
}
.cards-memberships .dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable,
.cards-memberships .dataTables_wrapper.no-footer div.dataTables_scrollBody > table {
    border-bottom: 1px solid #ccc;
}
.cards-memberships .dataTables_wrapper .dataTables_paginate .paginate_button.current,
.cards-memberships .dataTables_wrapper .dataTables_paginate .paginate_button.current,
.cards-memberships .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    background: #020C41 !important;
    color: #fff !important;
}
.cards-memberships .dataTables_wrapper .dataTables_paginate .paginate_button.previous,
.cards-memberships .dataTables_wrapper .dataTables_paginate .paginate_button.next {
    font-size: 15px;
    padding: 5px;
    border: 0;
    color: #020C41 !important;
}
.cards-memberships .btn-outline-primary.disabled {
    background: #F1F1F1;
    border: 2px solid #CCCCCC;
    color: #AAAAAA;
}
/*----------------------------------------------------
*               retroactive-accreditations
* color-horizon: #6C93AC
* color-fiord: #020C41
----------------------------------------------------*/
.retroactive-accreditations {
    width: 90%;
    margin: 0 auto;
    padding-left: 15px;
}
@media (max-width: 414px) {
    .retroactive-accreditations {
        width: 100%;
        margin: 0;
        padding: 15px;
        padding: 0;
    }
}
.retroactive-accreditations .row {
    margin: 0;
}
.retroactive-accreditations .row-exception {
    margin-left: -15px;
    margin-right: -15px;
}
.retroactive-accreditations .icon-dashboard {
    display: none;
}
@media (max-width: 768px) {
    .retroactive-accreditations .icon-dashboard {
        display: inline-block;
    }
    .retroactive-accreditations .btn {
        padding: 0.5rem;
    }
}
.retroactive-accreditations .btn-outline-primary {
    border-width: 1px;
}
.retroactive-accreditations  h4 {
    font-weight: 400;
}
.retroactive-accreditations h1,
.retroactive-accreditations h2,
.retroactive-accreditations h3,
.retroactive-accreditations h4,
.retroactive-accreditations h5 {
    color: #020C41;
}
.retroactive-accreditations .card-basic {
    flex-direction: initial;
}
.retroactive-accreditations .card-white {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.retroactive-accreditations .input-group .form-control {
    color: #020C41;
    border-color: #aaaaaa;
}
.retroactive-accreditations .input-group .form-control::placeholder {
    color: #D6D6D6;
    font-size: 12px;
}
.retroactive-accreditations .input-group-append .fa-search {
    margin-top: -15px;
    color: #6C93AC;
}
.retroactive-accreditations .input-group {
    display: block;
}
.retroactive-accreditations .input-group label {
    font-size: 11px;
}
.retroactive-accreditations .custom-select {
    height: 38px;
    margin-bottom: 0 !important;
    padding-bottom: 0;
    padding-left: 2px;
}
.retroactive-accreditations .input-group .input-group-append,
.retroactive-accreditations .input-group .input-group-append {
    top: 2rem;
    color: #020C41;
}
.retroactive-accreditations .input-group .input-group-append .fa-angle-down {
    font-size: 20px;
    color: #020C41;
}
.retroactive-accreditations .input-group .fa-lock-alt, 
.retroactive-accreditations .input-group .fa-question-circle {
    color: #81A0B2;
    font-size: 14px;
    padding: 0 5px;
}
.retroactive-accreditations .nav-tabs {
  border-bottom: 0;
  border: 1px solid #dee2e6;
  padding: 5px;
  border-radius: 50px;
  display: inline-flex;
}
.retroactive-accreditations .nav-tabs .nav-link.active,
.retroactive-accreditations .nav-tabs .nav-item.show .nav-link {
  background: #0B2343;
  color: white;
  padding: 10px 41px;
  border-radius: 50px;
}
@media (max-width: 768px) {
    .retroactive-accreditations .nav-tabs .nav-link.active,
    .retroactive-accreditations .nav-tabs .nav-item.show .nav-link {
      background: #0B2343;
      color: white;
      padding: 10px 41px;
      border-radius: 50px;
    }
    .retroactive-accreditations .nav-tabs .nav-link {
        margin: 0;
        margin-left: 0 !important;
        padding-top: 9px;
    }
}
.retroactive-accreditations .nav-tabs .nav-link {
  background: transparent;
  color: #0B2343;
  padding: 10px 41px;
  border-radius: 50px;
  font-weight: 600;
}
.retroactive-accreditations .nav-tabs .nav-link:hover {
  background: #0B2343;
  color: white;
}
.retroactive-accreditations .upload-dropzone {
    background-color: #F0F0F0;
    border: 1px dashed rgba(68, 68, 68, 0.25);
    margin: 10px;
    padding: 10px 30px;
    width: 100%;
}
.retroactive-accreditations .upload-dropzone p {
    color: rgba(68, 68, 68, 0.25);
    font-weight: 500;
}
.retroactive-accreditations .upload-dropzone .file-requirements {
    margin-top: 20px;
}
.retroactive-accreditations .upload-dropzone .file-requirements > p {
    font-size: 12px;
    margin: 0;
    padding: 0;
    line-height: initial;
}
.retroactive-accreditations .uploader-files-container .files-list {
    margin: 10px;
}
.retroactive-accreditations .uploader-files-container .files-list ul li p label {
    color: #020C41;
    cursor: pointer;
}
.retroactive-accreditations .update-controls {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 20px 10px;
    flex: 1 auto;
}
.retroactive-accreditations .update-controls button {
    min-width: auto;
    margin: 0px 5px;
}
.retroactive-accreditations small {
    font-size: 11px;
    line-height: 15px;
    color: #A2A2A2;
}
.retroactive-accreditations a {
    font-size: 13px;
}
.retroactive-accreditations .dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: 0;
}
.retroactive-accreditations .table.dataTable thead .sorting,
.retroactive-accreditations .table.dataTable thead .sorting_asc,
.retroactive-accreditations .table.dataTable thead .sorting_desc {
    background: none !important;
    height: 32px;
    white-space: nowrap;
}
.retroactive-accreditations .dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable,
.retroactive-accreditations .dataTables_wrapper.no-footer div.dataTables_scrollBody > table {
    border-bottom: 1px solid #ccc;
}
.retroactive-accreditations .dataTables_wrapper .dataTables_paginate .paginate_button.current,
.retroactive-accreditations .dataTables_wrapper .dataTables_paginate .paginate_button.current,
.retroactive-accreditations .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    background: #020C41 !important;
    color: #fff !important;
}
.retroactive-accreditations .dataTables_wrapper .dataTables_paginate .paginate_button.previous,
.retroactive-accreditations .dataTables_wrapper .dataTables_paginate .paginate_button.next {
    font-size: 15px;
    padding: 5px;
    border: 0;
    color: #020C41 !important;
}
.retroactive-accreditations .arrows {
    transform: rotate(90deg);
    display: inline-block;
}
.retroactive-accreditations table {
    font-size: 12px;
}
.retroactive-accreditations .table tbody td {
    border: 0;
    height: 46px;
}
.retroactive-accreditations .custom-checkbox {
    font-size: 12px;
}
.retroactive-accreditations #accordionTermsConditions ul {
    list-style: none;
    padding: 0;
}
.retroactive-accreditations #accordionTermsConditions ul li {
    font-size: 12px;
    color: #020C41;
}

/*----------------------------------------------------
*               Tutorial General
----------------------------------------------------*/
.container-home-member .link-tutorials,
.view-statement-tutorial  .link-tutorials, 
.cards-memberships-tutorial  .link-tutorials {
    cursor: pointer;
}

.view-tutorial span.btn-primary,
.view-tutorial .btn-secondary,
.view-statement-tutorial span.btn-primary,
.view-statement-tutorial .btn-secondary,
.cards-memberships-tutorial span.btn-primary,
.cards-memberships-tutorial .btn-secondary { 
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.container-home-member .modal-dashboard-welcome .modal-content,
.view-statement-tutorial  .modal-statement-welcome .modal-content, 
.cards-memberships-tutorial  .modal-membership-welcome .modal-content {
    background-color: transparent;
    border: none;
}
.container-home-member .modal-dashboard-welcome .modal-dialog,
.view-statement-tutorial .modal-statement-welcome .modal-dialog,
.cards-memberships-tutorial .modal-membership-welcome .modal-dialog {
    max-width: 620px;
}
@media (max-width: 414px) {
    .container-home-member .modal-dashboard-welcome .modal-dialog,
    .view-statement-tutorial .modal-statement-welcome .modal-dialog,
    .cards-memberships-tutorial .modal-membership-welcome .modal-dialog {
        max-width: 408px;
        padding-left: 20px;
        padding-right: 20px;
    }
}
@media (max-width: 375px) {
    .container-home-member .modal-dashboard-welcome .modal-dialog,
    .view-statement-tutorial .modal-statement-welcome .modal-dialog,
    .cards-memberships-tutorial .modal-membership-welcome .modal-dialog {
        max-width: 382px;
    }
}
@media (max-width: 320px) {
    .container-home-member .modal-dashboard-welcome .modal-dialog,
    .view-statement-tutorial .modal-statement-welcome .modal-dialog,
    .cards-memberships-tutorial .modal-membership-welcome .modal-dialog {
        max-width: 329px;
    }
}
.container-home-member .modal-dashboard-welcome .modal-content h1,
.container-home-member .modal-dashboard-welcome .modal-content h3,
.view-statement-tutorial .modal-statement-welcome .modal-content h1,
.view-statement-tutorial .modal-statement-welcome .modal-content h3,
.cards-memberships-tutorial .modal-membership-welcome .modal-content h1,
.cards-memberships-tutorial .modal-membership-welcome .modal-content h3 {
    color: white;
}
.example-open .modal-backdrop {
    background-color: #42586A !important;
    opacity: 0.9 !important;
    height: auto;
} 

.tutorial-active .modal-backdrop {
    background-color: #444444 !important;
    opacity: 0.7 !important;
}
.backdrop-open .modal-backdrop {
    height: auto;
} 
.disabled-button-tutorial {
    pointer-events: none;
}
/*----------------------------------------------------
*               Tutorial Dashboard
----------------------------------------------------*/

.modal-tutorial-sidebar.tutorial-active {
    z-index: 1050;
    position: absolute;
    left: 28%;
    width: 470px;
    height: 88%;
    top: 65px;
}
@media (max-width: 768px) {
    .modal-tutorial-sidebar.tutorial-active {
        top: 84px;
        left: 7%;
    }
}
@media (max-width: 414px) {
    .modal-tutorial-sidebar.tutorial-active {
        top: 221px;
        left: 2%;
        width: 340px;
        padding-left: 20px;
        padding-top: 25px;
    }
}
@media (max-width: 320px) {
    .modal-tutorial-sidebar.tutorial-active {    
        width: 300px;
        top: 39%;
     
    }
}
.modal-tutorial-sidebar.tutorial-active .modal-dialog {
    margin: 120px 0 0 20px;
    max-width: 390px;
}
.modal-tutorial-sidebar.tutorial-active .modal-content::after {
    content: "";
    position: absolute;
    top: 50px;
    left: -6%;
    margin-left: -3px;
    border-width: 15px;
    border-style: solid;
    border-color: transparent white transparent transparent;
}
@media (max-width: 768px) {
    .modal-tutorial-sidebar.tutorial-active .modal-content::after {
        top: -29px;
        left: 14%;
        border-color: transparent transparent white transparent;
    }
}
.container-cards-square.tutorial-active .modal{
    position: absolute;
    top: 374px;
    bottom: initial;
    left: -4%;
    height: 30%;
}
@media (max-width: 1823px) {
    .container-cards-square.tutorial-active .modal{
        top: 310px;
        left: -8%;
    }
}
@media (max-width: 1024px) {
    .container-cards-square.tutorial-active .modal{
        top: 270px;
        left: -14%;
    }
}
@media (max-width: 768px) {
    .container-cards-square.tutorial-active .modal{
        top: 270px;
        left: -8%;
    }
}
@media (max-width: 414px) {
    .container-cards-square.tutorial-active .modal {
        top: 294px;
        left: 4%;
        padding-top: 20px;
    }
}
@media (max-width: 320px) {
    .container-cards-square.tutorial-active .modal {
        width: 290px;
        height: 20%;
    }
}
.container-cards-square.tutorial-active .modal-dialog,
.container-cards-square.tutorial-active.cards .modal-dialog,
.container-cards-square.tutorial-active.contacts .modal-dialog {
    margin: 10px 0 0 20px;
    max-width: 350px;
}
.container-cards-square.tutorial-active .modal-content::after, 
.container-cards-square.tutorial-active.cards .modal-content::after,
.container-cards-square.tutorial-active.contacts .modal-content::after {
    content: "";
    position: absolute;
    top: -24px;
    left: 50%;
    margin-left: -3px;
    border-width: 12px;
    border-style: solid;
    border-color: transparent transparent white transparent;
}
.container-cards-square.tutorial-active.cards .modal{
    position: absolute;
    top: 374px;
    bottom: initial;
    left: 17%;
    height: 30%;
}
@media (max-width: 1823px) {
    .container-cards-square.tutorial-active.cards .modal{
        top: 310px;
        left: 11%;
    }
}
@media (max-width: 1280px) and (max-height: 640px) {
    .container-cards-square.tutorial-active.cards .modal {
       left: 17%;
    }
}
@media (max-width: 1024px) {
    .container-cards-square.tutorial-active.cards .modal{
        top: 270px;
        left: 11%;
    }
}
@media (max-width: 414px) {
    .container-cards-square.tutorial-active.cards .modal {
        top: 270px;
        left: 4%;
        padding-top: 20px;
    }
}
@media (max-width: 320px) {
    .container-cards-square.tutorial-active.cards .modal {
        height: 20%;
    }
}
.container-cards-square.tutorial-active.contacts .modal{
    position: absolute;
    top: 374px;
    bottom: initial;
    left: 38%;
    height: 30%;
}
@media (max-width: 1823px) {
    .container-cards-square.tutorial-active.contacts .modal {
        top: 310px;
        left: 30%;
    }
}
@media (max-width: 1570px) {
    .container-cards-square.tutorial-active.contacts .modal {
        left: 35%;
    }
}
@media (max-width: 1280px) and (max-height: 640px) {
    .container-cards-square.tutorial-active.contacts .modal {
       left: 43%;
    }
}
@media (max-width: 1024px) {
    .container-cards-square.tutorial-active.contacts .modal {
        top: 270px;
        left: 37%;
    }
}
@media (max-width: 414px) {
    .container-cards-square.tutorial-active.contacts .modal {
        top: 440px;
        left: 4%;
    }
}
@media (max-width: 320px) {
    .container-cards-square.tutorial-active.contacts .modal {
        height: 20%;
    }
}
.accumulate-points.tutorial-active{
    position: relative;
}
.accumulate-points.tutorial-active .modal{
    position: absolute;
    bottom:initial;
    left: 53%;
    top: -4%;
    height: 60%;
}
@media (max-width: 1280px) and (max-height: 640px) {
    .accumulate-points.tutorial-active .modal {
       height: 48%;
    }
}
@media (max-width: 1024px) {
    .accumulate-points.tutorial-active .modal {
        left: 42%;
    }
}
@media (max-width: 414px) {
    .accumulate-points.tutorial-active .modal {
        top: 4%;
        left: 4%;
        padding-top: 20px;
    }
}
@media (max-width: 414px) {
    .accumulate-points.tutorial-active .modal {
        left: 2%;
    }
}
.accumulate-points.tutorial-active .modal-dialog {
    margin: 25px 0 0 20px;
    max-width: 350px;
}
.accumulate-points.tutorial-active .modal-content::after {
    content: "";
    position: absolute;
    top: -24px;
    left: 48%;
    margin-left: -3px;
    border-width: 12px;
    border-style: solid;
    border-color: transparent transparent white transparent;
}
.modal-tutorial-questions.tutorial-active-one {
    position: absolute;
    top: 65%;
    left: 28%;
    width: 370px;
}
@media (max-width: 1556px) and (max-height: 828px) {
    .modal-tutorial-questions.tutorial-active-one {
        top: 68%;
    }
}
/* Correccion */
@media (max-width: 1390px) {
    .modal-tutorial-questions.tutorial-active-one {
        top: 94%;
    }
}
@media (max-width: 1360px) and (max-height: 657px) {
    .modal-tutorial-questions.tutorial-active-one {
        top: 94%;
    }
}
@media (max-width: 1280px) and (max-height: 640px) {
    .modal-tutorial-questions.tutorial-active-one {
        top: 102%;
    }
}
@media (max-width: 1024px) {
    .modal-tutorial-questions.tutorial-active-one {
        top: 45%;
    }
}
@media (max-width: 768px) {
    .modal-tutorial-questions.tutorial-active-one {
        top: 7%;
        left: 7%;
    }
}
@media (max-width: 414px) {
    .modal-tutorial-questions.tutorial-active-one  {
        top: 27%;
        padding-top: 20px;
    }
}
@media (max-width: 375px) {
    .modal-tutorial-questions.tutorial-active-one  {
        top: 30%;
        left: 3%;
    }
}
@media (max-width: 320px) {
    .modal-tutorial-questions.tutorial-active-one  {
        top: 35%;
        width: 300px;
    }
}
.modal-tutorial-questions.tutorial-active-one .modal-dialog {
    margin: 120px 0 200px 20px;
    max-width: 350px;
}
.modal-tutorial-questions.tutorial-active-one .modal-content::after {
    content: "";
    position: absolute;
    top: 50px;
    left: -6%;
    margin-left: -3px;
    border-width: 12px;
    border-style: solid;
    border-color: transparent white transparent transparent;
}
@media (max-width: 768px) {
    .modal-tutorial-questions.tutorial-active-one .modal-content::after {
        top: -23px;
        left: 20%;
        border-color: transparent transparent white transparent;
    }
}
.content-dashboard-title.div-active {
    z-index: auto;
}
.content-dashboard-title.div-active h2{
    color: #020C41;
}
@media (max-width: 768px) {
    .content-dashboard-title.div-active {
        z-index: 1050;
    }
}
@media (max-width: 768px) {
    .content-dashboard-title.div-active h2{
        color: white;
    }
}
/*----------------------------------------------------
*               Tutorial Account Statement
----------------------------------------------------*/
.view-statement-tutorial .form-corp.tutorial-active .modal {
    position: absolute;
    top: 298px;
    left: 52%;
    width: 470px;
    height: 47%;
}
@media (max-width: 1584px) {
    .view-statement-tutorial .form-corp.tutorial-active .modal {
        top: 253px;
    }
}
@media (max-width: 1024px) {
    .view-statement-tutorial .form-corp.tutorial-active .modal {
        top: 240px;
        left: 39%;
    }
}
@media (max-width: 768px) {
    .view-statement-tutorial .form-corp.tutorial-active .modal {
        height: 77%;
    }
}
@media (max-width: 414px) {
    .view-statement-tutorial .form-corp.tutorial-active .modal {
        top: 84%;
        left: 9%;
        padding-top: 16px;
    }
}
@media (max-width: 375px) {
    .view-statement-tutorial .form-corp.tutorial-active .modal {
        top: 63%;
        left: 3%;
    }
}
@media (max-width: 320px) {
    .view-statement-tutorial .form-corp.tutorial-active .modal {
        width: 300px;
        left: -2%;
    }
}
.view-statement-tutorial .form-corp.tutorial-active .modal-dialog {
    margin: 120px 0 0 20px;
    max-width: 390px;
}
@media (max-width: 414px) {
    .view-statement-tutorial .form-corp.tutorial-active .modal-dialog {
        max-width: 320px;
    }
}
.view-statement-tutorial .form-corp.tutorial-active .modal-content::after {
    content: "";
    position: absolute;
    top: -27px;
    left: 50%;
    margin-left: -3px;
    border-width: 15px;
    border-style: solid;
    border-color: transparent transparent white transparent;
}
@media (max-width: 414px) {
    .view-statement-tutorial .form-corp.tutorial-active .modal-content::after {
        left: 48%;
    }
}
.view-statement-tutorial .form-corp.tutorial-active .account {
    z-index: 1050!important;
}

.table-statement-tutorial.tutorial-active .modal {
    position: absolute;
    top: -17px;
    left: 52%;
    width: 470px;
    height: 78%;
}
@media (max-width: 1024px) {
    .table-statement-tutorial.tutorial-active .modal {
        left: 36%;
    }
}
@media (max-width: 414px) {
    .table-statement-tutorial.tutorial-active .modal {
        top: 49%;
        left: 4%;
    }
}
@media (max-width: 375px) {
    .table-statement-tutorial.tutorial-active .modal {
        top: 38%;
        left: -2%;
    }
}
@media (max-width: 320px) {
    .table-statement-tutorial.tutorial-active .modal {
        width: 300px;
    }
}
.table-statement-tutorial.tutorial-active .modal-dialog {
    margin: 120px 0 0 20px;
    max-width: 390px;
}
@media (max-width: 414px) {
    .table-statement-tutorial.tutorial-active .modal-dialog {
        max-width: 350px;
    }
}
.table-statement-tutorial.tutorial-active .modal-content::after {
    content: "";
    position: absolute;
    top: 99%;
    left: 50%;
    margin-left: -3px;
    border-width: 15px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}
.table-statement-tutorial.tutorial-active .container-table-statement {
    z-index: 1050;
}
.modal-tutorial-questions.tutorial-active-two {
    position: absolute;
    top: 69%;
    left: 28%;
    width: 370px;
}
@media (max-width: 1556px) and (max-height: 828px) {
    .modal-tutorial-questions.tutorial-active-two {
        top: 74%;
    }
}
@media (max-width: 1389px) {
    .modal-tutorial-questions.tutorial-active-two {
        top: 68%;
    }
}
@media (max-width: 1360px) and (max-height: 657px) {
    .modal-tutorial-questions.tutorial-active-two {
        top: 102%;
    }
}
@media (max-width: 1300px) { 
    .modal-tutorial-questions.tutorial-active-two {
        top: 104%;
    }
}
@media (max-width: 1280px) and (max-height: 660px) {
    .modal-tutorial-questions.tutorial-active-two {
        top: 109%;
    }
}
@media (max-width: 1024px) {
    .modal-tutorial-questions.tutorial-active-two {
        top: 49%;
    }
}
@media (max-width: 768px) {
    .modal-tutorial-questions.tutorial-active-two {
        top: 7%;
        left: 7%;
    }
}
@media (max-width: 414px) {
    .modal-tutorial-questions.tutorial-active-two  {
        top: 24%;
        padding-top: 20px;
        height: 29%;
    }
}
@media (max-width: 375px) {
    .modal-tutorial-questions.tutorial-active-two  {
        left: 3%;
        top: 25%;
        height: 44%;
    }
}
@media (max-width: 320px) {
    .modal-tutorial-questions.tutorial-active-two  {
        width: 300px;
        height: 44%;
        top: 33%;
    }
}
.modal-tutorial-questions.tutorial-active-two .modal-dialog {
    margin: 120px 0 200px 20px;
    max-width: 350px;
}
.modal-tutorial-questions.tutorial-active-two .modal-content::after {
    content: "";
    position: absolute;
    top: 50px;
    left: -6%;
    margin-left: -3px;
    border-width: 12px;
    border-style: solid;
    border-color: transparent white transparent transparent;
}
@media (max-width: 768px) {
    .modal-tutorial-questions.tutorial-active-two .modal-content::after {
        top: -23px;
        left: 20%;
        border-color: transparent transparent white transparent;
    }
}
/*----------------------------------------------------
*             Cards and Memberships Tutorial
----------------------------------------------------*/
.container-cards-tutorial.tutorial-active-one .modal {
    position: absolute;
    top: 449px;
    left: 33%;
    width: 470px;
    height: 50%;
}
@media (max-width: 1675px) {
    .container-cards-tutorial.tutorial-active-one .modal {
        top: 404px;
        left: 30%;
    }
}
@media (max-width: 1539px) {
    .container-cards-tutorial.tutorial-active-one .modal {
        top: 346px;
        left: 26%;
    }
}
@media (max-width: 1280px) and (max-height: 660px) {
    .container-cards-tutorial.tutorial-active-one .modal {
        top: 331px;
        left: 23%;
    }
}
@media (max-width: 1024px) {
    .container-cards-tutorial.tutorial-active-one .modal {
        top: 284px;
        left: 18%;
    }
}
@media (max-width: 768px) {
    .container-cards-tutorial.tutorial-active-one .modal {
        height: 65%;
    }
}
@media (max-width: 414px) {
    .container-cards-tutorial.tutorial-active-one .modal {
        top: 87%;
        left: -1%;
        padding-top: 15px;
        height: 22%;
    }
}
@media (max-width: 375px) {
    .container-cards-tutorial.tutorial-active-one .modal {
        top: 78%;
        width: 350px;
        left: -1%;
    }
}
@media (max-width: 320px) {
    .container-cards-tutorial.tutorial-active-one .modal {
        left: -3%;
        width: 300px;
    }
}
.container-cards-tutorial.tutorial-active-one .modal-dialog {
    margin: 120px 0 0 20px;
    max-width: 390px;
}
.container-cards-tutorial.tutorial-active-one .modal-content::after {
    content: "";
    position: absolute;
    top: -27px;
    left: 48%;
    margin-left: -3px;
    border-width: 15px;
    border-style: solid;
    border-color: transparent transparent white transparent;
}
.container-cards-tutorial.tutorial-active-one .gold-tutorial, 
.container-cards-tutorial.tutorial-active-one .platinum-tutorial,
.container-cards-tutorial.tutorial-active-one .premier-tutorial {
    z-index: 1050;
}
.container-cards-tutorial.tutorial-active-one .gold-tutorial .card-white, 
.container-cards-tutorial.tutorial-active-one .platinum-tutorial .card-white,
.container-cards-tutorial.tutorial-active-one .premier-tutorial .card-white {
    background: white;
}
.container-cards-tutorial.tutorial-active-two .modal {
    position: absolute;
    top: 449px;
    left: 33%;
    width: 470px;
    height: 47%;
}
@media (max-width: 1675px) {
    .container-cards-tutorial.tutorial-active-two .modal {
        top: 404px;
        left: 30%;
    }
}
@media (max-width: 1539px) {
    .container-cards-tutorial.tutorial-active-two .modal {
        top: 346px;
        left: 26%;
    }
}
@media (max-width: 1280px) and (max-height: 660px) {
    .container-cards-tutorial.tutorial-active-two .modal {
        top: 331px;
        left: 23%;
    }
}
@media (max-width: 1024px) {
    .container-cards-tutorial.tutorial-active-two .modal {
        top: 284px;
        left: 18%;
    }
}
@media (max-width: 768px) {
    .container-cards-tutorial.tutorial-active-two .modal {
        height: 65%;
    }
}
@media (max-width: 414px) {
    .container-cards-tutorial.tutorial-active-two .modal {
        top: 61%;
        left: -1%;
        padding-top: 15px;
        height: 20%;
    }
}
@media (max-width: 375px) {
    .container-cards-tutorial.tutorial-active-two .modal {
        top: 56%;
        width: 350px;
        left: -1%;
    }
}
@media (max-width: 320px) {
    .container-cards-tutorial.tutorial-active-two .modal {
        left: -3%;
        width: 300px;
    }
}
.container-cards-tutorial.tutorial-active-two .modal-dialog {
    margin: 120px 0 0 20px;
    max-width: 390px;
}
.container-cards-tutorial.tutorial-active-two .modal-content::after {
    content: "";
    position: absolute;
    top: -27px;
    left: 48%;
    margin-left: -3px;
    border-width: 15px;
    border-style: solid;
    border-color: transparent transparent white transparent;
}
.container-cards-tutorial.tutorial-active-two .platinum-tutorial {
    z-index: 1050;
}
.container-cards-tutorial.tutorial-active-two .platinum-tutorial .card-white {
    background: white;
}
.container-cards-tutorial.tutorial-active-three .modal {
    position: absolute;
    top: 13%;
    left: 54%;
    width: 470px;
    height: 78%;
}
@media (max-width: 1600px) {
    .container-cards-tutorial.tutorial-active-three .modal {
        top: 11%;
    }
}
@media (max-width: 1434px) {
    .container-cards-tutorial.tutorial-active-three .modal {
        top: 8%;
    }
}
@media (max-width: 1024px) {
    .container-cards-tutorial.tutorial-active-three .modal {
        top: 6%;
        left: 35%;
    }
}
@media (max-width: 414px) {
    .container-cards-tutorial.tutorial-active-three .modal {
        top: 67%;
        left: -1%;
        padding-top: 15px;
    }
}
@media (max-width: 375px) {
    .container-cards-tutorial.tutorial-active-three .modal {
        left: -1%;
        top: 60%;
        width: 350px;
        height: 90%;
    }
}
@media (max-width: 320px) {
    .container-cards-tutorial.tutorial-active-three .modal {
        top: 56%;
        width: 300px;
        left: -3%;
    }
}
.container-cards-tutorial.tutorial-active-three .modal-dialog {
    margin: 120px 0 0 20px;
    max-width: 390px;
}
.container-cards-tutorial.tutorial-active-three .modal-content::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 48%;
    margin-left: -3px;
    border-width: 15px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}
.container-cards-tutorial.tutorial-active-three .table-tutorial {
    z-index: 1050;
    background: white;
}
.modal-tutorial-questions.tutorial-active-three {
    position: absolute;
    top: 75%;
    left: 28%;
    width: 370px;
}
@media (max-width: 1556px) and (max-height: 828px) {
    .modal-tutorial-questions.tutorial-active-three {
        top: 84%;
    }
}
@media (max-width: 1390px) {
    .modal-tutorial-questions.tutorial-active-three {
        top: 110%;
    }
}
@media (max-width: 1360px) and (max-height: 657px) {
    .modal-tutorial-questions.tutorial-active-three {
        top: 110%;
    }
}
@media (max-width: 1280px) and (max-height: 660px) {
    .modal-tutorial-questions.tutorial-active-three {
        top: 119%;
    }
}
@media (max-width: 1024px) {
    .modal-tutorial-questions.tutorial-active-three {
        top: 53%;
    }
}
@media (max-width: 768px) {
    .modal-tutorial-questions.tutorial-active-three {
        top: 7%;
        left: 9%;
    }
}
@media (max-width: 414px) {
    .modal-tutorial-questions.tutorial-active-three {
        top: 28%;
        padding-top: 15px;
    }
}
@media (max-width: 375px) {
    .modal-tutorial-questions.tutorial-active-three {
        top: 25%;
        left: 2%;
    }
}
@media (max-width: 320px) {
    .modal-tutorial-questions.tutorial-active-three {
        width: 300px;
        top: 35%;
    }
}
.modal-tutorial-questions.tutorial-active-three .modal-dialog {
    margin: 120px 0 200px 20px;
    max-width: 350px;
}
.modal-tutorial-questions.tutorial-active-three .modal-content::after {
    content: "";
    position: absolute;
    top: 50px;
    left: -6%;
    margin-left: -3px;
    border-width: 12px;
    border-style: solid;
    border-color: transparent white transparent transparent;
}
@media (max-width: 768px) {
    .modal-tutorial-questions.tutorial-active-three .modal-content::after {
        top: -23px;
        left: 48%;
        border-color: transparent transparent white transparent;
    }
}

/*----------------------------------------------------
*               Buys-points
* color-horizon: #6C93AC
* color-fiord: #020C41
----------------------------------------------------*/
.buy-points {
    width: 90%;
    margin: 0 auto;
    padding-left: 15px;
}
@media (max-width: 414px) {
    .buy-points {
        width: 100%;
        margin: 0;
        padding: 15px;
        padding: 0;
    }
}
.buy-points .row {
    margin: 0;
}
.buy-points .row-exception {
    margin-left: -15px;
    margin-right: -15px;
}
.buy-points .icon-dashboard {
    display: none;
}
@media (max-width: 768px) {
    .buy-points .icon-dashboard {
        display: inline-block;
    }
    .buy-points .btn {
        padding: 0.5rem;
    }
}
.buy-points .btn-outline-primary {
    border-width: 1px;
}
.buy-points  h4 {
    font-weight: 600;
}
.buy-points .card-basic {
    flex-direction: initial;
}
.buy-points .card-white {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.buy-points .cards-type-member .card-white {
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
}
.buy-points .input-group .form-control {
    color: #020C41;
    border-color: #aaaaaa;
}
.buy-points .input-group .form-control::placeholder {
    color: #D6D6D6;
    font-size: 12px;
}
.buy-points form .input-group {
    display: block;
}
.buy-points form.buy-points-form .custom-select {
    height: 38px;
    margin-bottom: 0 !important;
    padding-bottom: 0;
    padding-left: 2px;
}
.buy-points .input-group .input-group-append {
    top: 2rem;
    color: #020C41;
}
.buy-points .input-group .input-group-append .fa-angle-down {
    font-size: 20px;
    color: #020C41;
}
.buy-points .input-group-append .input-group-text {
    padding: 5px;
}
.buy-points .panel-info {
    background-color: #0000E2;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
}
.buy-points .panel-info.variant {
    background-color: #020C41;
}
.buy-points .panel-info .text-points {
    margin-top: 60%;
}
.buy-points .panel-info .text-points span {
    font-size: 16px;
}
.buy-points .panel-info .text-points img {
    position: absolute;
    right: 5%;
    bottom: 5%;
}.buy-points .input-group-append .input-group-text {
    padding: 5px;
}
.buy-points .panel-info {
    background-color: #0000E2;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
}
.buy-points .panel-info.variant {
    background-color: #020C41;
}
.buy-points .panel-info .text-points {
    margin-top: 60%;
}
.buy-points .panel-info .text-points span {
    font-size: 16px;
}
.buy-points .panel-info .text-points img {
    position: absolute;
    right: 5%;
    bottom: 5%;
}

/****************************************************
*****************************************************
*            FAQ
*****************************************************
****************************************************/
.faq-view .card-body a {
    font-weight: 400;
}

.faq-view .accordion {
    max-width: 1000px;
    margin: 0px auto;
}

.faq-view ul, 
.faq-view ol {
    padding-left: 20px !important;
}

.faq-view ol li::before,
.faq-view ul li::before {
    color: #444444;
    position: relative;
	top: -3px;
    font-size: 5px;
}

/* !important because it's not possible to override without using it */
.faq-view .accordion .card{
    border-bottom: none !important;
}
.faq-view .accordion .card-header.collapsed {
    border-right: 2px solid #DDDDDD !important;
    border-left: 2px solid #DDDDDD !important;
}
.faq-view .accordion .card-header,
.faq-view .accordion .collapse.show {
    border-right: 2px solid #DDDDDD !important;
    border-left: 2px solid #DDDDDD !important;
}
.faq-view .accordion-container .card-header {
    cursor: pointer;
}
.faq-view .accordion:last-child .card{
    border-bottom: 2px solid #DDDDDD !important;
}

/*----------------------------------------------------
*            Recovery-points
* color-horizon: #6C93AC
* color-fiord: #020C41
----------------------------------------------------*/
.recovery-points {
    width: 90%;
    margin: 0 auto;
    padding-left: 15px;
}
@media (max-width: 414px) {
    .recovery-points {
        width: 100%;
        margin: 0;
        padding: 15px;
        padding: 0;
    }
}
.recovery-points .row {
    margin: 0;
}
.recovery-points .row-exception {
    margin-left: -15px;
    margin-right: -15px;
}
.recovery-points .icon-dashboard {
    display: none;
}
@media (max-width: 768px) {
    .recovery-points .icon-dashboard {
        display: inline-block;
    }
    .recovery-points .btn {
        padding: 0.5rem;
    }
}
.recovery-points .btn-outline-primary {
    border-width: 1px;
}
.recovery-points h4 {
    font-weight: 600;
}
.recovery-points .card-basic {
    flex-direction: initial;
}
.recovery-points .card-white {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.recovery-points .cards-type-member .card-white {
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
}
.recovery-points .input-group .form-control {
    color: #020C41;
    border-color: #aaaaaa;
}
.recovery-points .input-group .form-control::placeholder {
    color: #D6D6D6;
    font-size: 12px;
}
.recovery-points form .input-group {
    display: block;
}
.recovery-points form.recovery-points-form .custom-select {
    height: 38px;
    margin-bottom: 0 !important;
    padding-bottom: 0;
    padding-left: 2px;
}
.recovery-points .input-group .input-group-append {
    top: 2rem;
    color: #020C41;
}
.recovery-points .input-group .input-group-append .fa-angle-down {
    font-size: 20px;
    color: #020C41;
}
.recovery-points .input-group-append .input-group-text {
    padding: 5px;
}
.recovery-points .panel-info {
    background-color: #0000E2;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
}
.recovery-points .panel-info.variant {
    background-color: #020C41;
}
.recovery-points .panel-info .text-points {
    margin-top: 60%;
}
.recovery-points .panel-info .text-points span {
    font-size: 16px;
}
.recovery-points .panel-info .text-points img {
    position: absolute;
    right: 5%;
    bottom: 5%;
} 


/****************************************************
*****************************************************
*            Upgrades (Dashboard & Ancilliaries
*****************************************************
****************************************************/
/* Main classes */
.upgrades {
    width: 90%;
    margin: 0;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}
@media (max-width: 991px) {
    .upgrades {
        width: auto;
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 0px;
        margin-right: 0px;
    }
}
.upgrades .container-sidebar-homem {
    padding-right: 0px;
}

.upgrades .container-sidebar-homem.show {
    padding-left: 30px;
	padding-right: 30px;
	max-width: 90%;
	margin-left: auto;
	margin-right: auto;
}

.upgrades .sidebar .row {
    margin: 0px;
}

.upgrades .has-loader {
    position: relative;
}

.upgrades .loader {
    background: rgba(255, 255, 255, 0.75);
    position: absolute;
    top: 0px;
	bottom: 0px;
	right: 0px;
	left: 0px;
	z-index: 10;
}
.upgrades .loader .fa-spin {
    font-size: 16px;
}

.upgrades .main-title {
    background-color: #fff;
    padding-top: 30px;
    padding-left: 30px;
}

.upgrades-container h4 {
    cursor: pointer;
}
.upgrades-container .card-body p {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 10px;
}

.upgrades-container p {
    font-size: 18px;
    line-height: 25px;
}

@media (max-width: 767px) {
    .upgrades-container p {
        font-size: 16px;
        line-height: 18px;
    }
}
.upgrades td {
    padding-right: 7px;
}

.upgrades .breadcrumbs {
    position: relative;
    height: 120px;
    max-width: 680px;
    margin: 0px auto 60px;
    padding-top: 45px;
}
@media (max-width: 767px) {
    .upgrades .breadcrumbs {
        max-width: initial;
    }
    .upgrades .step1 .breadcrumbs {
        margin-bottom: 0px;
    }
}

.upgrades .step2 .breadcrumbs,
.upgrades .step3 .breadcrumbs {
    margin-top: 0px;
}

.upgrades .breadcrumbs .hr,
.upgrades .breadcrumbs .crumbs-container {
    position: absolute;
    right: 0px;
    left: 0px;
}

.upgrades .breadcrumbs .hr {
    height: 5px;
    background-color: #d0d0d0;
    top: calc(74% - 5px);
    max-width: 83%;
    margin: 0px auto;
}
@media (max-width: 767px) {
    .upgrades .breadcrumbs .hr {
        display: none
    }
}

.upgrades .breadcrumbs .crumbs-container {
    height: 100%;
    display: flex;
    justify-content: space-between;
}
@media (max-width: 767px) {
    .upgrades .breadcrumbs .crumbs-container {
        display: block;
        position: relative;
    }
}

.upgrades .breadcrumbs .crumb {
    min-width: 120px;
}

@media (max-width: 767px) {
    .upgrades .breadcrumbs .crumb {
        background-color: #fff;
        display: none;
        position: absolute;
        top: 0;
        left: calc(50% - 60px)
    }
    .upgrades .breadcrumbs .crumb.-active {
        display: block;
    }
}

.upgrades .breadcrumbs .crumb .number {
    background-color: #d8d8d8;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 0.4px;
    color: #fff;
    margin: 15px auto;
}
.upgrades .breadcrumbs .crumb.-active .number{
    background-color: #020C41;
}
.upgrades .breadcrumbs .crumb .label {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.45px;
    color: #A2A2A2;
}
.upgrades .breadcrumbs .crumb.-active .label{
    color: #444;
}

/* Dashboard step0 */
.upgrades .step0 {
    padding-left: 15px;
    padding-right: 15px;
}
.upgrades-container .dashboard-card {
    position: relative;
    width: 390px;
    height: 630px;
    margin-right: 17px;
    margin-bottom: 39px;
    float: left;
    background: #fff;
    border: 1px solid #ddd;
    box-sizing: border-box;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}
@media (max-width: 767px) {
    /* We setup here the properties for mobile cards instead of using the .-mobile 
    class modifier because we use js to create them so it's easier to handle one 
    type of card in the js code rather than two different templates and cards */
    .upgrades-container .dashboard-card {
        width: auto;
        height: 545px;
        margin-right: 0px;
        margin-bottom: 0px;
        padding-left: 30px;
        padding-right: 30px;
        float: none;
    }
}

.dashboard-grid {
    min-height: 500px;
}

.dashboard-grid.-mobile {
    margin-top: 35px;
}

.dashboard-grid.-mobile .grid-controls {
    flex-wrap: nowrap;
    overflow-y: auto;
    margin: auto;
    margin-bottom: 45px;
    max-width: 90vw;
}
.dashboard-grid.-mobile .grid-controls .nav {
    padding: 10px 0;
}
.dashboard-grid.-mobile .grid-controls .nav:not(:last-child) {
    margin-right: 15px;
}
.dashboard-grid.-mobile .grid-controls .btn-outline-primary:hover {
    border-width: 1px;
}
.dashboard-grid.-mobile .carousel-control-prev,
.dashboard-grid.-mobile .carousel-control-next {
    height: 32px;
    width: 18px;
    padding: 10px 7px;
    border-radius: 5px;
    background-color: #020C41;
    mix-blend-mode: normal;
    opacity: 0.7;
    top: 49%;
    width: auto;
}
.dashboard-grid.-mobile .carousel-control-prev{
    left: -10px;
}
.dashboard-grid.-mobile .carousel-control-next{
    right: -10px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    width: 14px;
    height: 14px;
    opacity: 0.7;
}

.dashboard-card .image,
.dashboard-card .title,
.dashboard-card .text,
.dashboard-card .button {
    width: 329px;
    margin-left: auto;
    margin-right: auto;
}
@media (max-width: 767px) {
    .dashboard-card .image,
    .dashboard-card .title,
    .dashboard-card .text,
    .dashboard-card .button {
        width: auto;
    }
}

.dashboard-card .image{
    display: flex;
    justify-content: center;
    height: 178px;
    margin-top: 35px;
    margin-bottom: 35px;
}

.dashboard-card .title{
    margin-top: 0px;
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
}
@media (max-width: 767px) {
    .dashboard-card .title {
        margin-top: 0px;
        margin-bottom: 30px;
    }
}

.dashboard-card .title::after {
    content: " ";
    display: block;
    border-bottom: 5px solid #020C41;
    width: 80px;
    height: px;
}
@media (max-width: 767px) {
    .dashboard-card .title::after {
        height: 25px;
    }
}

.dashboard-card .button {
    position: absolute;
    bottom: 30px;
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    border-radius: 1.875rem;
    background-color: transparent;
    border: 1px solid #030d75;
    padding: 0.7rem 1.7rem;
    font-weight: 400;
    width: 182px;
}
.dashboard-card .button .fas{
    margin-right: 6px;
	padding: 0;
}
.dashboard-card .button:hover {
    text-decoration: none;
    font-weight: normal;
}
@media (max-width: 767px) {
    .dashboard-card .button{
        bottom: 40px;
    }
}

.upgrades .messages .alert {
    margin: 50px auto;
}
.upgrades .messages .alert-danger button span,
.upgrades .messages .alert-danger button span:hover {
    color: #CA0F0F;
}
.upgrades .messages .alert button:focus,
.upgrades .messages .alert button:focus-visible{
    outline: none;
}
.upgrades .messages .alert h2 {
    font-size: 21px;
    line-height: 28px;
}

.upgrades .messages .alert .fa {
    margin-right: 10px !important
}
.upgrades .messages .alert .fa-times-circle:before {
    font-size: 20px !important;
    line-height: 28px !important;
}
.upgrades .messages .alert .fa-exclamation-triangle:before {
    font-size: 17px !important;
    line-height: 28px !important;
    position: relative;
    top: -2px;
}
.upgrades .messages .alert p {
    font-size: 18px;
    line-height: 25px;
}
.upgrades .messages .alert-danger button,
.upgrades .messages .alert-danger h2 {
    color: #CA0F0F;
}
.upgrades .messages .alert-warning button,
.upgrades .messages .alert-warning h2 {
    color: #FFC845;
}

/* step1 - Flight data form */
.upgrades [class^='step'] {
    width: 100%;
    box-sizing: border-box;
}
.upgrades [class^='step'] .header {
    width: 100%;
    padding: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
}
@media (max-width: 767px) {
    .upgrades [class^='step'] .header {
        justify-content: space-evenly;
        flex-direction: column;
        padding: 60px 15px 30px;
    }
    .upgrades [class^='step'] .header h1 {
        margin-top: 22px;
        text-align: center;
    }
}
.upgrades [class^='step'] .header h1 {
    max-width: 530px;
}
.upgrades .step1 {
    background-color: #fff;
}
.upgrades .step1 .form-cont {
    padding-top: 60px;
    padding-bottom: 60px;
    width: 100%;
    background-color: #f8f8f8;
}
@media (max-width: 767px) {
    .upgrades .step1 .form-cont {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.upgrades .step1 .form-cont h4 {
    color: #020C41;
    cursor: auto;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto
}   

.upgrades .step1 .form-flight {
    margin-left: auto;
    margin-right: auto;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    max-width: 525px;
    background-color: #fff;
}

.upgrades .step1 .form-flight p {
    color: #020C41;
}

.upgrades .step1 .form-flight label {
    color: #aaa;
}

@media (max-width: 767px) {
    .upgrades .step1 .form-flight .btn-primary {
        height: 40px;
        font-size: 12px;
        line-height: 14px;
        font-weight: normal;
    }
}

/* step2 - Search result tables */

.upgrades .step2 {
    background-color: #fff;
    padding-left: 15px;
    padding-right: 15px;
}
@media (max-width: 767px){
    .upgrades .step2 {
        background-color: #fff;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.upgrades .step2 h3.title,
.upgrades .step3 h3.title {
    font-size: 30px;
    line-height: 42px;
}

.upgrades .step2 p.subtitle {
    padding-right: 3rem;
    padding-left: 3rem;
}
@media (max-width: 700px){
    .upgrades .step2 p.subtitle {
        padding-right: 0rem;
        padding-left: 0rem;
    }
}

.upgrades .step2 .messages > div {
    margin-top: 40px;
}

.upgrades .flights,
.upgrades .upgrades {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    padding: 30px;
    max-width: 940px;
}
.upgrades .flights .-label {
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 15px;
    color: #496C82;
    font-weight: 400;
}
.upgrades .flights .-content {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 15px;
    color: #444;
}

.upgrades .flights .info {
    border-bottom: 1px solid #ddd;
    margin-bottom: 25px;
}

.upgrades .flights .index {
    color: #496c82;
}
.upgrades .flights .fa-plane {
    font-size: 13px;
	padding: 0;
	margin-right: 7px;
}
.upgrades .flights .single-flight {
    border-bottom: 1px solid #d0d0d0;
    margin-bottom: 25px;
}
.upgrades .flights .single-flight:last-child {
    margin-bottom: 0px;
}

.upgrades .flights .details {
    width: 100%;
}
@media (max-width: 700px){
    .upgrades .flights .details {
        flex-direction: column;
    } 
}
.upgrades .flights .airports,
.upgrades .flights .times {
    box-sizing: border-box;
}
.upgrades .flights .airports {
    flex-basis: 67%;
    border-right: 1px solid #d0d0d0;
    margin-right: 20px;
}
@media (max-width: 700px){
    .upgrades .flights .airports {
        border-right: 0px;
        border-bottom: 1px solid #d0d0d0;
        padding-bottom: 20px;
        margin-bottom: 15px;
    } 
}
.upgrades .flights .airports table {
    width: 100%;
    color: #496C82;
}
.upgrades .flights .airports td {
    vertical-align: top;
}
.upgrades .flights .airports .hours {
    font-size: 14px;
    line-height: 15px;
}
.upgrades .flights .airports .codes {
    font-size: 38px;
    line-height: 48px;
}
.upgrades .flights .airports .cities {
    font-size: 14px;
    line-height: 15px;
    color: #737373;
}
.upgrades .flights .airports .origins, 
.upgrades .flights .airports .destinations {
    width: 50%;
}
.upgrades .flights .conditions {
    flex-basis: 33%;  
}

.upgrades .operator {
    font-size: 18px;
    line-height: 25px;
    color: #737373;
}
@media (max-width: 700px){
    .upgrades .operator {
        margin-top: 25px;
    }
}

.upgrades .passengers {
    width: 100%;
}
.upgrades .passengers .col-names {
    width: 50%;
}
.upgrades .passengers .col-seats {
    width: 15%;
}
.upgrades .passengers .col-emds {
    width: 35%;
}
.upgrades .passengers th,
.upgrades .passengers td {
    vertical-align: top;
}
.upgrades .passengers th {
    padding-bottom: 20px;
}

.upgrades .passengers tbody td {
    font-size: 18px;
    line-height: 25px;
    padding-bottom: 20px;
}
@media (max-width: 700px){
    .upgrades .passengers tbody td {
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.6px;
        padding-bottom: 18px;
    }
}

.upgrades .passengers .name {
    padding-right: 7px;
}

.upgrades .passengers .name i,
.upgrades .passengers .seat i,
.upgrades .passengers .emd i {
    color: #d0d0d0;
    margin-right: 8px;
}
.upgrades .passengers .seat,
.upgrades .passengers .emd {
    color: #496C82;
}
.upgrades .passengers .seat.-unassigned,
.upgrades .passengers .emd.-unassigned {
    color: #A2A2A2;
}

/* Point data for step 2 */
.upgrades .point-data {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    max-width: 940px;
    flex-direction: column;
}
@media (max-width: 575px){
    .upgrades .point-data {
        flex-direction: row;
    }
}
.upgrades .point-data .data,
.upgrades .point-data .headers {
    box-sizing: border-box;
    width: 100%;
}
@media (max-width: 575px){
    .upgrades .point-data .data,
    .upgrades .point-data .headers {
        flex-direction: column;
    }
}
.upgrades .point-data .data{
    background-color: #fff;
}

.upgrades .point-data .headers {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
}

.upgrades .point-data .headers div,
.upgrades .point-data .data div {
    box-sizing: border-box;
    border-style: solid;
    border-width: 0px;
    flex-grow: 1;
    flex-basis: 25%;
    padding: 15px;
}
.upgrades .point-data .headers div {
    background-color: #00295E;
}
@media (max-width: 575px){
    .upgrades .point-data .headers div {
        font-size: 12px;
        line-height: 15px;
    }
}
.upgrades .point-data .headers div:first-child {
    border-top-left-radius: 5px;
}
.upgrades .point-data .headers div:last-child {
    border-top-right-radius: 5px;
}
@media (max-width: 575px){
    .upgrades .point-data .headers div:last-child {
        border-top-right-radius: 0px;
        border-bottom-left-radius: 5px;
    }
}
.upgrades .point-data .data div {
    border-color: #ddd;
    border-bottom-width: 1px;
    color: #444;
    font-size: 14px;
    line-height: 15px;
}
@media (max-width: 575px){
    .upgrades .point-data .data {
        font-size: 14px;
        line-height: 15px;
    }
}
.upgrades .point-data .data div:first-child {
    border-bottom-left-radius: 5px;
    border-left-width: 1px;
}
.upgrades .point-data .data div:last-child {
    border-bottom-right-radius: 5px;
    border-right-width: 1px;
}
@media (max-width: 575px){
    .upgrades .point-data .data div {
        border-right-width: 1px;
    }
    .upgrades .point-data .data div:first-child {
        border-bottom-left-radius: 0px;
        border-top-right-radius: 5px;
        border-left-width: 0px;
        border-top-width: 1px;
    }
    .upgrades .point-data .data div:last-child {
        border-bottom-right-radius: 5px;
        border-right-width: 1px;
    }
}

/* upgrades list for step 2 and 3 */
.upgrades .upgrades {
    background-color: #fff;
}
@media (max-width: 700px){
    .upgrades .upgrades {
        margin-top: 35px;
        padding: 17px;
    }
}
.upgrades .upgrade-list .upgrade{
    display: flex;
    border-bottom: 1px solid #aaa;
	margin-bottom: 15px;
}
@media (max-width: 700px){
    .upgrades .upgrade-list .upgrade{
        flex-direction: column;
        margin-bottom: 25px;
    }
}

.upgrades .upgrade-list .upgrade:not(:first-child) .details .title,
.upgrades .upgrade-list .upgrade:not(:first-child) .points .title {
    display: none
}
@media (max-width: 700px){
    .upgrades .upgrade-list .upgrade:not(:first-child) .details .title,
    .upgrades .upgrade-list .upgrade:not(:first-child) .points .title {
        display: block;
    }
}

.upgrades .upgrade-list .details{
    flex-basis: 70%;
}
@media (max-width: 700px){
    .upgrades .upgrade-list .details{
        margin-bottom: 20px;
    }
}
.upgrades .upgrade-list .points {
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
}

.upgrades .upgrade-list .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.45px;
    color: #496C82;
    padding-bottom: 15px;
    border-bottom: 1px solid #aaa;
    margin-bottom: 15px;
}
@media (max-width: 700px){
    .upgrades .upgrade-list .title {
        border-bottom-width: 0px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.45px;
        padding-bottom: 0px;
    }
}

.upgrades .upgrade-list .flight {
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.4px;
    color: #6C93AC;
    margin-bottom: 15px;
    padding-right: 10px;
}
.upgrades .upgrade-list .passenger {
    color: #444;
    margin-bottom: 15px;
}
@media (max-width: 700px){
    .upgrades .upgrade-list .flight,
    .upgrades .upgrade-list .passenger {
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.4px
    }
}
.upgrades .upgrade-list .cost {
    flex-grow: 1;
	position: relative;
}
@media (max-width: 700px){
    .upgrades .upgrade-list .cost {
        margin-bottom: 10px;
    }
}

.upgrades .upgrade-list .cost .custom-control{
    position: relative;
    top: 50%;
}
.upgrades .upgrade-list .cost .custom-control.-no-box{
    padding-left: 0px;
}
.upgrades .upgrade-list .cost .custom-control.-no-box label::before,
.upgrades .upgrade-list .cost .custom-control.-no-box label::after{
    display: none;
}

.upgrades .upgrades .totals {
    display: flex;
    font-weight: 500;
    font-size: 21px;
    line-height: 28px;
    letter-spacing: 0.39375px;
    color: #00295E;
}
@media (max-width: 700px){
    .upgrades .upgrades .totals {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.39375px;
    }
}


.upgrades .upgrades .totals .title {
    flex-basis: 70%;
}
 .total {
    flex-basis: 30%;
}
@media (max-width: 700px){
    .upgrades .upgrades .totals .title,
    .upgrades .upgrades .totals .total {
        flex-basis: 50%;
    }
    .upgrades .upgrades .totals .total {
        text-align: right;
    }
}

.upgrades .upgrades-buttons,
.upgrades .purchase-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
}
@media (max-width: 575px){
    .upgrades .purchase-buttons {
        padding-bottom: 0px;
    }
}
.upgrades .upgrades-buttons button,
.upgrades .purchase-buttons button {
    height: 40px;
	font-size: 16px;
	line-height: 19px;
    padding-top: 0px;
	padding-bottom: 0px;
    min-width: 220px;
}
.upgrades .upgrades-buttons button:first-child,
.upgrades .purchase-buttons button:first-child {
    margin-right: 25px;
}
@media (max-width: 575px){
    .upgrades .upgrades-buttons,
    .upgrades .purchase-buttons {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    .upgrades .upgrades-buttons button,
    .upgrades .purchase-buttons button {
        width: calc(100% - 30px);
        margin-right: auto;
        margin-left: auto;
    }
    .upgrades .upgrades-buttons button:first-child,
    .upgrades .purchase-buttons button:first-child {
        margin-right: auto;
        margin-bottom: 0px;
    }
    .upgrades .upgrades-buttons button:last-child,
    .upgrades .purchase-buttons button:last-child {
        margin-bottom: 25px;
    }
}

/** step3 - review info **/
.upgrades .step3 {
    background-color: #fff;
}
@media (max-width: 767px){
    .upgrades .step3 {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.upgrades .step3 .purchase-review {
    background-color: #f8f8f8;
    padding-top: 40px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 0px;
    margin-right: 0px;
}
@media (max-width: 767px){
    .upgrades .step3 .purchase-review {
        padding-bottom: 50px;
        margin-left: -15px;
        margin-right: -15px;
    }
}


.upgrades .step3 .header h1 span {
    display: block;
}
.upgrades .step3 .header h1 span:last-child {
    font-weight: 400;
}

.upgrades .step3 h3.title {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.4px;
    color: #444444;
}
.upgrades .step3 p.subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.4px;
    color: #444444;
}

.upgrades .terms-validation {
    font-size: 18px;
    line-height: 21px;
}

.upgrades .terms-validation a, 
.upgrades .terms-validation a:hover {
    text-decoration: none;
    font-size: inherit;
    color: #0000E3;
    font-weight: normal;
}
.upgrades .terms-validation .custom-control-label {
    margin-bottom: 0;
}

/** step4 - purchase confirmation **/
.upgrades .step4 {
    background-color: #fff;
}
.upgrades .step4 .header {
    width: 100%;
    padding: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
}
@media (max-width: 767px) {
    .upgrades .step4 .header {
        justify-content: space-evenly;
        flex-direction: column;
        padding: 60px 15px 30px;
        width: auto;
        margin-left: -15px;
        margin-right: -15px;
        /* padding-top: 50px;
        padding */
    }
}
.upgrades .step4 .header h1 {
    max-width: 530px;
}
@media (max-width: 767px) {
    .upgrades .step4 .header h1 {
        margin-top: 22px;
        text-align: center;
    }
}

.upgrades .step4 h3.title {
    font-weight: 500;
    font-size: 30px;
    line-height: 42px;
    letter-spacing: 0.45px;
    text-align: center;
}
.upgrades .step4 p.subtitle {
    max-width: 950px;
    padding-right: 3rem;
    padding-left: 3rem;
}
@media (max-width: 700px){
    .upgrades .step4 p.subtitle {
        padding-right: 0rem;
        padding-left: 0rem;
    }
}

.upgrades .final-confirmation {
    padding-left: 15px;
    padding-right: 15px;
    background-color: #f8f8f8;
}

.upgrades .purchase-confirmation {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    padding: 35px;
    border-radius: 5px;
    max-width: 940px;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.upgrades .flight-airports {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.45px;
    color: #496C82;
    margin-bottom: 35px;
}
.upgrades .flight-airports span {
    color: #444;
}

.upgrades .general-data  {
    display: flex;
    margin-bottom: 25px;
}
.upgrades .general-data .departure-data {
    flex-basis: 40%;
}
.upgrades .general-data .time-data,
.upgrades .general-data .class-data {
    flex-basis: 30%;
}
.upgrades .general-data .time-data i,
.upgrades .general-data .departure-data i{
    color: #d0d0d0;
    margin-right: 5px;
}
.upgrades .general-data .title {
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.4px;
    margin-bottom: 15px;
    color: #496C82;
}
.upgrades .general-data .data {
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.4px;
    color: #444444;
}
.upgrades .general-data .data.-highlight {
    color: #FF8200;
}
.upgrades .single-purchase  {
    border-bottom: 1px solid #ddd;
    margin-bottom: 25px;
}
.upgrades .single-purchase:last-child {
    border-bottom-width: 0px;
    margin-bottom: 0px;
}
.upgrades .single-purchase .passengers {
    border-bottom-width: 0;
}
.upgrades .select-seats .btn-primary {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    margin-left: auto;
    margin-right: auto;
}
@media (max-width: 767px){
    .upgrades .select-seats .btn-primary {
        height: 40px;
    }
}
@media (max-width: 575px){
    .upgrades .select-seats .btn-primary {
        width: auto;
    }
}

.upgrades .select-seats .link {
    margin-bottom: 60px;
}
.upgrades .select-seats .link p {
  color: #00295E;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: 0.4px;
}
.upgrades .select-seats .link a {
  font-size: 24px;
}
.upgrades .select-seats .link p a,
.upgrades .select-seats .link p a:hover {
    color: #00295E;
    font-weight: inherit;
}
@media (max-width: 767px){
    .upgrades .select-seats .link {
        margin-bottom: 30px;
    }
    .upgrades .select-seats .link p,
    .upgrades .select-seats .link a {
        font-size: 16px;
        line-height: 23px;
    }
}


.upgrades .step5 {
    background-color: #fff;
}
.upgrades .step5 .header {
    width: 100%;
    padding: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
}
@media (max-width: 767px) {
    .upgrades .step5 .header {
        justify-content: space-evenly;
        flex-direction: column;
        padding: 60px 15px 30px;
    }
}
.upgrades .step5 .header h1 {
    max-width: 530px;
}
@media (max-width: 767px) {
    .upgrades .step5 .header h1 {
        margin-top: 22px;
        text-align: center;
    }
}
.upgrades .step5 h3.title {
    font-weight: 500;
    font-size: 30px;
    line-height: 42px;
    text-align: center;
    letter-spacing: 0.45px;
}
.upgrades .step5 p.subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.4px;
    color: #444444;
    max-width: 950px;
    padding-right: 3rem;
    padding-left: 3rem;
    margin-left: auto;
    margin-right: auto;
}
@media (max-width: 700px){
    .upgrades .step5 p.subtitle {
        padding-right: 0rem;
        padding-left: 0rem;
    }
}

.upgrades .start-over {
    margin-bottom: 30px;
}

.upgrades .start-over a {
    max-width: 220px;
    margin: 0px auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: 767px){
    .upgrades .start-over .btn-primary {
        height: 40px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
    }
}
@media (max-width: 575px){
    .upgrades .start-over .btn-primary {
        width: auto;
    }
}

/** Terms and conditions div **/
.upgrades-container .terms {
    clear: both;
    background-color: #fff;

    font-size: 14px;
    line-height: 1.428571429;
    color: #333;
}

.upgrades-container .-bg-alt {
    background-color: #f8f8f8;
}

.upgrades-container .terms.-pt {
    padding-top: 60px;
}
@media (max-width: 700px){
    .upgrades-container .terms {
        clear: both;
        background-color: #fff;
    }

    .upgrades-container .terms.-bg-alt {
        background-color: #f8f8f8;
    }
}

.upgrades-container .terms .card-body p {
    text-align: justify;
    margin: 0 0 10px;
}

.upgrades-container .terms .card-body p,
.upgrades-container .terms .card-body a,
.upgrades-container .terms .card-body b,
.upgrades-container .terms .card-body ul,
.upgrades-container .terms .card-body ul li {
    text-align: justify;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
}

.upgrades-container .terms .card-body a,
.upgrades-container .terms .card-body a:hover {
    color: #337ab7;
    font-weight: 400;
}
.accordion-link {
    font-weight: 400;
    padding: 15px 0;
    position: relative;
    white-space: normal;
    background-color: transparent;
    line-height: 1.2;
    color: #425563;
}
.accordion-link::after {
    content: '\f078';
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    height: 20px;
    position: absolute;
    right: -15%;
    bottom: 20%;
}
.accordion-link[aria-expanded="true"]::after {
    content: '\f077';
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    height: 20px;
    position: absolute;
    right: -15%;
    bottom: 20%;
  }

/****************************************************
*****************************************************
*            CMS POINT CALCULATOR
*****************************************************
****************************************************/
.point-calculator-cms .calculator-tabs {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.point-calculator-cms .calculator-tab {
  flex-basis: 50%;
  border: 0;
  border-bottom: 1px solid #81A0B2;
  cursor: pointer;
  height: 55px;
}
.point-calculator-cms .calculator-tab p {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 95%;
  margin: 0;
  padding: 0;
  height: 53px;
  border: 1px solid #81A0B2;
  background-color: #fff;
  box-sizing: content-box;
}
.point-calculator-cms .calculator-tab:first-child p {
  margin-right: auto;
}
.point-calculator-cms .calculator-tab:last-child p {
  margin-left: auto;
}
.point-calculator-cms .calculator-tab p span {
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.45em;
  color: #020C41;
  padding: 3px;
}
@media (max-width: 1200px) {
  .point-calculator-cms .calculator-tab p span {
      font-size: 16px;
      line-height: 1.2em;
  }
}
@media (max-width: 991px) {
  .point-calculator-cms .calculator-tab p span {
      font-size: 14px;
      line-height: 1.2em;
  }
}
.point-calculator-cms .calculator-tab.-active p {
  border-top-width: 7px;
  border-bottom: 0;
  height: 48px;
}
.point-calculator-cms .calculator-tab.-active p span {
  position: relative;
  top: -3px;
}
@media (max-width: 1200px) {
  .point-calculator-cms .calculator-tab.-active p span {
      top: -4px;
  }
}
@media (max-width: 991px) {
  .point-calculator-cms .calculator-tab.-active p span {
      top: -3px;
  }
}

@media (max-width: 768px) {
  .point-calculator-cms .calculator-tab p {
      color: #020C41;
      border: 1px solid #81a0b2;
      border-top-width: 1px;
      border-bottom: 0;
      max-width: unset;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
  }

  .point-calculator-cms .calculator-tab p {
      font-size: 16px;
      line-height: 23px;
  }

  .point-calculator-cms .calculator-tab.-active p {
      background-color: #81a0b2;
      border: 1px solid #81a0b2;
      border-top-width: 1px;
      border-bottom: 0;
      height: 100%;
  }
  .point-calculator-cms .calculator-tab.-active p span {
      color: #fff;
      position: static;
  }
}

.point-calculator-cms .calculator-content {
  border: 1px solid #81A0B2;
  border-top: 0;
  padding: 30px;
}

.point-calculator-cms .calculator-tabs,
.point-calculator-cms .calculator-content {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.point-calculator-cms .calculator-info h3 {
  color: #6c93ac;
  font-weight: 500;
  font-size: 28px;
  line-height: 1.4em;
}

.point-calculator-cms .info-panels {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .point-calculator-cms .info-panels {
      display: block;
  }
}

.point-calculator-cms .info-panel {
  border: 1px solid #ddd;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 30px;
}
.point-calculator-cms .info-panel:first-child {
  margin-right: 10px;
}
.point-calculator-cms .info-panel:last-child {
  margin-left: 10px;
}
@media (max-width: 991px) {
  .point-calculator-cms .info-panel {
      max-width: 470px;
      padding: 20px;
  }
  .point-calculator-cms .info-panel:first-child {
      margin: 0 auto;
      margin-bottom: 20px;
  }
  .point-calculator-cms .info-panel:last-child {
      margin: 0 auto;
      margin-top: 20px;
  }
}

.point-calculator-cms .info-panel > * {
  margin-bottom: 30px;
}
  
.point-calculator-cms .info-panel img {
  display: block;
  margin: 0 auto;
  width: 214px;
  height: 214px;
}

.point-calculator-cms .info-panel h4 {
  color: #444;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
}

.point-calculator-cms .info-panel hr {
  border: 0;
  border-bottom: 5px solid #81a0b2;
  margin-left: 0;
  max-width: 80px;
}

.point-calculator-cms .info-panel p {
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.4px;
  color: #444;
}

.point-calculator-cms .calculator-form .alert {
  border-width: 2px;
  border-radius: 5px;
}

.point-calculator-cms .calculator-form .alert .alert-heading {
  font-weight: 500;
  font-size: 21px !important;
  line-height: 28px !important;
  letter-spacing: 0.39375px;
}
@media (max-width: 767px){
  .point-calculator-cms .calculator-form .alert .alert-heading {
      font-size: 14px !important;
      line-height: 20px !important;
  }
}
.point-calculator-cms .calculator-form .alert .close {
  opacity: 1;
  box-shadow: none;
  outline: none;
}
.point-calculator-cms .calculator-form .alert .fa {
  margin-right: 10px;
  font-size: inherit !important;
  line-height: inherit !important;
}
.point-calculator-cms .calculator-form .alert .fa-times-circle:before,
.point-calculator-cms .calculator-form .alert .fa-info-circle:before{
  font-size: inherit !important;
  line-height: inherit !important;
}
.point-calculator-cms .calculator-form .alert .alert-text {
  color: #444;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
}
@media (max-width: 767px){
  .point-calculator-cms .calculator-form .alert .alert-text {
      font-size: 16px;
      line-height: 18px;
  }
}
.point-calculator-cms .calculator-form .alert-primary {
  border-color: #426da9;
  color: #426da9;
}
.point-calculator-cms .calculator-form .alert-primary .alert-heading {
  color: #426da9 !important;
}
.point-calculator-cms .calculator-form .alert-primary .close {
  color: #426da9;
}
.point-calculator-cms .calculator-form .alert-danger {
  border-color: #CA0F0F;
  color: #CA0F0F;
}
.point-calculator-cms .calculator-form .alert-danger .alert-heading {
  color: #CA0F0F !important;
}
.point-calculator-cms .calculator-form .alert-danger .close {
  color: #CA0F0F;
}

.point-calculator-cms .form-container {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
@media (max-width: 1200px) {
  .form-container {
      flex-direction: column;
      align-items: center;
  }
}
.point-calculator-cms .form-pictogram {
  width: 139px;
  height: 128px;
}
@media (max-width: 1400px) {
  .point-calculator-cms .form-pictogram {
      display:  none;
  }
}
.point-calculator-cms .form-calculator {
  padding: 0 60px 40px;
  flex-grow: 1;
  max-width: 550px;
}
@media (max-width: 1400px) {
  .point-calculator-cms .form-calculator {
      padding-left: 0;
  }
}
@media (max-width: 1200px) {
  .point-calculator-cms .form-calculator {
      padding-right: 0;
      width: 100%;
      max-width: 500px;
  }
}
.point-calculator-cms .form-calculator h4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: 0.4px;
  color: #444;
  margin: 0;
}
@media (max-width: 1300px) {
  .point-calculator-cms .form-calculator h4 {
      font-size: 20px;
      line-height: 30px;
  }
}
.point-calculator-cms .form-calculator .input-group {
  width: 100%;
  margin: 0;
  margin-right: 7px;
}
.point-calculator-cms .form-calculator .input-group input,
.point-calculator-cms .form-calculator .input-group input::placeholder {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.point-calculator-cms .input-row {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 600px){
  .point-calculator-cms .input-row {
      flex-direction: column;
  }
}
.point-calculator-cms .input-row .input-col:first-child {
  padding-right: 10px;
}
.point-calculator-cms .input-row .input-col:last-child {
  padding-left: 10px;
}
@media (max-width: 600px) {
  .point-calculator-cms .input-row .input-col:first-child,
  .point-calculator-cms .input-row .input-col:last-child{
      padding: 0;
      margin-left: auto;
      margin-right: auto;
  }
}

.point-calculator-cms .input-row.-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1200px) {
  .point-calculator-cms .input-row.-buttons {
    justify-content: center;
  }
}

.point-calculator-cms .input-col {
  width: 100%;
  max-width: 210px;
}

.point-calculator-cms .input-row.-buttons .input-col {
  padding-right: 0;
}
.point-calculator-cms .input-row.-buttons button.btn-primary {
  width: 100%;
  height: 40px;
	font-size: 16px;
	line-height: 19px;
}

.point-calculator-cms .form-ticket {
  max-width: 100%;
}
.point-calculator-cms .form-ticket img {
  max-width: inherit
}

.point-calculator-cms .form-info {
  border: 2px solid #F1F1F1;
  padding: 20px;
  border-radius: 5px;
}

.point-calculator-cms .form-results .amount-base-points {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #444444;
  padding-bottom: 20px;
  border-bottom: 1px solid #828282;
  margin: 0 auto;
  max-width: 400px;
}
.point-calculator-cms .form-results .label-total-points {
  margin: 0;
  margin-top: 30px;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.4px;
  color: #496C82;
}

.point-calculator-cms .form-results .amount-total-points {
  font-weight: 500;
  font-size: 30px;
  line-height: 42px;
  letter-spacing: 0.45px;
  color: #496C82;
}

.point-calculator-cms .form-results .point-disclaimer {
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.4px;
  color: #A2A2A2;
}
.point-calculator-cms .form-results .point-disclaimer a {
  color: #81A0B2;
}

