
/* 
* * * Styles for Guide style *** 
*
* This file inclued styles guide style visual
*
* @vincoorbis.com
*
/* -- BUTTON -- */
.btn-main {
    background: #00295E !important;
    border: none;
    color: #FFFFFF;
    width: 100%;
}

/* -- NAVBAR -- */

.navbar-nav .dropdown-menu {
    border: none;
    position: absolute !important;
    border: 2px solid rgba(0, 0, 0, 0.15);
}

.multi-column-dropdown {
    list-style: none;
}

.navbar-toggler {
    align-items: left;
}

.navbar-brand {
    margin-left: 0;
    font-size: 20px !important;
    margin: 0;
}

.container-nav {
    margin-left: 0px;
}

.navbar {
    top: 0;
    width: 100%;
}

.navbar-nav.nav-guia a{
    font-size: 0.83vw;
    line-height: 0.99vw;
    font-weight: normal;
}

.dropdown-item.guia{
    color: #FFFFFF !important;
}

.dropdown-item-nav.disabled {
    background-color: inherit;
    color: rgb(255, 217, 0) !important;
}

.dropdown-item-nav {
    display: initial;
    padding-right: 4px;
    padding-left: 4px;
    padding-bottom: 4px;
    padding-top: 4px;
    font-size: 15px !important;
    font-weight: 300 !important;
    line-height: 14px;
    color: #FFFFFF;
}

.dropdown-menu {
    border: 2px solid rgba(0, 0, 0, 0.15);
}

.multi-column-dropdown {
    padding-left: 10px;
}


/* -- JUMBOTRON -- */

.jumbotron {
    background: #020C41;
    color: #fff;
    text-align: center;
    height: 200px;
    border-radius: 0%;
    display: flex;
    align-items: center;
}

.jumbotron h1 {
    margin: auto;
}


/* -- BODY -- */

.list-group-main {
    list-style: none;
}

.main-conteiner {
    margin-bottom: 20px;
}

.container-p {
    padding: 0px;
}

.input-group-text {
    background: none;
}

.row-aux {
    padding-top: 12px;
}


/* -- FOOTER -- */

.footer {
    bottom: 0;
    width: 100%;
}


/* -- ALERT -- */

.alert .alert-light {
    color: black !important;
}


/* -- PROGRESS INDICATORS -- */

.steps {
    margin-bottom: 35px;
}

/* -- CARDS -- */

@media only screen and (min-width: 768px) {
    .card-with-img-h {
        max-width: 655.88px;
    }
    .card-with-img{
        max-width: 350px;
    }
}

@media (max-width: 767px) {
    .card-with-img-h {
        max-width: 320.34px;
    }
}

.card-color-texto1 {
    color: #FFFFFF;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.475px;
}


.card-color-texto2 {
    color: #737373;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.475px;
}

/* -- ALERT -- */

.alert-titulo h1{
    color: rgb(101, 129, 151) !important;
}

.alert-subtitulo h3{
    font-weight: bold;
    color: rgb(101, 129, 151) !important;
}

.alert-texto h4{
    color: #444444 !important;
    font-weight: 400;
}

.alert h4{
    color: #444444 !important;
    font-weight: 400;
}

.p-30-guia{
    padding: 15px !important;
}

.alert-view {
    background: #FFFFFF !important;
    border: 1px solid #D0D0D0 !important;
    box-sizing: border-box;
    box-shadow: 2px 0px 6px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-bottom: 0 !important;
}

.dropdown-item.guia:hover {
    color: #16181b !important;
    background-color: #658197 !important;
}

.alert-view-code {
    background: #020C41 !important;
    border: 1px solid #D0D0D0;
    box-sizing: border-box;
    box-shadow: 2px 0px 6px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.alert-view-code h5{
    color: #FFFFFF;
    margin-bottom: 10px !important;
}

/* -- OTHER -- */

.row-basic {
    padding: 0 !important;
    margin: 0 !important;
}

@media (max-width: 767px) {
    .row-inputs {
        margin-top: 20px;
    }
    .alert-mobile {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .mobileOFF {
        display: none !important;
    }
}

@media (min-width: 768px) {
    .mobileON {
        display: none !important;
    }
}

.h5-etiqueta {
    color: #020C41;
    margin-bottom: -28px;
    margin-top: 15px;
    margin-left: 15px;
    background: #FFFFFF !important;
    border: 1px solid #D0D0D0;
    box-sizing: border-box;
    box-shadow: 2px 0px 6px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: max-content;
    padding: 15px;
    padding-top: 5px;
}

.h5-etiqueta2 {
    color: #00D3A0;
    margin-bottom: -28px;
    margin-top: 15px;
    margin-left: 15px;
    background: #020C41  !important;
    border: 1px solid #D0D0D0;
    box-sizing: border-box;
    box-shadow: 2px 0px 6px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: max-content;
    padding: 15px;
    padding-top: 5px;
}
.errorLogin{
    display : none;
    color : red !important;
}
.login-styleGuide .input-group{
    padding-top : 15px !important;
}
.login-styleGuide .input-group>.form-control:not(:first-child), .input-group>.custom-select:not(:first-child){
    margin-bottom : 15px !important;
}

.content-grid {
    margin: auto;
    padding: 0;
    display: flex;
    background: #FFFFFF;
    border-left: 1px solid #444444;
    border-right: 1px solid #444444;
}

 .colum-web-1 {
    height: 500px;
    padding: 0;
    width: 100px;
    background: #FFFFFF;
    display: flex;
}

 .colum-web-1 strong {
    text-align: center;
    color: #444444;
    margin: auto;
    font-size: 8px;
}

 .colum-web-2 {
    height: 500px;
    padding: 0; 
    width: 30px;
    background: rgba(66, 169, 75, 0.15);
    display: flex;
    align-items: center;
}

 .colum-web-2 strong {
    text-align: center;
    color: rgba(66, 169, 75, 0.4);
    margin: auto;
    font-size: x-large;
}

 .colum-web-3 {
    height: 500px;
    padding: 0;
    background: #FFFFFF;
    display: flex;
}

 .colum-web-3 strong {
    text-align: center;
    color: #444444;
    margin: auto;
    font-size: 8px;
    margin: auto;
}

.content-grid-mobile {
    margin: auto;
    display: flex;
    border-left: 1px solid #444444;
    border-right: 1px solid #444444;
}

.alert-grid-m {
    margin: auto;
    padding: 0;
    display: flex;
    background: #FFFFFF;
    width: inherit;
}

.alert-grid-m .colum-mobile-1 {
    height: 500px;
    padding: 0; 
    width: 30px;
    background: #FFFFFF;
    display: flex;
}

.colum-mobile-1 strong {
    text-align: middle;
    color: #444444;
    margin: auto;
    font-size: 8px;
    margin: auto;
}

.alert-grid-m .colum-mobile-2 {
    height: 500px;
    padding: 0;
    width: 30px;
    background: rgba(66, 169, 75, 0.15);
    display: flex;
    align-items: center;
}

.colum-mobile-2 strong {
    text-align: middle;
    color: rgba(66, 169, 75, 0.4);
    margin: auto;
    font-size: x-large;
}

.alert-grid-m .colum-mobile-3 {
    height: 500px;
    padding: 0; 
    background: #FFFFFF;
    display: flex;
}

.colum-mobile-3 strong {
    text-align: middle;
    color: #444444;
    margin: auto;
    font-size: 8px;
    margin: auto;
}

/*select.input-lg {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
  }
  
  select + i.fas {
    float: right;
    margin-top: -30px;
    margin-right: 5px;
    pointer-events: none;
    background-color: #fff;
    padding-right: 5px;
  }*/
  















